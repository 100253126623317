import { EchoPaymentsNoAccessTokenError } from './echoPayments.errors';
import { GetTokenResponse, GetTokenSuccess } from './echoPayments.types';

export function assertAccessToken(
  data: GetTokenResponse
): asserts data is GetTokenSuccess {
  if (data.ResponseCode !== '001') {
    throw new EchoPaymentsNoAccessTokenError();
  }
  if (!Array.isArray(data.TransLog)) {
    throw new EchoPaymentsNoAccessTokenError();
  }
  const accessTokenObject = data.TransLog[0];
  if (
    !accessTokenObject?.AuthToken ||
    accessTokenObject.AuthToken.length === 0
  ) {
    throw new EchoPaymentsNoAccessTokenError();
  }
}
