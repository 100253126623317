import { SVGIconProps } from '../types';

export const DownArrowIcon = ({ color }: SVGIconProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      style={{
        position: 'relative',
        top: '3px',
      }}
    >
      <g
        clipPath="url(#prefix__clip0_439_257)"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M10 4.167v11.666M15 10.833l-5 5M5 10.833l5 5" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_439_257">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
