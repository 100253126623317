import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const PiggyBankIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_502_1854)">
          <path
            d="M11.017 18.1984L15.0856 14.4251C16.3203 13.2799 16.4134 11.3727 15.2936 10.1652L11.6035 6.1864C10.4836 4.97889 8.5748 4.92835 7.34005 6.07351L3.27148 9.84684C2.03672 10.992 1.9436 12.8992 3.06349 14.1067L6.75355 18.0855C8.5144 20.5194 9.78225 19.3435 11.017 18.1984Z"
            fill="#FFD880"
          />
          <path
            d="M20 14.6666V14.6791"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.3334 4V9.07067C22.9836 10.0255 24.2399 11.5366 24.8774 13.3333H26.6654C27.019 13.3333 27.3582 13.4738 27.6082 13.7239C27.8583 13.9739 27.9987 14.313 27.9987 14.6667V17.3333C27.9987 17.687 27.8583 18.0261 27.6082 18.2761C27.3582 18.5262 27.019 18.6667 26.6654 18.6667H24.8761C24.4275 19.9338 23.6673 21.0678 22.6654 21.964V24.6667C22.6654 25.1971 22.4547 25.7058 22.0796 26.0809C21.7045 26.456 21.1958 26.6667 20.6654 26.6667C20.135 26.6667 19.6263 26.456 19.2512 26.0809C18.8761 25.7058 18.6654 25.1971 18.6654 24.6667V23.8893C18.2248 23.9631 17.7788 24.0002 17.3321 24H11.9987C11.552 24.0002 11.106 23.9631 10.6654 23.8893V24.6667C10.6654 25.1971 10.4547 25.7058 10.0796 26.0809C9.70454 26.456 9.19584 26.6667 8.6654 26.6667C8.13497 26.6667 7.62626 26.456 7.25119 26.0809C6.87612 25.7058 6.6654 25.1971 6.6654 24.6667V22L6.66674 21.964C5.45877 20.884 4.60722 19.4627 4.22479 17.8881C3.84236 16.3136 3.94708 14.66 4.52509 13.1463C5.10309 11.6325 6.12714 10.33 7.46169 9.41101C8.79625 8.49205 10.3784 8.00001 11.9987 8H15.3321L21.3321 4H21.3334Z"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.6667 12L8.00008 14.6667"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_502_1854">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
