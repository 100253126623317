import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const ResetIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="IconZorroReset" clipPath="url(#clip0_3204_8570)">
          <ellipse
            id="Ellipse 165"
            cx="24.5"
            cy="20"
            rx="8.5"
            ry="12"
            fill="#FF5636"
          />
          <path
            id="Vector"
            d="M6.75 18.3333C7.17147 15.1179 8.75037 12.1663 11.1911 10.031C13.6319 7.89577 16.7672 6.72324 20.0101 6.73294C23.253 6.74264 26.3813 7.93389 28.8092 10.0837C31.2372 12.2335 32.7984 15.1945 33.2006 18.4124C33.6029 21.6303 32.8185 24.8845 30.9945 27.5658C29.1704 30.2471 26.4317 32.1718 23.2909 32.9794C20.1502 33.7871 16.8227 33.4224 13.9315 31.9536C11.0402 30.4849 8.78333 28.0127 7.58333 25M6.75 33.3333V25H15.0833"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_3204_8570">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
