export const GreenCircleCheckmark = ({
  width = 24,
  height = 24,
  ...restProps
}: React.CSSProperties) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      style={restProps}
    >
      <g clipPath="url(#clip0_173_1630)">
        <circle
          cx="9"
          cy="10"
          r="9"
          transform="rotate(-180 9 10)"
          fill="#80E8B6"
        />
        <path
          d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
          stroke="#1A1721"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 12L11 14L15 10"
          stroke="#1A1721"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_173_1630">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
