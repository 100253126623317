import { GeoDistribution } from '@zorro/clients';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  CHILD_COUNT_GENERAL_AVERAGE,
  FullAllowanceModel,
  StandardizedEmployeeAllowance,
  calculateTotalCostUnits,
  generateFullAllowanceModel,
  getStandardizedEmployeeAllowances,
} from '~/lib/allowance';
import { BandType } from '~/types/allowance';
import {
  CostUnitResolver,
  DEFAULT_STATE_KEY,
  EmployeeData,
  EmployeeScenario,
} from '~/types/sheet';
import { calculateScenarioAffordabilityPercentage } from '~/utils/affordabilityHelpers';

import { useAppConfiguration } from './AppConfigurationContext';
import { useSheetData } from './SheetDataContext';

interface ScenarioCalculatedDataContextType {
  standardizedEmployeeAllowances: Map<string, StandardizedEmployeeAllowance>;
  fullAllowanceModel: FullAllowanceModel;
  employeeAveragePremium: number;
  childrenAveragePremium: number;
  scenarioAffordabilityPercentage: number;
}

export const initialScenarioCalculatedDataContext = {
  standardizedEmployeeAllowances: new Map(),
  fullAllowanceModel: { allowanceModel: new Map() },
  employeeAveragePremium: 0,
  childrenAveragePremium: 0,
  scenarioAffordabilityPercentage: 0,
};

export const ScenarioCalculatedDataContext =
  createContext<ScenarioCalculatedDataContextType>(
    initialScenarioCalculatedDataContext
  );

export function useScenarioCalculatedData() {
  return useContext(ScenarioCalculatedDataContext);
}

type ContextProps = {
  children: ReactNode;
};

// TODO: Should this be done in SheetDataContext instead to avoid repeated parsing?
function parseAgeCostUnits(ageCostUnits: string[][]) {
  const headers = ageCostUnits[0];
  const bandIndex = headers.indexOf('band');
  const costUnitsIndex = headers.indexOf('cost_units');
  const stateCodeIndex = headers.indexOf('state_code');
  return ageCostUnits
    .filter((row) =>
      [BandType.ONE_YEAR, BandType.CHILD].includes(
        row[headers.indexOf('band_type')] as BandType
      )
    )
    .reduce((acc, row) => {
      if (!acc[row[bandIndex]]) {
        acc[row[bandIndex]] = {};
      }
      acc[row[bandIndex]][row[stateCodeIndex]] = Number(row[costUnitsIndex]);
      return acc;
    }, {} as Record<string, Record<string, number>>);
}

export function ScenarioCalculatedDataProvider({ children }: ContextProps) {
  const { sheetData } = useSheetData();
  const { selectedScenario, allowanceModel } = useAppConfiguration();

  const [standardizedEmployeeAllowances, setStandardizedEmployeeAllowances] =
    useState(new Map());
  const [fullAllowanceModel, setFullAllowanceModel] = useState({
    allowanceModel: new Map<
      string,
      { age: string; [columnName: string]: unknown }[]
    >(),
  });
  const [employeeAveragePremium, setEmployeeAveragePremium] = useState(0);
  const [childrenAveragePremium, setChildrenAveragePremium] = useState(0);
  const [scenarioAffordabilityPercentage, setScenarioAffordabilityPercentage] =
    useState(0);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (!sheetData) {
      return;
    }

    const isGeoDistributionByState =
      allowanceModel.geoDistribution === GeoDistribution.STATES &&
      !!sheetData.statePriceFactors;

    const isGeoDistributionByArea =
      allowanceModel.geoDistribution === GeoDistribution.AREA && !!sheetData;

    function stateCodeOrDefault(stateCode: string) {
      return isGeoDistributionByState ? stateCode : DEFAULT_STATE_KEY;
    }

    const ageCostUnitsMap = parseAgeCostUnits(sheetData.ageCostUnits);
    const costUnitResolver: CostUnitResolver = {
      getChildCostUnits(stateCode: string) {
        return ageCostUnitsMap[BandType.CHILD][stateCodeOrDefault(stateCode)];
      },
      getEmployeeCostUnits(age: number, stateCode: string) {
        return ageCostUnitsMap[age][stateCodeOrDefault(stateCode)];
      },
    };
    const employeeScenarios = sheetData.scenarios.get(selectedScenario);

    function calculateCostUnitValue(
      scenarios: EmployeeScenario[],
      employeesInScenario: EmployeeData[],
      stateCode?: string,
      ratingArea?: string
    ): number {
      if (isGeoDistributionByArea && ratingArea) {
        const sumPremiums = scenarios.reduce((previous, current) => {
          if (ratingArea === current.ratingArea) {
            return previous + current.premium;
          }

          return previous;
        }, 0);
        const sumCostUnits = employeesInScenario.reduce((previous, current) => {
          if (ratingArea === current.ratingArea) {
            const totalCostUnits = calculateTotalCostUnits(
              current,
              costUnitResolver,
              allowanceModel.geoDistribution,
              allowanceModel.familyUnits
            );
            return previous + totalCostUnits;
          }

          return previous;
        }, 0);
        return sumPremiums / sumCostUnits;
      }

      if (isGeoDistributionByState && stateCode) {
        const sumPremiums = scenarios.reduce((previous, current) => {
          if (stateCode === current.stateCode) {
            return previous + current.premium;
          }

          return previous;
        }, 0);
        const sumCostUnits = employeesInScenario.reduce((previous, current) => {
          if (stateCode === current.stateCode) {
            const totalCostUnits = calculateTotalCostUnits(
              current,
              costUnitResolver,
              allowanceModel.geoDistribution,
              allowanceModel.familyUnits
            );
            return previous + totalCostUnits;
          }

          return previous;
        }, 0);
        return sumPremiums / sumCostUnits;
      }

      const sumPremiums = scenarios.reduce(
        (previous, current) => previous + current.premium,
        0
      );
      const sumCostUnits = employeesInScenario.reduce(
        (previous, current) =>
          previous +
          calculateTotalCostUnits(
            current,
            costUnitResolver,
            allowanceModel.geoDistribution,
            allowanceModel.familyUnits
          ),
        0
      );
      return sumPremiums / sumCostUnits;
    }

    function adjustCostUnitValueByState(
      stateCode: string,
      allStatesCostUnitValue: number,
      scenarios: EmployeeScenario[],
      employeesInScenario: EmployeeData[]
    ): number {
      if (!isGeoDistributionByState || !sheetData) {
        return allStatesCostUnitValue;
      }

      return calculateCostUnitValue(scenarios, employeesInScenario, stateCode);
    }

    function adjustCostUnitValueByRatingArea(
      ratingArea: string,
      locationIndependentCostUnitValue: number,
      scenarios: EmployeeScenario[],
      employeesInScenario: EmployeeData[]
    ): number {
      if (!isGeoDistributionByArea || !sheetData) {
        return locationIndependentCostUnitValue;
      }

      return calculateCostUnitValue(
        scenarios,
        employeesInScenario,
        undefined,
        ratingArea
      );
    }

    // Price factor adjustor is disabled as it was finnicky, replaced by adding support to calculate cost unit value above by state.
    // https://app.clickup.com/t/8694tre3j
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function calculatePriceFactorAdjuster(
      employeesInScenario: EmployeeData[],
      sumCostUnits: number
    ) {
      let statePriceFactorAdjuster = 1;
      if (isGeoDistributionByState && sheetData) {
        const priceFactorCostUnitsSum = employeesInScenario.reduce(
          (acc, curr) => {
            const statePriceFactor =
              sheetData.statePriceFactors[curr.stateCode];
            const totalCostUnits = calculateTotalCostUnits(
              curr,
              costUnitResolver,
              allowanceModel.geoDistribution,
              allowanceModel.familyUnits
            );
            return acc + totalCostUnits * statePriceFactor;
          },
          0
        );
        statePriceFactorAdjuster = sumCostUnits / priceFactorCostUnitsSum;
      }
      return statePriceFactorAdjuster;
    }

    function calculateAndSetAveragePremiums(
      employeesInScenario: EmployeeData[],
      scenarios: EmployeeScenario[],
      locationIndependentCostUnitValue: number,
      employeeData: Map<string, EmployeeData>
    ): {
      costUnitValuesByGeo: Map<string, number>;
      employeeAveragePremiumResult: number;
      childrenAveragePremiumResult: number;
    } {
      if (isGeoDistributionByState) {
        const stateKeys = new Set<string>();
        employeeData.forEach((value, key) => {
          stateKeys.add(value.stateCode);
        });
        const costUnitValuesByState = new Map(
          [...stateKeys].map((stateCode) => [
            stateCode,
            adjustCostUnitValueByState(
              stateCode,
              locationIndependentCostUnitValue,
              scenarios,
              employeesInScenario
            ),
          ])
        );

        const meanChildCount =
          employeesInScenario.reduce((acc, curr) => {
            return acc + curr.childCount;
          }, 0) / employeesInScenario.length;

        const {
          sumEmployeeCostUnitsCostValueByState,
          sumChildCostUnitsWithValue,
        } = employeesInScenario.reduce(
          (acc, curr) => {
            const adjustedCostUnitValue =
              costUnitValuesByState.get(curr.stateCode) ||
              locationIndependentCostUnitValue;

            acc.sumEmployeeCostUnitsCostValueByState +=
              costUnitResolver.getEmployeeCostUnits(curr.age, curr.stateCode) *
              adjustedCostUnitValue;

            const childCount = meanChildCount === 0 ? 1 : curr.childCount;
            acc.sumChildCostUnitsWithValue +=
              childCount *
              costUnitResolver.getChildCostUnits(curr.stateCode) *
              adjustedCostUnitValue;

            return acc;
          },
          {
            sumEmployeeCostUnitsCostValueByState: 0,
            sumChildCostUnitsWithValue: 0,
          }
        );

        const meanChildCostUnitsWithValue =
          sumChildCostUnitsWithValue / employeesInScenario.length;
        const childrenAveragePremiumResult =
          (CHILD_COUNT_GENERAL_AVERAGE * meanChildCostUnitsWithValue) /
          (meanChildCount === 0 ? 1 : meanChildCount);

        setChildrenAveragePremium(childrenAveragePremiumResult);
        setEmployeeAveragePremium(
          sumEmployeeCostUnitsCostValueByState / employeesInScenario.length
        );

        return {
          costUnitValuesByGeo: costUnitValuesByState,
          employeeAveragePremiumResult:
            sumEmployeeCostUnitsCostValueByState / employeesInScenario.length,
          childrenAveragePremiumResult,
        };
      }
      if (isGeoDistributionByArea) {
        const ratingAreaKeys = new Set<string>();
        employeeData.forEach((value, key) => {
          ratingAreaKeys.add(value.ratingArea);
        });

        const costUnitValuesByRatingArea = new Map(
          [...ratingAreaKeys.values()].map((ratingArea) => [
            ratingArea,
            adjustCostUnitValueByRatingArea(
              ratingArea,
              locationIndependentCostUnitValue,
              scenarios,
              employeesInScenario
            ),
          ])
        );

        const meanChildCount =
          employeesInScenario.reduce((acc, curr) => {
            return acc + curr.childCount;
          }, 0) / employeesInScenario.length;

        const {
          sumEmployeeCostUnitsCostValueByRatingArea,
          sumChildCostUnitsWithValue,
        } = employeesInScenario.reduce(
          (acc, curr) => {
            const adjustedCostUnitValue =
              costUnitValuesByRatingArea.get(curr.ratingArea) ||
              locationIndependentCostUnitValue;

            acc.sumEmployeeCostUnitsCostValueByRatingArea +=
              costUnitResolver.getEmployeeCostUnits(curr.age, curr.ratingArea) *
              adjustedCostUnitValue;

            const childCount = meanChildCount === 0 ? 1 : curr.childCount;
            acc.sumChildCostUnitsWithValue +=
              childCount *
              costUnitResolver.getChildCostUnits(curr.ratingArea) *
              adjustedCostUnitValue;

            return acc;
          },
          {
            sumEmployeeCostUnitsCostValueByRatingArea: 0,
            sumChildCostUnitsWithValue: 0,
          }
        );

        const meanChildCostUnitsWithValue =
          sumChildCostUnitsWithValue / employeesInScenario.length;
        const childrenAveragePremiumResult =
          (CHILD_COUNT_GENERAL_AVERAGE * meanChildCostUnitsWithValue) /
          (meanChildCount === 0 ? 1 : meanChildCount);

        setChildrenAveragePremium(childrenAveragePremiumResult);
        setEmployeeAveragePremium(
          sumEmployeeCostUnitsCostValueByRatingArea / employeesInScenario.length
        );

        return {
          costUnitValuesByGeo: costUnitValuesByRatingArea,
          employeeAveragePremiumResult:
            sumEmployeeCostUnitsCostValueByRatingArea /
            employeesInScenario.length,
          childrenAveragePremiumResult,
        };
      }
      const costUnitValuesAllGeo = new Map([
        [
          DEFAULT_STATE_KEY,
          adjustCostUnitValueByState(
            DEFAULT_STATE_KEY,
            locationIndependentCostUnitValue,
            scenarios,
            employeesInScenario
          ),
        ],
      ]);

      const meanChildCount =
        employeesInScenario.reduce((acc, curr) => {
          return acc + curr.childCount;
        }, 0) / employeesInScenario.length;

      const {
        sumEmployeeCostUnitsCostValueAllGeo,
        sumChildCostUnitsWithValue,
      } = employeesInScenario.reduce(
        (acc, curr) => {
          const adjustedCostUnitValue =
            costUnitValuesAllGeo.get(curr.stateCode) ||
            locationIndependentCostUnitValue;

          acc.sumEmployeeCostUnitsCostValueAllGeo +=
            costUnitResolver.getEmployeeCostUnits(curr.age, curr.stateCode) *
            adjustedCostUnitValue;

          const childCount = meanChildCount === 0 ? 1 : curr.childCount;
          acc.sumChildCostUnitsWithValue +=
            childCount *
            costUnitResolver.getChildCostUnits(curr.ratingArea) *
            adjustedCostUnitValue;

          return acc;
        },
        {
          sumEmployeeCostUnitsCostValueAllGeo: 0,
          sumChildCostUnitsWithValue: 0,
        }
      );

      const meanChildCostUnitsWithValue =
        sumChildCostUnitsWithValue / employeesInScenario.length;
      const childrenAveragePremiumResult =
        (CHILD_COUNT_GENERAL_AVERAGE * meanChildCostUnitsWithValue) /
        (meanChildCount === 0 ? 1 : meanChildCount);

      setChildrenAveragePremium(childrenAveragePremiumResult);
      setEmployeeAveragePremium(
        sumEmployeeCostUnitsCostValueAllGeo / employeesInScenario.length
      );
      return {
        costUnitValuesByGeo: costUnitValuesAllGeo,
        employeeAveragePremiumResult:
          sumEmployeeCostUnitsCostValueAllGeo / employeesInScenario.length,
        childrenAveragePremiumResult,
      };
    }

    if (employeeScenarios) {
      const employeesInScenario = employeeScenarios
        .map((employeeScenario) => employeeScenario.employeeId)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map((employeeId) => sheetData.employeeData.get(employeeId)!);

      const locationIndependentCostUnitValue = calculateCostUnitValue(
        employeeScenarios,
        employeesInScenario
      );

      const {
        costUnitValuesByGeo,
        employeeAveragePremiumResult,
        childrenAveragePremiumResult,
      } = calculateAndSetAveragePremiums(
        employeesInScenario,
        employeeScenarios,
        locationIndependentCostUnitValue,
        sheetData.employeeData
      );

      const newFullAllowanceModel = generateFullAllowanceModel(
        allowanceModel,
        costUnitResolver,
        costUnitValuesByGeo,
        employeeAveragePremiumResult,
        childrenAveragePremiumResult
      );
      setFullAllowanceModel(newFullAllowanceModel);

      const standardizedEmployeeAllowancesInstance =
        getStandardizedEmployeeAllowances(
          newFullAllowanceModel,
          sheetData.employeeData,
          employeeScenarios,
          allowanceModel.geoDistribution
        );
      setStandardizedEmployeeAllowances(standardizedEmployeeAllowancesInstance);

      setScenarioAffordabilityPercentage(
        calculateScenarioAffordabilityPercentage(
          sheetData.employeeData,
          employeeScenarios,
          newFullAllowanceModel,
          allowanceModel.geoDistribution
        )
      );
    }
  }, [allowanceModel, selectedScenario, sheetData]);

  return (
    <ScenarioCalculatedDataContext.Provider
      value={{
        standardizedEmployeeAllowances,
        fullAllowanceModel,
        employeeAveragePremium,
        childrenAveragePremium,
        scenarioAffordabilityPercentage,
      }}
    >
      {children}
    </ScenarioCalculatedDataContext.Provider>
  );
}
