export const WalletIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4832_18417)">
        <path
          d="M21.8113 27C24.1247 27 25.5 26.3438 25.5 26.3438L26 18.4688V11.4977C23 11 22.5 9.5 23 6L14.1887 6C11.8753 6 10 8.46139 10 11.4977L10 21.5023C10 24.5386 11.8753 27 14.1887 27H21.8113Z"
          fill="#80E8B6"
        />
        <path
          d="M22.6668 10.6663V6.66634C22.6668 6.31272 22.5264 5.97358 22.2763 5.72353C22.0263 5.47348 21.6871 5.33301 21.3335 5.33301H8.00016C7.29292 5.33301 6.61464 5.61396 6.11454 6.11406C5.61445 6.61415 5.3335 7.29243 5.3335 7.99967M5.3335 7.99967C5.3335 8.70692 5.61445 9.3852 6.11454 9.88529C6.61464 10.3854 7.29292 10.6663 8.00016 10.6663H24.0002C24.3538 10.6663 24.6929 10.8068 24.943 11.0569C25.193 11.3069 25.3335 11.6461 25.3335 11.9997V15.9997M5.3335 7.99967V23.9997C5.3335 24.7069 5.61445 25.3852 6.11454 25.8853C6.61464 26.3854 7.29292 26.6663 8.00016 26.6663H24.0002C24.3538 26.6663 24.6929 26.5259 24.943 26.2758C25.193 26.0258 25.3335 25.6866 25.3335 25.333V21.333"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.6665 16V21.3333H21.3332C20.6259 21.3333 19.9477 21.0524 19.4476 20.5523C18.9475 20.0522 18.6665 19.3739 18.6665 18.6667C18.6665 17.9594 18.9475 17.2811 19.4476 16.781C19.9477 16.281 20.6259 16 21.3332 16H26.6665Z"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4832_18417">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
