import { CensusEmployeeDto, FamilyUnit } from '@zorro/clients';

export const getFamilyUnitForCensusEmployee = (
  censusEmployee: CensusEmployeeDto
): FamilyUnit => {
  if (censusEmployee.familyUnit) {
    return censusEmployee.familyUnit;
  }
  if (
    censusEmployee.spouseDateOfBirth &&
    censusEmployee.dependentsDatesOfBirth.length > 0
  ) {
    return FamilyUnit.FAMILY;
  }
  if (censusEmployee.spouseDateOfBirth) {
    return FamilyUnit.EMPLOYEE_SPOUSE;
  }
  if (censusEmployee.dependentsDatesOfBirth.length > 0) {
    return FamilyUnit.EMPLOYEE_CHILD;
  }
  return FamilyUnit.EMPLOYEE_ONLY;
};
