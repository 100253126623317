import {
  AllowanceUnit,
  ClassType,
  FamilyUnitDistribution,
  GeoDistribution,
} from '@zorro/clients';

export type AllowanceName = 'mainAllowance' | 'secondaryAllowance';

export enum BandType {
  ONE_YEAR = '1_years',
  CHILD = 'child',
}

export interface Allowance {
  employeeOnly: number;
  employeeAndSpouse: number;
  employeeAndChildren: number;
  employeeAndFamily: number;
}

export interface AllowanceModel {
  unit: AllowanceUnit;
  useClasses: boolean;
  selectedClass?: ClassType;
  geoDistribution: GeoDistribution;
  familyUnits: FamilyUnitDistribution;
  mainAllowance: Allowance;
  secondaryAllowance: Allowance;
}
