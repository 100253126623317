import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const ArrowChartIcon = ({ imageAlt, style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-label={imageAlt}
      >
        <g clipPath="url(#clip0_1_19775)">
          <path d="M8 9H20C22.2091 9 24 10.7909 24 13V28H8V9Z" fill="#F0E6FF" />
          <path
            d="M16 28.0002V9.3335"
            stroke="#1A1721"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 13.3335L16 9.3335L20 13.3335"
            stroke="#1A1721"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4 28H28"
            stroke="#1A1721"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20 20L24 16L28 20"
            stroke="#1A1721"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M24 28V16"
            stroke="#1A1721"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4 8L8 4L12 8"
            stroke="#1A1721"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 28V4"
            stroke="#1A1721"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_19775">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
