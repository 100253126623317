import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const IconZorroWritingSign = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_5366_33873)">
          <path
            d="M22.5005 9.17617C23.2917 7.98452 25.0417 7.98452 25.8329 9.17616L38.7031 28.5604C39.5857 29.8898 38.6326 31.6667 37.0369 31.6667H11.2964C9.70076 31.6667 8.74763 29.8898 9.63026 28.5604L22.5005 9.17617Z"
            fill="#FFBBAF"
          />
          <path
            d="M5 31.6667C10.555 28.3333 13.3333 25 13.3333 21.6667C13.3333 16.6667 11.6667 16.6667 10 16.6667C8.33333 16.6667 6.61333 18.475 6.66667 21.6667C6.72333 25.08 9.43 26.4617 10.8333 28.3333C13.3333 31.6667 15 32.5 16.6667 30C17.7783 28.3333 18.6117 26.945 19.1667 25.8333C20.8333 29.7217 23.055 31.6667 25.8333 31.6667H30"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.3333 28.3333V8.33333C33.3333 6.465 31.8683 5 30 5C28.1316 5 26.6666 6.465 26.6666 8.33333V28.3333L30 31.6667L33.3333 28.3333Z"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.6666 11.6667H33.3333"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_5366_33873">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
