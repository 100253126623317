import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const ZorroRecommendationIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_1533_3244"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1533_3244)">
          <path
            d="M18.2462 8.43761V8.33346V6.72179H12.5443C12.4655 6.72179 12.3881 6.71485 12.3127 6.70153C12.5304 7.18151 12.4682 7.78483 11.9909 8.21312L11.9898 8.21405L5.42962 14.0778C4.99366 14.4679 4.74866 15.0148 4.74866 15.5863V17.2369H10.4307C10.2775 16.802 10.3596 16.2859 10.7815 15.9113C10.782 15.9109 10.7826 15.9104 10.7831 15.91L17.5677 9.84676L18.2462 8.43761ZM18.2462 8.43761C18.2196 8.97264 17.9785 9.47906 17.568 9.84646L18.2462 8.43761ZM11.8104 18.5511C11.8104 18.3844 11.7794 18.2258 11.7232 18.0802H17.2067V19.5313C17.2067 20.469 16.4317 21.25 15.4505 21.25H11.8104V18.5511ZM11.2215 5.40752C11.2215 5.59851 11.2621 5.77887 11.3348 5.94126C11.2609 5.9288 11.1841 5.92226 11.1048 5.92226H5.85483V4.54165C5.85483 3.5631 6.6639 2.75 7.68508 2.75H11.2215V5.40752Z"
            stroke="#3C0789"
            strokeWidth="1.5"
          />
        </g>
      </svg>
    </Center>
  );
};
