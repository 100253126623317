import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const DownloadIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
        <rect x={0.5} y={0.5} width={27} height={27} rx={3.5} fill="#F9F9F9" />
        <g
          clipPath="url(#prefix__clip0_1876_18248)"
          stroke="#1A1721"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M7.333 18.167v1.667A1.667 1.667 0 009 21.5h10a1.667 1.667 0 001.667-1.666v-1.667M9.833 13.167L14 17.334l4.167-4.167M14 7.333v10" />
        </g>
        <rect
          x={0.5}
          y={0.5}
          width={27}
          height={27}
          rx={3.5}
          stroke="#D9D9D9"
        />
        <defs>
          <clipPath id="prefix__clip0_1876_18248">
            <path fill="#fff" transform="translate(4 4)" d="M0 0h20v20H0z" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
