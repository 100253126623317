import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { SheetData } from '~/types/sheet';

interface StateContextType {
  isSheetDataLoading: boolean;
  sheetData: SheetData | null;
  setSheetData: (state: SheetData) => void;
  removeSheetData: () => void;
}

export const initialSheetDataContext: StateContextType = {
  isSheetDataLoading: true,
  sheetData: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSheetData: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removeSheetData: () => {},
};

export const SheetDataContext = createContext<StateContextType>(
  initialSheetDataContext
);

export function useSheetData() {
  return useContext(SheetDataContext);
}

type ContextProps = {
  children: ReactNode;
};

export function SheetDataProvider({ children }: ContextProps) {
  const [sheetData, setSheetData] = useState<SheetData | null>(null);
  const [parsedSheetData, setParsedSheetData] = useState<SheetData | null>(
    null
  );

  const [isSheetDataLoading, setIsSheetDataLoading] = useState(true);

  function parseSheetData(sheetDataObj: SheetData) {
    sheetDataObj.employeeData = new Map(
      Object.entries(sheetDataObj.employeeData)
    );
    sheetDataObj.scenarios = new Map(Object.entries(sheetDataObj.scenarios));
    setParsedSheetData(sheetDataObj);
  }

  useEffect(() => {
    const localStorageSheetData = localStorage.getItem('sheetData');
    if (localStorageSheetData) {
      const sheetDataObj = JSON.parse(localStorageSheetData) as SheetData;
      parseSheetData(sheetDataObj);
    }

    setIsSheetDataLoading(false);
  }, []);

  useEffect(() => {
    if (sheetData) {
      localStorage.setItem('sheetData', JSON.stringify(sheetData));
      parseSheetData(sheetData);
    }
  }, [sheetData]);

  const removeSheetData = () => {
    setSheetData(null);
    setParsedSheetData(null);
  };

  return (
    <SheetDataContext.Provider
      value={{
        isSheetDataLoading,
        sheetData: parsedSheetData,
        setSheetData,
        removeSheetData,
      }}
    >
      {children}
    </SheetDataContext.Provider>
  );
}
