import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const IconZorroDotsCircleHorizontal = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_961_1010"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="32"
          height="32"
        >
          <rect width="32" height="32" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_961_1010)">
          <path
            d="M23.9998 17.0007C23.9998 23.9042 20.404 29.0007 14.605 29.0007C8.80603 29.0007 2.99976 23.9042 2.99976 17.0007C2.99976 10.0971 8.80603 4.00067 14.605 4.00067C20.404 4.00067 23.9998 10.0971 23.9998 17.0007Z"
            fill="#E8E8E8"
          />
          <circle
            cx="15.9998"
            cy="16"
            r="12.5833"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <path
            d="M10.0002 16.6666C10.3684 16.6666 10.6668 16.3682 10.6668 16C10.6668 15.6318 10.3684 15.3333 10.0002 15.3333C9.63197 15.3333 9.3335 15.6318 9.3335 16C9.3335 16.3682 9.63197 16.6666 10.0002 16.6666Z"
            fill="#1A1721"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.0002 16.6666C16.3684 16.6666 16.6668 16.3682 16.6668 16C16.6668 15.6318 16.3684 15.3333 16.0002 15.3333C15.632 15.3333 15.3335 15.6318 15.3335 16C15.3335 16.3682 15.632 16.6666 16.0002 16.6666Z"
            fill="#1A1721"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.0002 16.6666C22.3684 16.6666 22.6668 16.3682 22.6668 16C22.6668 15.6318 22.3684 15.3333 22.0002 15.3333C21.632 15.3333 21.3335 15.6318 21.3335 16C21.3335 16.3682 21.632 16.6666 22.0002 16.6666Z"
            fill="#1A1721"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </Center>
  );
};
