import { SVGIconProps } from '../types';

export const UpArrowIcon = ({ color }: Omit<SVGIconProps, 'style'>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      style={{
        position: 'relative',
        top: '3px',
      }}
    >
      <g
        clipPath="url(#prefix__clip0_439_264)"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M10 15.833V4.167M5 9.167l5-5M15 9.167l-5-5" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_439_264">
          <path fill="#fff" transform="rotate(180 10 10)" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
