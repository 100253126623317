import { useAuth } from '@frontegg/nextjs';
import { Permissions } from '@zorro/types';

export const usePermissions = (): {
  loggedInUserPermissions: Permissions[];
} => {
  // 🧠 must `useAuth` since permissions are not currently part of UserDTO
  const { user } = useAuth();

  return {
    loggedInUserPermissions:
      user?.permissions.map((permission) => permission.key as Permissions) ??
      [],
  };
};
