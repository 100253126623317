import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const DrugsBlueIcon = ({ imageAlt, style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-label={imageAlt}
      >
        <g id="Mask group">
          <mask
            id="mask0_367_3412"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="32"
            height="32"
          >
            <rect id="Rectangle 327" width="32" height="32" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_367_3412)">
            <g id="Group">
              <circle
                id="Ellipse 78"
                cx="15.9998"
                cy="18"
                r="10"
                fill="#66D8F2"
              />
              <path
                id="Vector"
                d="M27.6259 25.4052C27.6259 28.2824 25.2935 30.6148 22.4163 30.6148C19.5391 30.6148 17.2067 28.2824 17.2067 25.4052C17.2067 22.528 19.5391 20.1956 22.4163 20.1956C25.2935 20.1956 27.6259 22.528 27.6259 25.4052Z"
                stroke="#1A1721"
              />
              <path
                id="Line 251"
                d="M20.9001 24.2002L23.7285 27.0286"
                stroke="#1A1721"
                strokeLinecap="round"
              />
              <path
                id="Vector_2"
                d="M13.728 12.2599C15.2358 13.7417 15.2568 16.1651 13.775 17.6729L7.50191 24.0558C6.02009 25.5636 3.59662 25.5846 2.08886 24.1028C0.58111 22.621 0.560107 20.1975 2.04187 18.6897C2.04188 18.6897 2.04188 18.6897 2.04188 18.6897L8.31494 12.3068C9.79677 10.7991 12.2203 10.778 13.728 12.2599Z"
                stroke="#1A1721"
              />
              <path
                id="Vector_3"
                d="M2.04188 18.6884L4.82796 15.8536L10.288 21.2196L7.50191 24.0545C6.02009 25.5622 3.59662 25.5832 2.08886 24.1014C0.581111 22.6196 0.560107 20.1962 2.04187 18.6884C2.04187 18.6884 2.04188 18.6884 2.04188 18.6884Z"
                stroke="#1A1721"
              />
              <path
                id="Vector_4"
                d="M26.831 15.3897C25.2875 16.8342 22.8653 16.754 21.4207 15.2105L15.3054 8.67631C13.8608 7.13281 13.9411 4.71058 15.4845 3.26602C17.028 1.82147 19.4502 1.9017 20.8948 3.44514C20.8948 3.44515 20.8948 3.44515 20.8948 3.44515L27.0101 9.97931C28.4547 11.5228 28.3745 13.9451 26.831 15.3897Z"
                stroke="#1A1721"
              />
              <path
                id="Vector_5"
                d="M20.8948 3.44499L23.6108 6.34704L18.0214 11.5782L15.3054 8.67615C13.8608 7.13265 13.9411 4.71042 15.4845 3.26586C17.028 1.82131 19.4502 1.90154 20.8948 3.44498C20.8948 3.44499 20.8948 3.44499 20.8948 3.44499Z"
                stroke="#1A1721"
              />
            </g>
          </g>
        </g>
      </svg>
    </Center>
  );
};
