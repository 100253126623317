import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const MissingDetailsWarningIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
      >
        <path
          d="M25 14C25 20.0751 20.0751 25 14 25C7.92487 25 3 20.0751 3 14C3 7.92487 7.92487 3 14 3C20.0751 3 25 7.92487 25 14Z"
          fill="white"
        />
        <mask id="path-2-inside-1_859_25789" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.3482 21.9025C6.2502 21.8076 6.15399 21.7109 6.05963 21.6125C4.17005 19.6421 3.00676 16.9697 3.00003 14.0257C3.00001 14.0171 3 14.0086 3 14C3 13.9899 3.00001 13.9798 3.00004 13.9697C3.01639 7.91183 7.92955 3.00542 13.99 3C13.9933 3 13.9967 3 14 3C20.0751 3 25 7.92487 25 14C25 20.0751 20.0751 25 14 25C11.0262 25 8.32803 23.8199 6.3482 21.9025ZM8.24628 22.1799L14 14L22.9378 9.51013C23.6174 10.8602 24 12.3854 24 14C24 19.5228 19.5228 24 14 24C11.8581 24 9.87346 23.3266 8.24628 22.1799Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.3482 21.9025C6.2502 21.8076 6.15399 21.7109 6.05963 21.6125C4.17005 19.6421 3.00676 16.9697 3.00003 14.0257C3.00001 14.0171 3 14.0086 3 14C3 13.9899 3.00001 13.9798 3.00004 13.9697C3.01639 7.91183 7.92955 3.00542 13.99 3C13.9933 3 13.9967 3 14 3C20.0751 3 25 7.92487 25 14C25 20.0751 20.0751 25 14 25C11.0262 25 8.32803 23.8199 6.3482 21.9025ZM8.24628 22.1799L14 14L22.9378 9.51013C23.6174 10.8602 24 12.3854 24 14C24 19.5228 19.5228 24 14 24C11.8581 24 9.87346 23.3266 8.24628 22.1799Z"
          fill="#FF5636"
        />
        <path
          d="M6.05963 21.6125L6.78148 20.9205L6.78139 20.9204L6.05963 21.6125ZM6.3482 21.9025L7.04389 21.1842L7.04382 21.1841L6.3482 21.9025ZM3.00003 14.0257L4.00003 14.0234L3.00003 14.0257ZM3.00004 13.9697L4.00004 13.9724L3.00004 13.9697ZM13.99 3L13.9908 4L13.99 3ZM8.24628 22.1799L7.42835 21.6046L6.85352 22.4218L7.67025 22.9974L8.24628 22.1799ZM14 14L13.5511 13.1064L13.3266 13.2192L13.1821 13.4247L14 14ZM22.9378 9.51013L23.831 9.06053L23.3818 8.168L22.4889 8.61654L22.9378 9.51013ZM5.33778 22.3046C5.44071 22.4119 5.54567 22.5174 5.65259 22.6209L7.04382 21.1841C6.95473 21.0979 6.86727 21.01 6.78148 20.9205L5.33778 22.3046ZM2.00003 14.028C2.00737 17.2393 3.27743 20.1561 5.33787 22.3047L6.78139 20.9204C5.06268 19.1281 4.00615 16.7001 4.00003 14.0234L2.00003 14.028ZM2 14C2 14.0093 2.00001 14.0186 2.00003 14.028L4.00003 14.0234C4.00001 14.0156 4 14.0078 4 14H2ZM2.00004 13.967C2.00001 13.978 2 13.989 2 14H4C4 13.9908 4.00001 13.9816 4.00004 13.9724L2.00004 13.967ZM13.9891 2C7.37767 2.00591 2.01788 7.35836 2.00004 13.967L4.00004 13.9724C4.0149 8.46531 8.48142 4.00493 13.9908 4L13.9891 2ZM14 2C13.9964 2 13.9927 2 13.9891 2L13.9908 4C13.9939 4 13.9969 4 14 4V2ZM26 14C26 7.37258 20.6274 2 14 2V4C19.5228 4 24 8.47715 24 14H26ZM14 26C20.6274 26 26 20.6274 26 14H24C24 19.5228 19.5228 24 14 24V26ZM5.65251 22.6209C7.81135 24.7116 10.7562 26 14 26V24C11.2962 24 8.84472 22.9282 7.04389 21.1842L5.65251 22.6209ZM9.0642 22.7553L14.8179 14.5753L13.1821 13.4247L7.42835 21.6046L9.0642 22.7553ZM14.4489 14.8936L23.3867 10.4037L22.4889 8.61654L13.5511 13.1064L14.4489 14.8936ZM25 14C25 12.2258 24.5792 10.5469 23.831 9.06053L22.0446 9.95972C22.6555 11.1735 23 12.5451 23 14H25ZM14 25C20.0751 25 25 20.0751 25 14H23C23 18.9706 18.9706 23 14 23V25ZM7.67025 22.9974C9.4605 24.2589 11.6452 25 14 25V23C12.071 23 10.2864 22.3942 8.82231 21.3625L7.67025 22.9974Z"
          fill="#FF5636"
          mask="url(#path-2-inside-1_859_25789)"
        />
      </svg>
    </Center>
  );
};
