export const DependentsCareIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4829_18752)">
        <path
          d="M33 18C33.5 22 27.1797 19.5 20 19.5C12.8203 19.5 7 22 7 18C7 11.3726 12.8203 6 20 6C27.1797 6 33 11.3726 33 18Z"
          fill="#66D8F2"
        />
        <path
          d="M6.6665 20.0003C6.6665 16.4641 8.07126 13.0727 10.5717 10.5722C13.0722 8.07175 16.4636 6.66699 19.9998 6.66699C23.5361 6.66699 26.9274 8.07175 29.4279 10.5722C31.9284 13.0727 33.3332 16.4641 33.3332 20.0003H6.6665Z"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 20V30C20 30.8841 20.3512 31.7319 20.9763 32.357C21.6014 32.9821 22.4493 33.3333 23.3333 33.3333C24.2174 33.3333 25.0652 32.9821 25.6904 32.357C26.3155 31.7319 26.6667 30.8841 26.6667 30"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4829_18752">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
