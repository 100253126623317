import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const DoctorsIcon = ({ imageAlt, style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-label={imageAlt}
      >
        <g clipPath="url(#clip0_4832_18369)">
          <path
            d="M26.2267 0.62846C27.7951 4.36687 32.0083 11.529 29.1506 19.0586C26.8469 25.1286 22.665 26.9688 18.35 28.3797C16.5427 28.9706 14.5243 28.2116 13.5732 26.5652C11.4434 22.8789 9.42344 18.3566 11.6742 12.426C14.5319 4.89631 21.8889 2.56891 26.2267 0.62846Z"
            fill="#66D8F2"
          />
          <path
            d="M8.5 5.33301H7.16667C6.45942 5.33301 5.78115 5.61396 5.28105 6.11406C4.78095 6.61415 4.5 7.29243 4.5 7.99967V12.6663C4.5 14.6113 5.27262 16.4765 6.64788 17.8518C8.02315 19.2271 9.88841 19.9997 11.8333 19.9997C13.7783 19.9997 15.6435 19.2271 17.0188 17.8518C18.394 16.4765 19.1667 14.6113 19.1667 12.6663V7.99967C19.1667 7.29243 18.8857 6.61415 18.3856 6.11406C17.8855 5.61396 17.2072 5.33301 16.5 5.33301H15.1667"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.1665 20C11.1665 21.0506 11.3734 22.0909 11.7755 23.0615C12.1775 24.0321 12.7668 24.914 13.5096 25.6569C14.2525 26.3997 15.1344 26.989 16.105 27.391C17.0756 27.7931 18.1159 28 19.1665 28C20.2171 28 21.2574 27.7931 22.228 27.391C23.1986 26.989 24.0805 26.3997 24.8234 25.6569C25.5662 24.914 26.1555 24.0321 26.5575 23.0615C26.9596 22.0909 27.1665 21.0506 27.1665 20V16"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.1665 4V6.66667"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.5 4V6.66667"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27.1667 16.0003C28.6394 16.0003 29.8333 14.8064 29.8333 13.3337C29.8333 11.8609 28.6394 10.667 27.1667 10.667C25.6939 10.667 24.5 11.8609 24.5 13.3337C24.5 14.8064 25.6939 16.0003 27.1667 16.0003Z"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_4832_18369">
            <rect
              width="32"
              height="32"
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
