/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';
import { Logger } from 'pino';

import {
  EXPIRED_ACCESS_TOKEN_STRINGS,
  INVALID_ACCESS_TOKEN_STRINGS,
} from './echoPayments.consts';
import {
  ExpiredAccessTokenResponse,
  InvalidAccessTokenResponse,
} from './echoPayments.types';

/* Generic Errors */

export function isIPRestrictedError(error: AxiosError): boolean {
  const { response } = error;
  if (response) {
    const { status } = response;
    const CLOUDFLARE_IP_BLOCKED_STATUS = 403;
    if (status === CLOUDFLARE_IP_BLOCKED_STATUS) {
      return true;
    }
  }
  return false;
}

/* Access Token */

export function isInvalidAccessTokenError(
  data: any
): data is InvalidAccessTokenResponse {
  const { ResponseCode, ResponseCodeDesc } = data;
  return (
    ResponseCode === INVALID_ACCESS_TOKEN_STRINGS.ResponseCode &&
    ResponseCodeDesc === INVALID_ACCESS_TOKEN_STRINGS.ResponseCodeDesc
  );
}

export function isExpiredAccessTokenError(
  data: any
): data is ExpiredAccessTokenResponse {
  const { ResponseCode, ResponseCodeDesc } = data;
  return (
    ResponseCode === EXPIRED_ACCESS_TOKEN_STRINGS.ResponseCode &&
    ResponseCodeDesc === EXPIRED_ACCESS_TOKEN_STRINGS.ResponseCodeDesc
  );
}

/* Business Logic */

export class EchoPaymentsNoAccessTokenError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'EchoPaymentsNoAccessTokenError';
  }
}

/* Client */

export class EchoPaymentsClientError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'EchoPaymentsClientError';
  }
}

export function logAxiosError(logger: Logger, error: any) {
  if (error instanceof AxiosError) {
    const errorObject = error.toJSON() as any;
    logger.error(
      { ...errorObject, responseData: error.response?.data },
      errorObject.message
    );
  }
}
