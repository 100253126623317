import { SplitFactory } from '@splitsoftware/splitio';
import SplitIO, { Attributes } from '@splitsoftware/splitio/types/splitio';
import { RequiredEnvParams } from '@zorro/environment';
// eslint-disable-next-line unicorn/prefer-node-protocol,unicorn/import-style
import * as path from 'path';

export interface IFeatureFlagManager {
  isDemoModeEnabled(employerId: string): Promise<boolean>;

  isSupportedState(userId: string, state: string): Promise<boolean>;

  isHsaSupported(employerId: string): Promise<boolean>;

  isSupplementalSupported(employerId: string): Promise<boolean>;

  isGallagherMode(employerId: string): Promise<boolean>;

  isReportingMode(agencyIds: string[]): Promise<boolean>;

  isZorroInternalUser(userId: string): Promise<boolean>;

  shouldHidePartnersHub(employerId: string): Promise<boolean>;

  isOn(key: string, feature: string): Promise<boolean>;
}

export abstract class AbsFeatureFlagManager implements IFeatureFlagManager {
  abstract isDemoModeEnabled(employerId: string): Promise<boolean>;

  abstract isSupportedState(userId: string, state: string): Promise<boolean>;

  abstract isHsaSupported(employerId: string): Promise<boolean>;

  abstract isSupplementalSupported(employerId: string): Promise<boolean>;

  abstract isGallagherMode(employerId: string): Promise<boolean>;

  abstract isReportingMode(employerIds: string[]): Promise<boolean>;

  abstract isZorroInternalUser(userId: string): Promise<boolean>;

  abstract shouldHidePartnersHub(employerId: string): Promise<boolean>;

  abstract isOn(key: string, feature: string): Promise<boolean>;
}

export class FeatureFlagManager implements AbsFeatureFlagManager {
  private static instance?: IFeatureFlagManager;
  private client: SplitIO.IClient;

  constructor() {
    const rootPath = path.join(process.cwd(), '../../');

    const factory = SplitFactory({
      core: {
        authorizationKey: process.env[RequiredEnvParams.SPLIT_IO_API_KEY]!,
      },
      features: path.join(rootPath, '.split.yaml'),
    });
    this.client = factory.client();
  }

  static getInstance() {
    if (!FeatureFlagManager.instance) {
      FeatureFlagManager.instance = new FeatureFlagManager();
    }
    return FeatureFlagManager.instance;
  }

  async isDemoModeEnabled(employerId: string): Promise<boolean> {
    const treatment = await this.getTreatment(employerId, 'demo_mode');
    return treatment === 'on';
  }

  async isSupportedState(userId: string, state: string): Promise<boolean> {
    const treatment = await this.getTreatment(userId, 'state_supported', {
      state,
    });

    return treatment === 'on';
  }

  async isHsaSupported(employerId: string): Promise<boolean> {
    const treatment = await this.getTreatment(employerId, 'supports_hsa');
    return treatment === 'on';
  }

  async isSupplementalSupported(employerId: string): Promise<boolean> {
    const treatment = await this.getTreatment(
      employerId,
      'supports_supplemental'
    );
    return treatment === 'on';
  }

  async isGallagherMode(employerId: string): Promise<boolean> {
    const treatment = await this.getTreatment(employerId, 'gallagher_mode');
    return treatment === 'on';
  }

  async isReportingMode(employerIds: string[]): Promise<boolean> {
    const treatments = await Promise.all(
      employerIds.map((agencyId) =>
        this.getTreatment(agencyId, 'reporting_mode')
      )
    );

    return treatments.includes('on');
  }

  async isZorroInternalUser(userId: string): Promise<boolean> {
    const treatment = await this.getTreatment(userId, 'zorro_internal_user');
    return treatment === 'on';
  }

  async shouldHidePartnersHub(employerId: string): Promise<boolean> {
    const treatment = await this.getTreatment(employerId, 'hide_partners_hub');
    return treatment === 'on';
  }

  async isOn(key: string, feature: string): Promise<boolean> {
    const treatment = await this.getTreatment(key, feature);
    return treatment === 'on';
  }

  private async getTreatment(
    key: string,
    feature: string,
    attributes?: Attributes
  ): Promise<string> {
    await this.client.ready().catch((error) => {
      throw error;
    });
    return this.client.getTreatment(key, feature, attributes);
  }
}
