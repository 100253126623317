import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const ThumbsUpIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_4829_18733)">
          <path
            d="M26.7644 5.87953C29.1289 5.78235 31.1372 7.79772 31.0378 10.1621C30.8166 15.4178 29.9494 22.4776 24.5464 27.9616C18.6355 33.9611 12.9352 34.0269 7.31965 33.6551C5.30853 33.5219 3.66376 31.9094 3.5087 29.8998C3.10538 24.6731 3.15903 18.4622 8.94446 12.59C14.4711 6.9806 21.2834 6.1048 26.7644 5.87953Z"
            fill="#FFC39C"
          />
          <path
            d="M11.6667 18.3337V31.667C11.6667 32.109 11.4911 32.5329 11.1785 32.8455C10.866 33.1581 10.442 33.3337 10 33.3337H6.66667C6.22464 33.3337 5.80072 33.1581 5.48816 32.8455C5.17559 32.5329 5 32.109 5 31.667V20.0003C5 19.5583 5.17559 19.1344 5.48816 18.8218C5.80072 18.5093 6.22464 18.3337 6.66667 18.3337H11.6667ZM11.6667 18.3337C13.4348 18.3337 15.1305 17.6313 16.3807 16.381C17.631 15.1308 18.3333 13.4351 18.3333 11.667V10.0003C18.3333 9.11627 18.6845 8.26842 19.3096 7.6433C19.9348 7.01818 20.7826 6.66699 21.6667 6.66699C22.5507 6.66699 23.3986 7.01818 24.0237 7.6433C24.6488 8.26842 25 9.11627 25 10.0003V18.3337H30C30.8841 18.3337 31.7319 18.6848 32.357 19.31C32.9821 19.9351 33.3333 20.7829 33.3333 21.667L31.6667 30.0003C31.427 31.0228 30.9723 31.9007 30.3711 32.5019C29.7699 33.1031 29.0547 33.395 28.3333 33.3337H16.6667C15.3406 33.3337 14.0688 32.8069 13.1311 31.8692C12.1935 30.9315 11.6667 29.6597 11.6667 28.3337"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_4829_18733">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
