import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const ManSittingWithALaptop = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="346"
        height="354"
        viewBox="0 0 346 354"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_622_107101"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="-6"
          y="0"
          width="355"
          height="354"
        >
          <rect x="-5.177" width="353.766" height="354" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_622_107101)">
          <path
            d="M175.314 45.1406C175.314 45.1406 190.818 93.125 250.498 90.5331C270.327 88.0529 293.927 90.5331 314.942 118.229C346.887 160.36 334.06 207.294 319.544 237.086"
            stroke="#C5C9F0"
            strokeWidth="2"
          />
          <path
            d="M69.0317 99.909C67.8282 101.962 65.5854 103.168 63.419 103.611C60.6507 104.19 57.5931 105.084 54.7905 105.338H53.0648C52.4421 105.534 52.5053 105.613 52.202 106.201C51.8987 106.79 51.2691 107.172 51.4638 107.807C52.4718 111.347 53.8586 114.764 55.599 117.994L53.0199 120.627C52.2891 119.618 52.2547 117.85 51.2747 118.021C50.3333 117.06 49.2157 116.59 48.0594 117.876C45.7984 120.399 46.1638 125.642 49.4865 127.09L48.7429 130.867C48.214 133.591 45.8749 135.554 43.1548 135.558L43.7265 131.087C39.8579 133.083 35.4691 128.766 35.7743 125.207C35.9721 122.768 35.4304 121.083 33.6638 119.315C33.5477 119.197 34.257 118.758 34.1495 118.639C33.6509 117.099 33.5821 115.3 34.0764 114.283C34.9361 112.466 37.4679 111.404 36.303 109.421C35.7917 108.557 35.4188 107.615 35.1983 106.631C34.7251 103.991 35.3944 101.27 37.0338 99.1719C38.7532 97.0659 41.5472 97.6319 43.8297 96.886C46.6108 96.0085 47.8961 92.968 50.8061 92.029C52.2547 91.5596 53.5959 91.7746 55.0445 92.1124C56.2695 92.402 57.4817 92.7266 58.6853 93.1127C59.8889 93.4989 60.9463 93.8191 62.1241 93.4594C63.6801 93.0206 64.8794 91.5376 66.5773 91.5245C67.4245 91.5045 68.2538 91.7748 68.9329 92.2923C70.188 94.5957 70.1966 97.939 69.0317 99.909Z"
            fill="#3C0789"
          />
          <path
            d="M17.8615 157.631C17.8615 157.631 -27.1126 107.994 39.1196 83.2242C42.9602 81.8148 46.963 80.8954 51.0334 80.4877C61.6046 79.3633 84.9736 75.366 87.6671 61.8652"
            stroke="#C5C9F0"
            strokeWidth="2"
          />
          <path d="M206.656 88.1424V88.13" stroke="#CDABFF" strokeWidth="2" />
          <path d="M88.6506 177.519V177.507" stroke="#3C0789" strokeWidth="2" />
          <path
            d="M53.4872 120.848C53.0469 120.087 52.5232 119.377 51.9256 118.732C51.0209 117.827 49.9469 117.384 48.8357 118.595C46.6628 120.972 47.0139 125.912 50.2071 127.276L49.4925 130.835C48.9843 133.401 46.7362 135.251 44.1222 135.254"
            stroke="#3C0789"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M42.1229 151.003C42.3047 149.623 42.755 145.997 43.1887 142.542C43.6018 139.338 43.9695 136.254 44.1141 135.237L44.6676 131.033"
            stroke="#3C0789"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M67.6277 109.58C67.6277 109.58 62.3524 109.841 61.332 114.21"
            stroke="#3C0789"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M69.5448 121.126C70.9824 121.089 72.8496 120.878 73.5271 119.419L69.8918 115.405C69.005 114.421 68.4804 113.164 68.4046 111.841C68.2601 109.622 68.5203 106.1 66.397 103.756"
            stroke="#3C0789"
            strokeWidth="2"
          />
          <ellipse
            cx="1.60754"
            cy="1.36363"
            rx="1.60754"
            ry="1.36363"
            transform="matrix(0.670312 -0.74208 0.741638 0.6708 63.3361 114.934)"
            fill="#3C0789"
          />
          <path
            d="M66.0783 147.491L63.3395 134.205"
            stroke="#3C0789"
            strokeWidth="2"
          />
          <path
            d="M136.629 200.686H206.41C242.121 237.542 195.003 240.463 195.003 240.463C191.885 241.06 116.754 261.107 113.589 261.355C98.2383 262.463 82.491 258.499 69.8378 249.62C44.2256 231.638 21.9183 195.65 33.8155 164.316C34.8166 161.666 36.0396 159.105 37.4714 156.66C38.7053 154.535 40.2763 152.625 42.1229 151.005C48.7248 145.222 58.1014 143.849 66.0827 147.496C67.5786 148.179 68.9933 149.027 70.3004 150.026C76.9885 155.147 83.0693 162.427 88.3116 170.066C92.9662 176.974 97.1547 184.186 100.849 191.652C101.869 193.69 102.795 195.617 103.613 197.382C111.028 194.381 117.245 189.284 123.028 183.778C116.035 196.84 136.629 200.686 136.629 200.686Z"
            fill="#E0E3FE"
          />
          <path
            d="M70.9692 162.943C75.651 171.914 80.3328 180.9 85.0146 189.904C88.5466 196.699 94.5159 200.895 102.922 197.675C112.068 194.17 119.446 187.469 126.271 180.64C133.095 173.811 140.011 167.28 149.863 165.519C162.599 163.241 174.649 170.186 183.324 178.962C184.698 180.339 184.946 182.481 183.923 184.137C183.278 185.178 182.212 185.886 181.003 186.077C179.794 186.269 178.562 185.924 177.627 185.133C173.517 181.624 166.829 176.547 162.177 176.481"
            stroke="#3C0789"
            strokeWidth="2"
          />
          <path
            d="M149.488 188.523C150.917 189.478 152.9 190.537 154.552 190.537"
            stroke="#3C0789"
            strokeWidth="2"
          />
          <path
            d="M167.804 178.223C170.852 179.731 171.964 184.146 169.811 186.784C167.082 190.172 163.663 192.94 159.781 194.902C155.535 197.023 152.808 195.729 148.628 193.141"
            stroke="#3C0789"
            strokeWidth="2"
          />
          <path
            d="M160.749 182.178C160.749 182.178 166.788 183.674 166.627 189.54C166.616 189.79 166.572 190.037 166.495 190.276"
            stroke="#3C0789"
            strokeWidth="2"
          />
          <path
            d="M156.48 185.873C156.48 185.873 164.581 191.11 160.727 194.405"
            stroke="#3C0789"
            strokeWidth="2"
          />
          <path
            d="M312.967 206.402C303.425 232.647 276.709 233.387 273.652 233.387C274.933 230.688 275.28 225.946 272.475 217.637C276.324 212.012 276.247 204.579 272.281 199.036C272.318 199.003 272.339 198.957 272.372 198.924C273.708 198.843 275.033 198.642 276.334 198.325C280.944 197.225 290.032 194.811 297.245 191.413C302.136 189.102 307.841 192.806 307.423 198.209C307.423 198.279 307.423 198.345 307.399 198.424C309.337 198.422 311.153 199.369 312.263 200.959C313.373 202.549 313.636 204.582 312.967 206.402Z"
            fill="#8033EF"
          />
          <path
            d="M190.512 267.955C187.299 273.474 182.296 278.174 177.463 282.275C160.447 296.685 138.218 304.002 115.911 304.287C93.5291 304.002 71.2176 296.66 54.1236 282.378C0.110878 237.246 9.87241 183.958 18.6095 159.284C18.9172 158.431 19.3337 157.62 19.8488 156.874C21.2305 154.851 23.4101 153.515 25.8387 153.203C30.4241 153.836 34.5881 157.643 33.8528 163.537C33.8528 163.765 33.7619 164.079 33.7413 164.306C21.8523 195.64 44.1679 231.628 69.7635 249.61C81.3758 257.766 95.5946 261.776 109.723 261.515H109.884C111.095 261.49 112.306 261.434 113.515 261.346C121.451 260.767 129.163 258.865 136.801 256.563C145.766 253.88 154.631 250.627 163.587 248.349C166.734 247.547 169.891 246.774 173.063 246.051C174.894 245.675 176.767 245.545 178.632 245.666C189.633 246.41 197.362 256.199 190.512 267.955Z"
            fill="#8033EF"
          />
          <path
            d="M191.343 267.857C188.135 273.341 183.138 278.012 178.314 282.087C160.655 296.991 137.355 304.27 114.159 303.962C91.8201 303.679 69.5513 296.383 52.4901 282.19C-1.40675 237.338 8.33608 184.382 17.0564 159.861C17.3635 159.013 17.7793 158.208 18.2933 157.466C19.6724 155.456 21.8478 154.129 24.2718 153.818C28.8484 154.447 33.0044 158.23 32.2705 164.088C32.2705 164.314 32.1798 164.627 32.1592 164.852C20.293 195.991 42.5658 231.756 68.1124 249.625C79.7024 257.731 93.894 261.715 107.995 261.457H108.156C110.28 261.489 112.393 261.433 114.493 261.288C122.413 260.713 130.107 258.823 137.735 256.535C146.682 253.869 155.53 250.636 164.469 248.373C167.606 247.576 170.76 246.807 173.923 246.088C174.863 245.897 175.815 245.769 176.772 245.706C189.116 244.881 198.731 255.229 191.343 267.857Z"
            fill="#8033EF"
          />
          <path
            d="M119.629 304.213L125.065 311.91C125.065 311.91 157.96 313.191 175.963 328.313C179.293 331.111 177.301 336.547 172.956 336.547C171.866 336.551 170.81 336.167 169.977 335.464C165.726 331.892 149.487 320.665 112.755 320.326C76.9637 319.991 63.2365 331.074 59.4401 335.113C58.5649 336.034 57.3481 336.554 56.0774 336.547H55.371C50.9096 336.547 49.0217 330.909 52.5454 328.172C67.574 316.478 101.262 312.749 101.262 312.749L107.013 304.068"
            fill="#3C0789"
          />
          <path
            d="M265.209 261.533C265.209 261.533 275.284 271.111 272.141 300.804C271.912 302.924 271.175 304.957 269.993 306.731L268.898 308.385C267.103 311.075 264.396 313.025 261.276 313.874C254.696 315.673 242.757 319.839 235.383 327.735C232.268 331.079 234.586 336.527 239.155 336.527C240.885 336.532 242.503 335.669 243.463 334.229C246.467 329.707 255.559 320.104 277.49 320.307C302.227 320.534 312.191 330.376 315.384 334.618C316.279 335.817 317.685 336.524 319.18 336.527C321.07 336.524 322.777 335.4 323.529 333.665C324.281 331.931 323.934 329.916 322.646 328.533C313 318.045 301.202 314.135 294.692 312.684C291.421 311.978 288.532 310.075 286.591 307.347C285.105 305.243 284.26 302.754 284.158 300.179C283.794 290.974 283.604 268.627 289.838 261.541"
            fill="#3C0789"
          />
          <path
            d="M136.885 256.578C154.602 247.971 173.832 242.325 192.74 237.009C211.767 231.66 230.98 226.993 250.251 222.607C257.952 220.858 265.722 219.3 273.385 217.328"
            stroke="#3C0789"
            strokeWidth="2"
          />
          <path
            d="M153.507 190.417H153.519H153.527"
            stroke="#3C0789"
            strokeWidth="2"
          />
          <path
            d="M151.519 189.297C150.408 190.397 146.442 196.271 145.447 197.325C139.977 203.19 134.167 208.728 128.047 213.909C126.506 215.191 124.874 216.513 123.156 217.853C122.817 218.113 122.466 218.386 122.115 218.646C110.465 227.493 95.2634 236.008 81.2181 230.552C80.8463 230.415 80.4827 230.258 80.1233 230.097C58.5802 220.445 43.5228 196.271 42.2091 172.936C42.1637 172.175 42.1389 171.419 42.1306 170.67C42.0149 164.292 42.9568 157.914 49.0707 154.549"
            stroke="#3C0789"
            strokeWidth="2"
          />
          <path
            d="M33.8485 164.226L33.9353 163.548C35.3068 152.544 19.6916 148.832 15.9861 159.286C7.25319 183.956 -2.51248 237.232 51.5126 282.372C86.3823 311.519 142.836 311.721 177.574 282.277C182.407 278.18 187.41 273.472 190.628 267.954C198.77 253.998 186.336 242.808 172.311 246.243"
            stroke="#3C0789"
            strokeWidth="2"
          />
          <path
            d="M119.629 304.215L125.065 311.912C125.065 311.912 157.96 313.193 175.963 328.315C179.293 331.113 177.302 336.549 172.956 336.549C171.866 336.553 170.811 336.169 169.977 335.466C165.727 331.894 149.488 320.667 112.755 320.328C76.964 319.993 63.2367 331.076 59.4403 335.115C58.5651 336.036 57.3483 336.555 56.0777 336.549H55.3713C50.9098 336.549 49.022 330.911 52.5457 328.174C67.5742 316.48 101.262 312.751 101.262 312.751L107.013 304.07"
            stroke="#3C0789"
            strokeWidth="2"
          />
          <path
            d="M101.262 190.992C115.052 187.272 120.732 167.806 123.02 154.719"
            stroke="#3C0789"
            strokeWidth="2"
          />
          <path
            d="M88.2985 170.079C96.0878 169.059 102.788 164.062 105.992 156.884C106.66 155.461 106.614 153.804 105.868 152.42C104.364 149.634 102.121 146.405 102.654 143.065C103.038 140.709 104.975 139.051 107.198 138.518C108.324 138.223 109.51 138.243 110.626 138.576C111.981 138.96 113.091 139.933 113.65 141.226C114.192 142.495 113.547 143.752 112.552 144.578C111.556 145.405 110.391 145.699 109.177 146.029C109.02 146.075 108.59 146.166 108.115 146.257"
            stroke="#3C0789"
            strokeWidth="2"
          />
          <path
            d="M273.367 217.319C273.367 217.319 300.549 211.623 305.655 203.418C306.612 201.921 307.209 200.223 307.398 198.457"
            stroke="#3C0789"
            strokeWidth="2"
          />
          <path
            d="M139.614 203.306C187.025 198.267 240.538 199.011 240.538 199.011H270.57C272.511 199.014 274.446 198.786 276.333 198.333C280.943 197.233 290.032 194.823 297.244 191.421C302.135 189.111 307.84 192.814 307.423 198.221C307.423 198.288 307.423 198.358 307.398 198.436C309.332 198.437 311.144 199.381 312.253 200.967C313.362 202.552 313.629 204.579 312.967 206.398C302.838 234.255 273.367 233.383 273.367 233.383C269.6 233.495 265.84 233.643 262.069 233.838C228.843 235.454 195.738 239.927 163.5 248.166C154.544 250.443 145.852 253.886 136.887 256.573C129.245 258.884 121.524 260.773 113.589 261.352C98.2379 262.464 82.4906 258.495 69.8373 249.616C42.1225 230.154 18.2702 189.578 37.471 156.64C44.25 145.016 59.6007 141.833 70.3 150.026C85.3905 161.58 97.4447 184.088 103.629 197.366"
            stroke="#3C0789"
            strokeWidth="2"
          />
          <path d="M312.34 261.535H246.36" stroke="#3C0789" strokeWidth="2" />
          <path
            d="M233.041 248.199C233.061 240.842 239.015 234.882 246.367 234.859H312.356C319.709 234.88 325.666 240.84 325.686 248.199"
            stroke="#3C0789"
            strokeWidth="2"
          />
          <path
            d="M326.984 252.926C326.991 255.211 326.086 257.405 324.471 259.02C322.855 260.636 320.663 261.54 318.379 261.532H240.303C236.238 261.147 233.229 257.576 233.537 253.501C233.845 249.426 237.358 246.348 241.435 246.581H319.511C322.324 246.576 324.961 247.952 326.567 250.264C326.843 251.124 326.984 252.022 326.984 252.926Z"
            fill="#8033EF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M326.983 252.925C326.99 255.21 326.085 257.404 324.47 259.02C322.854 260.635 320.662 261.539 318.378 261.532H240.302C237.184 261.6 234.273 259.974 232.693 257.283C231.114 254.591 231.114 251.255 232.693 248.563C234.273 245.872 237.184 244.246 240.302 244.315H318.378C322.107 244.313 325.413 246.714 326.566 250.263C326.842 251.123 326.983 252.021 326.983 252.925V252.925Z"
            stroke="#3C0789"
            strokeWidth="2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M122.872 154.41L122.715 155.737C122.566 156.983 121.511 157.922 120.257 157.924H111.677C110.423 157.922 109.367 156.983 109.219 155.737L108.112 146.229C108.587 146.138 109.017 146.047 109.173 146.002C110.388 145.671 111.565 145.39 112.549 144.551C113.532 143.712 114.201 142.484 113.647 141.199C113.088 139.906 111.978 138.933 110.623 138.549C109.507 138.216 108.321 138.196 107.195 138.491L106.934 136.226C106.857 135.526 107.081 134.826 107.55 134.3C108.019 133.775 108.689 133.474 109.392 133.473H122.558C123.261 133.475 123.93 133.777 124.398 134.302C124.866 134.827 125.089 135.526 125.012 136.226L124.706 138.809"
            fill="#00D16C"
          />
          <path
            d="M122.872 154.41L122.715 155.737C122.566 156.983 121.511 157.922 120.257 157.924H111.677C110.423 157.922 109.367 156.983 109.219 155.737L108.112 146.229C108.587 146.138 109.017 146.047 109.173 146.002C110.388 145.671 111.565 145.39 112.549 144.551C113.532 143.712 114.201 142.484 113.647 141.199C113.088 139.906 111.978 138.933 110.623 138.549C109.507 138.216 108.321 138.196 107.195 138.491L106.934 136.226C106.857 135.526 107.081 134.826 107.55 134.3C108.019 133.775 108.689 133.474 109.392 133.473H122.558C123.261 133.475 123.93 133.777 124.398 134.302C124.866 134.827 125.089 135.526 125.012 136.226L124.706 138.809"
            stroke="#3C0789"
            strokeWidth="2"
          />
          <path
            d="M124.016 144.716L124.003 144.807"
            stroke="#3C0789"
            strokeWidth="2"
          />
          <path d="M123.371 150.176V150.139" stroke="#3C0789" strokeWidth="2" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M123.496 150.177C123.459 150.171 123.421 150.171 123.384 150.177C123.332 150.171 123.279 150.171 123.227 150.177C122.117 150.181 121.195 151.035 121.106 152.142C121.016 153.249 121.789 154.24 122.884 154.422C123.112 154.468 123.346 154.468 123.574 154.422C124.605 154.257 125.546 153.737 126.234 152.95C127.151 151.442 127.788 149.779 128.114 148.044C128.12 148.026 128.12 148.007 128.114 147.99C128.366 146.711 128.462 145.406 128.399 144.104C128.399 143.546 128.329 142.992 128.263 142.451C128.004 140.591 126.56 139.119 124.706 138.825C124.661 138.825 124.607 138.825 124.561 138.801C122.826 138.586 120.393 139.243 119.943 141.202C119.53 142.972 121.447 144.489 123.021 144.625C123.021 144.625 123.434 144.679 124.016 144.716C125.181 144.795 127.118 144.782 128.399 144.104C128.587 144.007 128.765 143.889 128.928 143.753"
            fill="#F7F8FB"
          />
          <path
            d="M123.496 150.177C123.459 150.171 123.421 150.171 123.384 150.177C123.332 150.171 123.279 150.171 123.227 150.177C122.117 150.181 121.195 151.035 121.106 152.142C121.016 153.249 121.789 154.24 122.884 154.422C123.112 154.468 123.346 154.468 123.574 154.422C124.605 154.257 125.546 153.737 126.234 152.95C127.151 151.442 127.788 149.779 128.114 148.044C128.12 148.026 128.12 148.007 128.114 147.99C128.366 146.711 128.462 145.406 128.399 144.104C128.399 143.546 128.329 142.992 128.263 142.451C128.004 140.591 126.56 139.119 124.706 138.825C124.661 138.825 124.607 138.825 124.561 138.801C122.826 138.586 120.393 139.243 119.943 141.202C119.53 142.972 121.447 144.489 123.021 144.625C123.021 144.625 123.434 144.679 124.016 144.716C125.181 144.795 127.118 144.782 128.399 144.104C128.587 144.007 128.765 143.889 128.928 143.753"
            stroke="#3C0789"
            strokeWidth="2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M125.305 144.811H125.02H122.819H121.848C120.485 144.816 119.363 145.884 119.291 147.246C119.224 148.566 120.166 149.723 121.472 149.924C124.322 150.383 127.578 150.478 128.135 147.924"
            fill="#F7F8FB"
          />
          <path
            d="M125.305 144.811H125.02M125.02 144.811H122.819H121.848C120.485 144.816 119.363 145.884 119.291 147.246C119.224 148.566 120.166 149.723 121.472 149.924C124.322 150.383 127.578 150.478 128.135 147.924C128.243 145.384 126.104 144.79 125.02 144.811Z"
            stroke="#3C0789"
            strokeWidth="2"
          />
          <path
            d="M265.209 261.531C265.209 261.531 275.284 271.109 272.141 300.802C271.912 302.922 271.175 304.955 269.993 306.729L268.898 308.383C267.103 311.073 264.396 313.023 261.276 313.873C254.696 315.671 242.757 319.838 235.383 327.733C232.268 331.077 234.586 336.525 239.155 336.525C240.885 336.53 242.503 335.667 243.463 334.227C246.467 329.705 255.559 320.102 277.49 320.305C302.227 320.532 312.191 330.374 315.384 334.616C316.278 335.815 317.685 336.522 319.18 336.525C321.07 336.522 322.777 335.398 323.529 333.664C324.281 331.929 323.934 329.914 322.646 328.531C313 318.043 301.202 314.133 294.692 312.682C291.421 311.977 288.531 310.073 286.591 307.345C285.105 305.241 284.26 302.752 284.158 300.177C283.794 290.972 283.604 268.625 289.838 261.54"
            stroke="#3C0789"
            strokeWidth="2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M244.266 192.129V192.799C244.266 197.383 240.572 198.173 237.383 198.251L175.662 199.69C172.956 199.756 170.68 197.392 170.622 194.432V194.308C170.622 191.414 172.754 188.996 175.423 188.934C175.423 188.934 209.953 187.958 236.689 187.223C237.202 188.843 238.441 190.091 239.994 191.071C241.281 191.841 242.768 192.21 244.266 192.129Z"
            fill="#E0E3FE"
            stroke="#3C0789"
            strokeWidth="2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M277.073 126.776C277.076 127.53 276.903 128.274 276.569 128.95L248.966 185.574C248.31 186.905 247.1 187.877 245.661 188.232C244.521 188.524 243.314 188.401 242.257 187.885C240.527 187.032 239.435 185.267 239.444 183.338C239.446 183.105 239.462 182.874 239.493 182.643C239.567 182.131 239.72 181.633 239.948 181.168L267.547 124.535C268.573 122.443 270.907 121.335 273.176 121.863C275.445 122.391 277.05 124.416 277.048 126.747L277.073 126.776Z"
            fill="#E0E3FE"
            stroke="#3C0789"
            strokeWidth="2"
          />
          <path
            d="M53.6032 121.187C53.5702 121.071 53.5247 120.959 53.4875 120.848C53.4504 120.736 53.3967 120.608 53.3636 120.484"
            stroke="#3C0789"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M53.6042 121.192L54.4511 120.34L56.0704 118.699"
            stroke="#3C0789"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M63.1337 123.432C63.1337 123.432 64.2201 126.677 68.3718 126.896"
            stroke="#3C0789"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M44.668 131.034C40.9501 132.914 36.7323 128.847 37.0256 125.494C37.2157 123.196 36.6952 121.609 34.9973 119.943C34.8858 119.831 34.7825 119.715 34.6834 119.604C33.3609 118.164 33.0498 116.062 33.8985 114.3C34.671 112.502 36.3358 111.448 35.1791 109.509C34.6716 108.671 34.3006 107.757 34.0802 106.802C33.6134 104.239 34.2778 101.599 35.902 99.5634C37.604 97.4966 40.3759 98.067 42.652 97.3436C45.3909 96.4714 46.6715 93.524 49.5467 92.6188C50.9843 92.1682 52.3186 92.3707 53.7561 92.6973C54.9665 92.9825 56.181 93.3008 57.3584 93.6729C58.5357 94.0449 59.6098 94.3632 60.7871 94.0118C62.328 93.5612 63.5136 92.1434 65.1907 92.131C66.8679 92.1186 68.1568 93.1065 68.9086 94.4872C70.0984 96.6533 70.1066 99.8032 68.9871 101.672C67.8304 103.606 65.4097 104.425 63.3277 104.842C60.6673 105.388 57.9161 105.533 55.2227 105.772L53.657 106.157C52.4169 106.541 51.7221 107.858 52.1038 109.1C53.0725 112.436 54.4052 115.655 56.0778 118.698"
            stroke="#3C0789"
            strokeWidth="2"
          />
          <path
            d="M56.0688 131.509C56.0688 131.509 64.2647 137.516 68.9369 134.205C73.609 130.894 70.9693 121.188 70.9693 121.188"
            stroke="#3C0789"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M113.982 129.327C113.982 129.327 110.5 126.971 112.714 124.565C114.928 122.159 111.978 118.984 111.978 118.984"
            stroke="#3C0789"
            strokeWidth="2"
          />
          <path
            d="M117.195 129.736C117.195 129.736 119.501 128.177 118.022 126.582C116.543 124.986 118.513 122.861 118.513 122.861"
            stroke="#3C0789"
            strokeWidth="2"
          />
          <ellipse
            cx="133.079"
            cy="61.8442"
            rx="45.4079"
            ry="45.438"
            stroke="#C5C9F0"
            strokeWidth="2"
          />
          <ellipse
            cx="133.08"
            cy="61.844"
            rx="24.9388"
            ry="24.9553"
            stroke="#C5C9F0"
            strokeWidth="2"
          />
          <ellipse
            cx="133.08"
            cy="61.8448"
            rx="4.27557"
            ry="4.27841"
            stroke="#C5C9F0"
            strokeWidth="2"
          />
          <path
            d="M141.933 47.5586L135.331 58.2071"
            stroke="#C5C9F0"
            strokeWidth="2"
          />
          <path d="M133.079 36.8887V41.3283" stroke="#C5C9F0" strokeWidth="2" />
          <path d="M158.019 61.8433H153.813" stroke="#C5C9F0" strokeWidth="2" />
          <path d="M133.079 86.8039V82.4883" stroke="#C5C9F0" strokeWidth="2" />
          <path d="M108.137 61.8433H111.929" stroke="#C5C9F0" strokeWidth="2" />
          <path
            d="M2.94409 336.732H343.975"
            stroke="#3C0789"
            strokeWidth="2.59024"
          />
        </g>
      </svg>
    </Center>
  );
};
