export const UsaMapIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4829_18737)">
        <path
          d="M25.5 19.5002C24.5 32.5 22.8513 35.0002 16.5 35.0002C10.1487 35.0002 5 28.2844 5 20.0002C5 11.7159 10.1487 5.00017 16.5 5.00017C23 4 25.5 11.2159 25.5 19.5002Z"
          fill="#80E8B6"
        />
        <path
          d="M20 35C28.2843 35 35 28.2843 35 20C35 11.7157 28.2843 5 20 5C11.7157 5 5 11.7157 5 20C5 28.2843 11.7157 35 20 35Z"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 15H34"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 25H34"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.1664 5C16.3587 9.49935 14.8701 14.6964 14.8701 20C14.8701 25.3036 16.3587 30.5006 19.1664 35"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.8335 5C23.6413 9.49935 25.1298 14.6964 25.1298 20C25.1298 25.3036 23.6413 30.5006 20.8335 35"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4829_18737">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
