import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const CheckboxIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <g clipPath="url(#clip0_5676_19782)">
          <path
            d="M5 9C5 6.79086 6.79086 5 9 5H16C18.2091 5 20 6.79086 20 9V23C20 25.2091 18.2091 27 16 27H9C6.79086 27 5 25.2091 5 23V9Z"
            fill="#66D8F2"
          />
          <path
            d="M12 14.6667L16 18.6667L26.6667 8"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.6667 16V24C26.6667 24.7073 26.3858 25.3856 25.8857 25.8857C25.3856 26.3858 24.7073 26.6667 24 26.6667H8.00004C7.2928 26.6667 6.61452 26.3858 6.11442 25.8857C5.61433 25.3856 5.33337 24.7073 5.33337 24V8.00004C5.33337 7.2928 5.61433 6.61452 6.11442 6.11442C6.61452 5.61433 7.2928 5.33337 8.00004 5.33337H20"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_5676_19782">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
