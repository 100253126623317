export const SumIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <g clipPath="url(#clip0_1327_14405)">
        <rect x="6" y="16" width="20" height="20" rx="2" fill="#80E8B6" />
        <path
          d="M30 26.6666V29.9999C30 30.4419 29.8244 30.8659 29.5118 31.1784C29.1993 31.491 28.7754 31.6666 28.3333 31.6666H10L20 19.9999L10 8.33325H28.3333C28.7754 8.33325 29.1993 8.50885 29.5118 8.82141C29.8244 9.13397 30 9.55789 30 9.99992V13.3333"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1327_14405">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
