export const GreenCircleCheckmarkFilled = ({
  width = 20,
  height = 20,
}: React.CSSProperties) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <circle cx="10" cy="10" r="10" fill="#00D16C" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.19596 12.867L8.87497 12.5461L8.55399 12.867C8.73126 13.0443 9.01868 13.0443 9.19596 12.867ZM8.87497 11.9041L6.77493 9.80404C6.59765 9.62677 6.31023 9.62677 6.13296 9.80404C5.95568 9.98132 5.95568 10.2687 6.13296 10.446L8.55399 12.867L8.87497 12.5461C9.19596 12.867 9.19593 12.8671 9.19596 12.867L13.867 8.19596C14.0443 8.01868 14.0443 7.73126 13.867 7.55399C13.6898 7.37671 13.4023 7.37671 13.2251 7.55399L8.87497 11.9041Z"
        fill="white"
      />
    </svg>
  );
};
