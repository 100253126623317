import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const IconZorroTransform = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <g clipPath="url(#clip0_254_946)">
          <path
            d="M30 25V11.7123C30 9.10976 27.8902 7 25.2877 7L12 7V11.7123V20.2877C12 22.8902 14.1098 25 16.7123 25H25.2877H30Z"
            fill="#66D8F2"
          />
          <path
            d="M4 24C4 24.7072 4.28095 25.3855 4.78105 25.8856C5.28115 26.3857 5.95942 26.6667 6.66667 26.6667C7.37391 26.6667 8.05219 26.3857 8.55229 25.8856C9.05238 25.3855 9.33333 24.7072 9.33333 24C9.33333 23.2928 9.05238 22.6145 8.55229 22.1144C8.05219 21.6143 7.37391 21.3333 6.66667 21.3333C5.95942 21.3333 5.28115 21.6143 4.78105 22.1144C4.28095 22.6145 4 23.2928 4 24Z"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.6667 8C22.6667 8.70725 22.9477 9.38552 23.4478 9.88562C23.9479 10.3857 24.6262 10.6667 25.3334 10.6667C26.0407 10.6667 26.7189 10.3857 27.219 9.88562C27.7191 9.38552 28.0001 8.70725 28.0001 8C28.0001 7.29276 27.7191 6.61448 27.219 6.11438C26.7189 5.61429 26.0407 5.33334 25.3334 5.33334C24.6262 5.33334 23.9479 5.61429 23.4478 6.11438C22.9477 6.61448 22.6667 7.29276 22.6667 8Z"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M25.3334 10.6667V17.3333C25.3334 19.1014 24.631 20.7971 23.3808 22.0474C22.1306 23.2976 20.4349 24 18.6667 24H14.6667M14.6667 24L18.6667 20M14.6667 24L18.6667 28"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.66675 21.3333V14.6667C6.66675 12.8986 7.36913 11.2029 8.61937 9.95262C9.86961 8.70238 11.5653 8 13.3334 8H17.3334M17.3334 8L13.3334 4M17.3334 8L13.3334 12"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_254_946">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
