import {
  AllowanceUnit,
  FamilyUnitDistribution,
  GeoDistribution,
} from '@zorro/clients';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AllowanceModel } from '~/types/allowance';

const defaultAllowanceModel: AllowanceModel = {
  mainAllowance: {
    employeeOnly: 85,
    employeeAndSpouse: 70,
    employeeAndChildren: 70,
    employeeAndFamily: 50,
  },
  secondaryAllowance: {
    employeeOnly: 85,
    employeeAndSpouse: 70,
    employeeAndChildren: 70,
    employeeAndFamily: 50,
  },
  unit: AllowanceUnit.PERCENT,
  useClasses: false,
  geoDistribution: GeoDistribution.OFF,
  familyUnits: FamilyUnitDistribution.EIGHT,
};

interface AppConfigurationContextType {
  allowanceModel: AllowanceModel;
  setAllowanceModel: (allowanceModel: AllowanceModel) => void;
  selectedScenario: string;
  setSelectedScenario: (selectedScenario: string) => void;
  resetConfiguration: () => void;
  quoteChanged: boolean;
  setQuoteChanged: (quoteChanged: boolean) => void;
}

export const initialAppConfigurationContext: AppConfigurationContextType = {
  allowanceModel: defaultAllowanceModel,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setAllowanceModel: () => {},
  selectedScenario: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedScenario: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetConfiguration: () => {},
  quoteChanged: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setQuoteChanged: () => {},
};

export const AppConfigurationContext =
  createContext<AppConfigurationContextType>(initialAppConfigurationContext);

export function useAppConfiguration() {
  return useContext(AppConfigurationContext);
}

type ContextProps = {
  children: ReactNode;
};

export function AppConfigurationProvider({ children }: ContextProps) {
  const [allowanceModel, setAllowanceModel] = useState<AllowanceModel>(
    defaultAllowanceModel
  );

  const [quoteChanged, setQuoteChanged] = useState(false);

  const [selectedScenario, setSelectedScenario] = useState<string>('');
  useEffect(() => {
    const localStorageSelectedScenario =
      window.localStorage.getItem('selectedScenario');
    const localStorageAllowanceModel =
      window.localStorage.getItem('allowanceModel');
    if (localStorageSelectedScenario) {
      setSelectedScenario(localStorageSelectedScenario);
    }
    if (localStorageAllowanceModel) {
      setAllowanceModel(JSON.parse(localStorageAllowanceModel));
    }
  }, []);
  useEffect(() => {
    if (selectedScenario) {
      window.localStorage.setItem('selectedScenario', selectedScenario);
    }
  }, [selectedScenario]);

  useEffect(() => {
    if (allowanceModel) {
      window.localStorage.setItem(
        'allowanceModel',
        JSON.stringify(allowanceModel)
      );
    }
    setQuoteChanged(true);
  }, [allowanceModel]);

  const resetConfiguration = () => {
    setAllowanceModel(defaultAllowanceModel);
    setSelectedScenario('');
  };

  return (
    <AppConfigurationContext.Provider
      value={{
        allowanceModel,
        setAllowanceModel,
        selectedScenario,
        setSelectedScenario,
        resetConfiguration,
        quoteChanged,
        setQuoteChanged,
      }}
    >
      {children}
    </AppConfigurationContext.Provider>
  );
}
