import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export function HandWithFourHearts({ style }: SVGIconProps) {
  return (
    <Center style={style}>
      <svg
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_343_640"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="80"
          height="80"
        >
          <path d="M0 0H80V80H0V0Z" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_343_640)">
          <path
            d="M53.2446 61.7048L72.334 55.1422C74.3824 54.4031 76.8086 55.1013 77.8431 57.0154C79.2481 59.6137 78.0183 62.4793 75.6324 63.4345L46.4373 76.2892C44.0496 77.375 41.4575 77.9371 38.8354 77.9371C35.5077 77.9371 32.2417 77.0326 29.3889 75.3199L21.2169 70.417C19.3309 69.286 16.2541 68.6512 14.0623 68.5719C14.0623 68.5688 14.0623 46.6876 14.0623 46.6876H26.4324C28.0437 46.6876 29.6263 47.1014 31.0296 47.8918L39.2858 52.536C39.7546 52.7997 40.2807 52.9376 40.8184 52.9376H50.9369C53.526 52.9376 55.6244 55.036 55.6244 57.6252C55.6244 59.375 54.6667 60.8997 53.2446 61.7048Z"
            fill="#FFC39C"
          />
          <path
            d="M14.0624 46.6876V68.5718C14.0575 70.2937 12.661 71.6871 10.9374 71.6871H1.56232V43.5626H10.9374C12.664 43.5626 14.0624 44.9609 14.0624 46.6876Z"
            fill="#8033EF"
          />
          <path
            d="M71.8153 53.6682L57.085 58.7323C57.1503 58.3698 57.1869 57.9999 57.1869 57.6245C57.1869 54.1785 54.3836 51.3745 50.9369 51.3745H40.8185C40.5499 51.3745 40.285 51.3056 40.0513 51.1737L31.7962 46.5308C30.1641 45.6109 28.3093 45.1245 26.4324 45.1245H15.3557C14.7112 43.3062 12.9741 42.0001 10.9374 42.0001H1.56232C0.699283 42.0001 -0.000183105 42.6996 -0.000183105 43.5626V71.6871C-0.000183105 72.5501 0.699283 73.2496 1.56232 73.2496H10.9374C12.9277 73.2496 14.6343 71.999 15.3118 70.2424C17.0806 70.4621 19.1271 70.9852 20.4131 71.7567L28.5845 76.6597C31.6802 78.5176 35.2246 79.4996 38.8354 79.4996C41.6968 79.4996 44.469 78.8991 47.0758 77.715L76.2392 64.8737C79.4691 63.5633 81.064 59.6882 79.2177 56.2726C77.8596 53.7592 74.6791 52.641 71.8153 53.6682ZM12.4999 68.5694C12.4962 69.4276 11.7961 70.1246 10.9374 70.1246H3.12483V45.1251H10.9374C11.7986 45.1251 12.4999 45.8258 12.4999 46.6876V68.5694ZM75.052 61.9837C75.0355 61.9898 75.0191 61.9971 75.0026 62.0038C75.0026 62.0038 45.7959 74.864 45.7904 74.8664C43.5901 75.8674 41.25 76.3746 38.8354 76.3746C35.791 76.3746 32.8027 75.547 30.1928 73.9802L22.0208 69.0772C20.2715 68.028 17.7666 67.3884 15.6249 67.132V48.2501H26.4324C27.7728 48.2501 29.0972 48.5968 30.2636 49.2542L38.5187 53.8971C39.2181 54.2914 40.014 54.5001 40.8185 54.5001H50.9369C52.66 54.5001 54.0619 55.9015 54.0619 57.6252C54.0619 59.3421 52.6593 60.7502 50.9369 60.7502H35.0531C34.19 60.7502 33.4906 61.4496 33.4906 62.3127C33.4906 63.1751 34.19 63.8746 35.0531 63.8746H50.9369C51.9617 63.8746 52.9706 63.6201 53.866 63.1428C53.866 63.1428 72.8572 56.6144 72.8645 56.612C74.2647 56.1066 75.8479 56.6095 76.4686 57.7582C77.3994 59.48 76.6298 61.352 75.052 61.9837Z"
            fill="#1A1721"
          />
          <path
            d="M61.3381 12.6494L53.009 20.9999L44.68 12.6494M44.68 12.6494C44.1306 12.1082 43.6979 11.4578 43.4091 10.739C43.1202 10.0202 42.9815 9.24864 43.0017 8.47292C43.0219 7.6972 43.2005 6.93412 43.5264 6.23172C43.8522 5.52932 44.3181 4.90283 44.8949 4.39168C45.4717 3.88054 46.1467 3.49582 46.8776 3.26175C47.6084 3.02768 48.3792 2.94934 49.1415 3.03165C49.9037 3.11396 50.6408 3.35515 51.3064 3.74001C51.9721 4.12488 52.5518 4.6451 53.009 5.26791C53.4683 4.64962 54.0487 4.13395 54.7138 3.75317C55.379 3.37238 56.1146 3.13468 56.8747 3.05494C57.6348 2.9752 58.403 3.05514 59.1312 3.28976C59.8593 3.52437 60.5318 3.90861 61.1066 4.41842C61.6813 4.92823 62.1459 5.55265 62.4713 6.25258C62.7966 6.95252 62.9758 7.71291 62.9975 8.48616C63.0193 9.25941 62.8831 10.0289 62.5976 10.7464C62.312 11.4639 61.8832 12.1141 61.3381 12.6561"
            fill="#CDABFF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M50.2573 4.52458C49.6688 4.46103 49.0736 4.52147 48.5089 4.70234C47.9441 4.88322 47.4214 5.18084 46.974 5.57733C46.5266 5.97385 46.1641 6.46074 45.9103 7.00788C45.6565 7.55506 45.5171 8.15016 45.5013 8.75559C45.4856 9.36102 45.5939 9.96286 45.819 10.523C46.0441 11.0832 46.3809 11.589 46.8073 12.009L46.8151 12.0168L46.8151 12.0168L54.2591 19.48L61.7031 12.0168C61.7271 11.9927 61.7519 11.9698 61.7773 11.9481C62.1671 11.5421 62.4762 11.0621 62.6862 10.5344C62.9087 9.97516 63.015 9.37493 62.9981 8.77142C62.9811 8.16792 62.8413 7.57492 62.5878 7.02967C62.3344 6.48445 61.973 5.99918 61.5271 5.60369C61.0813 5.20823 60.5606 4.91098 59.9979 4.72968C59.4352 4.54839 58.842 4.48671 58.2552 4.54827C57.6684 4.60984 57.0997 4.7934 56.5849 5.08814C56.07 5.3829 55.6196 5.78273 55.2626 6.26341C55.026 6.58188 54.6523 6.76915 54.2556 6.76805C53.8589 6.76695 53.4863 6.57761 53.2515 6.25783C52.896 5.77361 52.4461 5.37024 51.9308 5.0723C51.4156 4.77439 50.8458 4.58813 50.2573 4.52458ZM63.3922 13.8634L55.1441 22.1328C54.9096 22.368 54.5912 22.5001 54.2591 22.5001C53.927 22.5001 53.6086 22.368 53.3741 22.1328L45.0488 13.786C44.3784 13.1245 43.8511 12.3309 43.4993 11.4552C43.1467 10.5778 42.9776 9.63656 43.0022 8.69056C43.0268 7.74456 43.2446 6.81349 43.6425 5.95587C44.0403 5.09821 44.6098 4.33211 45.3159 3.70634C46.022 3.08054 46.8494 2.60872 47.7464 2.32147C48.6433 2.0342 49.5897 1.93795 50.5257 2.03903C51.4617 2.1401 52.3662 2.43621 53.1822 2.90804C53.5682 3.13125 53.9305 3.39147 54.2642 3.68434C54.5973 3.39526 54.9584 3.13858 55.3429 2.9185C56.1583 2.45167 57.061 2.15983 57.9944 2.06192C58.9277 1.964 59.8709 2.06221 60.7646 2.35014C61.6582 2.63806 62.4825 3.10928 63.1861 3.73346C63.8897 4.35759 64.4575 5.12115 64.8548 5.9758C65.2521 6.83042 65.4706 7.7582 65.4971 8.7012C65.5236 9.64419 65.3576 10.5829 65.009 11.4588C64.6604 12.3346 64.1366 13.1293 63.4695 13.7927C63.4445 13.8175 63.4187 13.8411 63.3922 13.8634Z"
            fill="#1A1721"
          />
          <path
            d="M25.3376 12.6496L17.0086 21.0001L8.67956 12.6496M8.67956 12.6496C8.13019 12.1084 7.69745 11.4579 7.40861 10.7392C7.11977 10.0204 6.98107 9.24882 7.00125 8.4731C7.02144 7.69739 7.20007 6.9343 7.52589 6.23191C7.85172 5.52951 8.31768 4.90301 8.89444 4.39187C9.4712 3.88072 10.1463 3.496 10.8771 3.26193C11.608 3.02787 12.3788 2.94952 13.141 3.03183C13.9032 3.11415 14.6404 3.35533 15.306 3.7402C15.9716 4.12507 16.5513 4.64528 17.0086 5.26809C17.4678 4.6498 18.0482 4.13413 18.7134 3.75335C19.3785 3.37256 20.1142 3.13486 20.8743 3.05513C21.6344 2.97539 22.4025 3.05533 23.1307 3.28994C23.8589 3.52455 24.5314 3.90879 25.1061 4.4186C25.6808 4.92842 26.1454 5.55283 26.4708 6.25277C26.7962 6.9527 26.9754 7.71309 26.9971 8.48634C27.0188 9.25959 26.8826 10.0291 26.5971 10.7466C26.3115 11.4641 25.8828 12.1143 25.3376 12.6563"
            fill="#CDABFF"
          />
          <path
            d="M43.5882 35.9073L35.2592 44.2578L26.9302 35.9073M26.9302 35.9073C26.3808 35.3661 25.9481 34.7156 25.6592 33.9969C25.3704 33.2781 25.2317 32.5065 25.2519 31.7308C25.272 30.9551 25.4507 30.192 25.7765 29.4896C26.1023 28.7872 26.5683 28.1607 27.145 27.6496C27.7218 27.1384 28.3969 26.7537 29.1277 26.5196C29.8586 26.2856 30.6294 26.2072 31.3916 26.2895C32.1538 26.3719 32.891 26.613 33.5566 26.9979C34.2222 27.3828 34.8019 27.903 35.2592 28.5258C35.7184 27.9075 36.2988 27.3918 36.964 27.0111C37.6291 26.6303 38.3648 26.3926 39.1249 26.3128C39.885 26.2331 40.6532 26.313 41.3813 26.5476C42.1095 26.7823 42.782 27.1665 43.3567 27.6763C43.9314 28.1861 44.396 28.8105 44.7214 29.5105C45.0468 30.2104 45.226 30.9708 45.2477 31.744C45.2694 32.5173 45.1333 33.2868 44.8477 34.0043C44.5622 34.7218 44.1334 35.372 43.5882 35.914"
            fill="#CDABFF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.2573 27.5246C30.6688 27.461 30.0736 27.5215 29.5089 27.7024C28.9441 27.8832 28.4214 28.1809 27.974 28.5773C27.5266 28.9739 27.1642 29.4608 26.9104 30.0079C26.6565 30.5551 26.5171 31.1502 26.5013 31.7556C26.4856 32.361 26.5939 32.9629 26.819 33.5231C27.0441 34.0832 27.3809 34.589 27.8073 35.0091L27.8151 35.0168L27.8151 35.0168L35.2591 42.4801L42.7031 35.0168C42.7271 34.9927 42.7519 34.9698 42.7773 34.9481C43.1671 34.5421 43.4762 34.0621 43.6862 33.5344C43.9087 32.9752 44.0151 32.3749 43.9981 31.7714C43.9811 31.1679 43.8413 30.5749 43.5878 30.0297C43.3344 29.4845 42.973 28.9992 42.5271 28.6037C42.0813 28.2082 41.5606 27.911 40.9979 27.7297C40.4352 27.5484 39.842 27.4867 39.2552 27.5483C38.6684 27.6099 38.0998 27.7934 37.5849 28.0882C37.07 28.3829 36.6196 28.7827 36.2626 29.2634C36.026 29.5819 35.6523 29.7692 35.2556 29.7681C34.8589 29.767 34.4863 29.5776 34.2515 29.2578C33.896 28.7736 33.4461 28.3703 32.9308 28.0723C32.4156 27.7744 31.8458 27.5881 31.2573 27.5246ZM44.3922 36.8634L36.1441 45.1328C35.9096 45.368 35.5912 45.5001 35.2591 45.5001C34.927 45.5001 34.6086 45.368 34.3741 45.1328L26.0488 36.786C25.3784 36.1245 24.8512 35.3309 24.4993 34.4552C24.1467 33.5778 23.9776 32.6366 24.0022 31.6906C24.0268 30.7446 24.2446 29.8135 24.6425 28.9559C25.0403 28.0982 25.6098 27.3321 26.3159 26.7064C27.022 26.0806 27.8494 25.6087 28.7464 25.3215C29.6433 25.0342 30.5897 24.938 31.5257 25.039C32.4617 25.1401 33.3662 25.4362 34.1822 25.9081C34.5683 26.1313 34.9305 26.3915 35.2642 26.6844C35.5973 26.3953 35.9584 26.1386 36.3429 25.9185C37.1583 25.4517 38.061 25.1598 38.9944 25.0619C39.9278 24.964 40.8709 25.0622 41.7646 25.3502C42.6582 25.6381 43.4825 26.1093 44.1861 26.7335C44.8897 27.3576 45.4575 28.1212 45.8548 28.9758C46.2521 29.8304 46.4706 30.7582 46.4971 31.7012C46.5236 32.6442 46.3576 33.5829 46.009 34.4588C45.6605 35.3347 45.1366 36.1294 44.4695 36.7927C44.4445 36.8176 44.4187 36.8411 44.3922 36.8634Z"
            fill="#1A1721"
          />
          <path
            d="M76.5881 40.9073L68.2591 49.2579L59.9301 40.9073M59.9301 40.9073C59.3807 40.3662 58.948 39.7157 58.6591 38.9969C58.3703 38.2781 58.2316 37.5066 58.2518 36.7309C58.272 35.9552 58.4506 35.1921 58.7764 34.4897C59.1023 33.7873 59.5682 33.1608 60.145 32.6496C60.7217 32.1385 61.3968 31.7538 62.1276 31.5197C62.8585 31.2856 63.6293 31.2073 64.3915 31.2896C65.1538 31.3719 65.8909 31.6131 66.5565 31.998C67.2222 32.3828 67.8019 32.903 68.2591 33.5259C68.7184 32.9076 69.2987 32.3919 69.9639 32.0111C70.6291 31.6303 71.3647 31.3926 72.1248 31.3129C72.8849 31.2332 73.6531 31.3131 74.3812 31.5477C75.1094 31.7823 75.7819 32.1666 76.3566 32.6764C76.9314 33.1862 77.396 33.8106 77.7213 34.5105C78.0467 35.2105 78.2259 35.9709 78.2476 36.7441C78.2693 37.5174 78.1332 38.2868 77.8476 39.0044C77.5621 39.7219 77.1333 40.372 76.5881 40.9141"
            fill="#CDABFF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M64.2573 32.5246C63.6688 32.461 63.0736 32.5215 62.5089 32.7024C61.9441 32.8832 61.4214 33.1809 60.974 33.5773C60.5266 33.9739 60.1641 34.4608 59.9103 35.0079C59.6565 35.5551 59.5171 36.1502 59.5013 36.7556C59.4856 37.361 59.5939 37.9629 59.819 38.5231C60.0441 39.0832 60.3809 39.589 60.8073 40.0091L60.8151 40.0168L60.8151 40.0168L68.2591 47.4801L75.7031 40.0168C75.7271 39.9927 75.7519 39.9698 75.7773 39.9481C76.1671 39.5421 76.4762 39.0621 76.6862 38.5344C76.9087 37.9752 77.015 37.3749 76.9981 36.7714C76.9811 36.1679 76.8413 35.5749 76.5878 35.0297C76.3344 34.4845 75.973 33.9992 75.5271 33.6037C75.0813 33.2082 74.5606 32.911 73.9979 32.7297C73.4352 32.5484 72.842 32.4867 72.2552 32.5483C71.6684 32.6099 71.0997 32.7934 70.5849 33.0882C70.07 33.3829 69.6196 33.7827 69.2626 34.2634C69.026 34.5819 68.6523 34.7692 68.2556 34.7681C67.8589 34.767 67.4863 34.5776 67.2515 34.2578C66.896 33.7736 66.4461 33.3703 65.9308 33.0723C65.4156 32.7744 64.8458 32.5881 64.2573 32.5246ZM77.3922 41.8634L69.1441 50.1328C68.9096 50.368 68.5912 50.5001 68.2591 50.5001C67.927 50.5001 67.6086 50.368 67.3741 50.1328L59.0488 41.786C58.3784 41.1245 57.8511 40.3309 57.4993 39.4552C57.1467 38.5778 56.9776 37.6366 57.0022 36.6906C57.0268 35.7446 57.2446 34.8135 57.6425 33.9559C58.0403 33.0982 58.6098 32.3321 59.3159 31.7064C60.022 31.0806 60.8494 30.6087 61.7464 30.3215C62.6433 30.0342 63.5897 29.938 64.5257 30.039C65.4617 30.1401 66.3662 30.4362 67.1822 30.9081C67.5682 31.1313 67.9305 31.3915 68.2642 31.6844C68.5973 31.3953 68.9584 31.1386 69.3429 30.9185C70.1583 30.4517 71.061 30.1598 71.9944 30.0619C72.9277 29.964 73.8709 30.0622 74.7646 30.3502C75.6582 30.6381 76.4825 31.1093 77.1861 31.7335C77.8897 32.3576 78.4575 33.1212 78.8548 33.9758C79.2521 34.8304 79.4706 35.7582 79.4971 36.7012C79.5236 37.6442 79.3576 38.5829 79.009 39.4588C78.6604 40.3347 78.1366 41.1294 77.4695 41.7927C77.4445 41.8176 77.4187 41.8411 77.3922 41.8634Z"
            fill="#1A1721"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.0078 4.27461C12.4193 4.21106 11.8241 4.27151 11.2594 4.45237C10.6946 4.63325 10.1719 4.93087 9.72451 5.32736C9.27709 5.72388 8.91464 6.21077 8.66084 6.75791C8.40702 7.30509 8.26759 7.90019 8.25184 8.50562C8.23608 9.11105 8.34437 9.71289 8.56947 10.2731C8.79456 10.8332 9.13136 11.339 9.55778 11.7591L9.56563 11.7668L9.5656 11.7668L17.0096 19.2301L24.4536 11.7668C24.4776 11.7428 24.5024 11.7199 24.5278 11.6981C24.9176 11.2921 25.2267 10.8121 25.4367 10.2844C25.6592 9.72519 25.7655 9.12497 25.7486 8.52145C25.7316 7.91795 25.5918 7.32495 25.3383 6.7797C25.0849 6.23448 24.7235 5.74921 24.2776 5.35372C23.8318 4.95826 23.3111 4.66101 22.7484 4.47971C22.1857 4.29842 21.5925 4.23674 21.0057 4.2983C20.4189 4.35987 19.8503 4.54343 19.3354 4.83817C18.8205 5.13293 18.3701 5.53276 18.0131 6.01344C17.7765 6.33191 17.4028 6.51918 17.0061 6.51808C16.6094 6.51698 16.2368 6.32764 16.002 6.00786C15.6465 5.52364 15.1966 5.12027 14.6813 4.82233C14.1661 4.52442 13.5963 4.33816 13.0078 4.27461ZM26.1427 13.6135L17.8946 21.8829C17.6601 22.118 17.3417 22.2501 17.0096 22.2501C16.6775 22.2501 16.3591 22.118 16.1246 21.8829L7.79926 13.536C7.12889 12.8746 6.60164 12.0809 6.24976 11.2052C5.89718 10.3278 5.72807 9.38659 5.75268 8.44059C5.7773 7.49459 5.99513 6.56352 6.39296 5.7059C6.79081 4.84824 7.36028 4.08214 8.06638 3.45637C8.77251 2.83057 9.59993 2.35875 10.4969 2.0715C11.3938 1.78423 12.3402 1.68798 13.2762 1.78906C14.2122 1.89013 15.1167 2.18624 15.9327 2.65807C16.3187 2.88129 16.681 3.1415 17.0147 3.43437C17.3478 3.14529 17.7089 2.88861 18.0934 2.66853C18.9088 2.2017 19.8115 1.90986 20.7449 1.81195C21.6782 1.71403 22.6214 1.81224 23.5151 2.10017C24.4087 2.38809 25.233 2.85932 25.9366 3.48349C26.6402 4.10762 27.208 4.87118 27.6053 5.72583C28.0026 6.58045 28.2211 7.50823 28.2476 8.45123C28.2741 9.39422 28.1081 10.3329 27.7595 11.2088C27.4109 12.0847 26.8871 12.8794 26.22 13.5427C26.195 13.5676 26.1692 13.5912 26.1427 13.6135Z"
            fill="#1A1721"
          />
        </g>
      </svg>
    </Center>
  );
}
