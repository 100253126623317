export const GondolaIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5190_19519)">
        <path
          d="M9 20H31V29C31 31.2091 29.2091 33 27 33H13C10.7909 33 9 31.2091 9 29V20Z"
          fill="#FF8972"
        />
        <path
          d="M6.66663 8.33333L33.3333 5M20 6.66667V23.3333M8.33329 23.3333H31.6666M11.49 13.3333H28.6666C32.75 18.3333 32.75 28.3333 28.3333 33.3333H11.49C7.24996 28.3333 7.24996 18.3333 11.49 13.3333Z"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5190_19519">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
