import { GetObjectCommand, GetObjectCommandInput } from '@aws-sdk/client-s3';
import {
  PresignedPostOptions,
  createPresignedPost,
} from '@aws-sdk/s3-presigned-post';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { s3Client } from '@zorro/clients';
import type { NextApiRequest, NextApiResponse } from 'next';
import { v4 as uuidv4 } from 'uuid';

import {
  BENEFIT_DOCUMENTS_BUCKET,
  EMPLOYER_DOCUMENTS_BUCKET,
  expires,
} from './bucketUtils';
import { parseEnumValue } from './enumUtils';
import { logger } from './logger';

export enum BucketType {
  EMPLOYER = 'EMPLOYER',
  BENEFIT = 'BENEFIT',
}

const bucketByDocumentType = {
  [BucketType.EMPLOYER]: EMPLOYER_DOCUMENTS_BUCKET,
  [BucketType.BENEFIT]: BENEFIT_DOCUMENTS_BUCKET,
};

export const handleCreatePresignedPost = async (
  req: NextApiRequest,
  res: NextApiResponse
) => {
  // INFO: use uuid in upload path to avoid overwriting files with the same filename
  const uploadKey = `${uuidv4()}/${req.query.file}`;
  const documentType = parseEnumValue(
    BucketType,
    req.query.documentType as string
  );

  const bucketParams: PresignedPostOptions = {
    Bucket: bucketByDocumentType[documentType as BucketType],
    Key: uploadKey,
    Fields: {
      'Content-Type': req.query.fileType as string,
    },
    Expires: expires,
  };

  const data = await createPresignedPost(s3Client, bucketParams);
  res.status(200).json({ ...data });
};

export const handleGetSignedUrl = async (
  req: NextApiRequest,
  res: NextApiResponse
) => {
  const key = req.query.key as string | undefined | null;
  const documentType = parseEnumValue(
    BucketType,
    req.query.documentType as string
  );

  if (!key) {
    res.status(500).json({ error: `Invalid key: ${key}` });
    return;
  }

  const bucketParams: GetObjectCommandInput = {
    Bucket: bucketByDocumentType[documentType as BucketType],
    Key: key,
  };

  const command = new GetObjectCommand(bucketParams);
  const url = await getSignedUrl(s3Client, command, { expiresIn: expires });
  res.status(200).json({ url });
};

export const documentsHandler = async (
  req: NextApiRequest,
  res: NextApiResponse
) => {
  try {
    const operationType = req.query.operationType;

    switch (operationType) {
      case 'createPresignedPost': {
        await handleCreatePresignedPost(req, res);
        break;
      }
      case 'getSignedUrl': {
        await handleGetSignedUrl(req, res);
        break;
      }
      default: {
        res
          .status(500)
          .json({ error: `Invalid operationType: ${operationType}` });
      }
    }
  } catch (error) {
    logger.error(error);

    res.status(500).json({
      error: `Failed to sign employer document operation: ${error}`,
    });
  }
};
