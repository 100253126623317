import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const IconZorroPay = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="15"
        viewBox="0 0 20 15"
        fill="none"
      >
        <path
          d="M1.66666 4.21672H18.3333M3.33332 13.8H16.6667C17.5871 13.8 18.3333 13.0539 18.3333 12.1334V2.96672C18.3333 2.04624 17.5871 1.30005 16.6667 1.30005H3.33332C2.41285 1.30005 1.66666 2.04624 1.66666 2.96672V12.1334C1.66666 13.0539 2.41285 13.8 3.33332 13.8Z"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.9438 8.01536V7.50946C11.9438 7.43568 11.8898 7.37574 11.8232 7.37574H10.3906C10.3145 7.37574 10.2527 7.30723 10.2527 7.22292V6.35736C10.2527 6.32574 10.2295 6.30005 10.201 6.30005H9.2208C8.87782 6.30005 8.59963 6.60833 8.59963 6.98841V7.50814C8.59963 7.53976 8.62281 7.56545 8.65134 7.56545H10.0441C10.1701 7.56545 10.2301 7.73738 10.1368 7.83158L8.55743 9.41975C8.41476 9.56335 8.33333 9.76624 8.33333 9.97967V10.4961C8.33333 10.5699 8.38742 10.6298 8.454 10.6298H9.89547C9.97155 10.6298 10.0334 10.6983 10.0334 10.7826V11.6594C10.0334 11.691 10.0566 11.7167 10.0851 11.7167H11.0903C11.4237 11.7167 11.6936 11.417 11.6936 11.0481V10.5093C11.6936 10.4777 11.6704 10.452 11.6419 10.452H10.1671C10.0566 10.452 10.0042 10.3011 10.0863 10.2194L11.7203 8.57659C11.863 8.43299 11.9444 8.2301 11.9444 8.01668L11.9438 8.01536Z"
          fill="#1A1721"
        />
      </svg>
    </Center>
  );
};
