import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const FamilyIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_4832_18348)">
          <path
            d="M19.6147 5.44091C21.8684 4.71894 24.3476 6.17927 24.8115 8.49985C25.6399 12.644 26.1441 18.0765 23.1687 23.2301C19.8643 28.9535 15.5941 30.3348 11.26 31.2013C9.28359 31.5965 7.26755 30.4413 6.63445 28.5278C5.32474 24.5693 4.35499 19.8144 7.57938 14.2295C10.6296 8.94644 15.4582 6.77244 19.6147 5.44091Z"
            fill="#FFBBAF"
          />
          <path
            d="M15.9998 14.6667C18.9454 14.6667 21.3332 12.2789 21.3332 9.33333C21.3332 6.38781 18.9454 4 15.9998 4C13.0543 4 10.6665 6.38781 10.6665 9.33333C10.6665 12.2789 13.0543 14.6667 15.9998 14.6667Z"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 28V25.3333C8 23.9188 8.5619 22.5623 9.5621 21.5621C10.5623 20.5619 11.9188 20 13.3333 20H18.6667C20.0812 20 21.4377 20.5619 22.4379 21.5621C23.4381 22.5623 24 23.9188 24 25.3333V28"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M24 4.17334C25.1472 4.46707 26.164 5.13427 26.8902 6.06975C27.6163 7.00523 28.0105 8.15578 28.0105 9.34001C28.0105 10.5242 27.6163 11.6748 26.8902 12.6103C26.164 13.5457 25.1472 14.2129 24 14.5067"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M30.6665 28.0002V25.3335C30.6597 24.1564 30.2637 23.0146 29.5402 22.0861C28.8167 21.1575 27.8063 20.4945 26.6665 20.2002"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 10.6665C6.85577 10.8939 5.84159 11.4105 5.11735 12.1347C4.39311 12.8589 4 13.7497 4 14.6665C4 15.5833 4.39311 16.4741 5.11735 17.1983C5.84159 17.9226 6.85577 18.4391 8 18.6665"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.6665 29.1335V26.4668C2.67327 25.2897 3.06928 24.1479 3.79281 23.2194C4.51634 22.2908 5.52674 21.6278 6.6665 21.3335"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_4832_18348">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
