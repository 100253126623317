export const MoneyStackMedium = () => {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_131_761"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="96"
        height="96"
      >
        <rect width="96" height="96" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_131_761)">
        <path
          d="M34.25 44C24.9165 44 15 41.107 15 35.75C15 34.991 15.616 34.375 16.375 34.375C17.134 34.375 17.75 34.991 17.75 35.75C17.75 38.0462 24.0255 41.25 34.25 41.25C44.4745 41.25 50.75 38.0462 50.75 35.75C50.75 34.991 51.366 34.375 52.125 34.375C52.884 34.375 53.5 34.991 53.5 35.75C53.5 41.107 43.5835 44 34.25 44Z"
          fill="#8033EF"
        />
        <path
          d="M61.75 77C52.4165 77 42.5 74.107 42.5 68.75C42.5 67.991 43.116 67.375 43.875 67.375C44.634 67.375 45.25 67.991 45.25 68.75C45.25 71.0462 51.5255 74.25 61.75 74.25C71.9745 74.25 78.25 71.0462 78.25 68.75C78.25 67.991 78.866 67.375 79.625 67.375C80.384 67.375 81 67.991 81 68.75C81 74.107 71.0835 77 61.75 77Z"
          fill="#8033EF"
        />
        <path
          d="M61.75 85.625C52.4165 85.625 42.5 82.732 42.5 77.375C42.5 76.616 43.116 76 43.875 76C44.634 76 45.25 76.616 45.25 77.375C45.25 79.6712 51.5255 82.875 61.75 82.875C71.9745 82.875 78.25 79.6712 78.25 77.375C78.25 76.616 78.866 76 79.625 76C80.384 76 81 76.616 81 77.375C81 82.732 71.0835 85.625 61.75 85.625Z"
          fill="#8033EF"
        />
        <path
          d="M61.75 68.75C52.4165 68.75 42.5 65.857 42.5 60.5C42.5 59.741 43.116 59.125 43.875 59.125C44.634 59.125 45.25 59.741 45.25 60.5C45.25 62.7962 51.5255 66 61.75 66C71.9745 66 78.25 62.7962 78.25 60.5C78.25 59.741 78.866 59.125 79.625 59.125C80.384 59.125 81 59.741 81 60.5C81 65.857 71.0835 68.75 61.75 68.75Z"
          fill="#8033EF"
        />
        <path
          d="M61.75 60.5C52.4165 60.5 42.5 57.607 42.5 52.25C42.5 46.893 52.4165 44 61.75 44C71.0835 44 81 46.893 81 52.25C81 57.607 71.0835 60.5 61.75 60.5ZM61.75 46.75C51.5255 46.75 45.25 49.9538 45.25 52.25C45.25 54.5463 51.5255 57.75 61.75 57.75C71.9745 57.75 78.25 54.5463 78.25 52.25C78.25 49.9538 71.9745 46.75 61.75 46.75Z"
          fill="#8033EF"
        />
        <path
          d="M79.625 79.125C78.866 79.125 78.25 78.509 78.25 77.75V53.25C78.25 52.491 78.866 51.875 79.625 51.875C80.384 51.875 81 52.491 81 53.25V77.75C81 78.509 80.384 79.125 79.625 79.125Z"
          fill="#8033EF"
        />
        <path
          d="M43.875 79.125C43.116 79.125 42.5 78.509 42.5 77.75V53.25C42.5 52.491 43.116 51.875 43.875 51.875C44.634 51.875 45.25 52.491 45.25 53.25V77.75C45.25 78.509 44.634 79.125 43.875 79.125Z"
          fill="#8033EF"
        />
        <path
          d="M34.25 35.75C24.9165 35.75 15 32.857 15 27.5C15 26.741 15.616 26.125 16.375 26.125C17.134 26.125 17.75 26.741 17.75 27.5C17.75 29.7962 24.0255 33 34.25 33C44.4745 33 50.75 29.7962 50.75 27.5C50.75 26.741 51.366 26.125 52.125 26.125C52.884 26.125 53.5 26.741 53.5 27.5C53.5 32.857 43.5835 35.75 34.25 35.75Z"
          fill="#8033EF"
        />
        <path
          d="M34.25 60.5C24.9165 60.5 15 57.607 15 52.25C15 51.491 15.616 50.875 16.375 50.875C17.134 50.875 17.75 51.491 17.75 52.25C17.75 54.5462 24.0255 57.75 34.25 57.75C35.746 57.75 37.0495 57.6895 38.2292 57.5658C38.9745 57.4833 39.662 58.0333 39.7417 58.7868C39.8215 59.5403 39.2743 60.2195 38.5208 60.2992C37.2393 60.4368 35.8423 60.5 34.25 60.5Z"
          fill="#8033EF"
        />
        <path
          d="M34.25 68.75C24.9165 68.75 15 65.857 15 60.5C15 59.741 15.616 59.125 16.375 59.125C17.134 59.125 17.75 59.741 17.75 60.5C17.75 62.7962 24.0255 66 34.25 66C35.746 66 37.0495 65.9395 38.2292 65.8158C38.9745 65.7195 39.662 66.2833 39.7417 67.0368C39.8215 67.7903 39.2743 68.4695 38.5208 68.5492C37.2393 68.6868 35.8423 68.75 34.25 68.75Z"
          fill="#8033EF"
        />
        <path
          d="M34.25 52.25C24.9165 52.25 15 49.357 15 44C15 43.241 15.616 42.625 16.375 42.625C17.134 42.625 17.75 43.241 17.75 44C17.75 46.2962 24.0255 49.5 34.25 49.5C35.746 49.5 37.0495 49.4395 38.2292 49.3157C38.9745 49.2277 39.662 49.7805 39.7417 50.5367C39.8215 51.2902 39.2743 51.9695 38.5208 52.0492C37.2393 52.1868 35.8423 52.25 34.25 52.25Z"
          fill="#8033EF"
        />
        <path
          d="M34.25 27.5C24.9165 27.5 15 24.607 15 19.25C15 13.893 24.9165 11 34.25 11C43.5835 11 53.5 13.893 53.5 19.25C53.5 24.607 43.5835 27.5 34.25 27.5ZM34.25 13.75C24.0255 13.75 17.75 16.9538 17.75 19.25C17.75 21.5463 24.0255 24.75 34.25 24.75C44.4745 24.75 50.75 21.5463 50.75 19.25C50.75 16.9538 44.4745 13.75 34.25 13.75Z"
          fill="#8033EF"
        />
        <path
          d="M16.375 61.875C15.616 61.875 15 61.259 15 60.5V19.25C15 18.491 15.616 17.875 16.375 17.875C17.134 17.875 17.75 18.491 17.75 19.25V60.5C17.75 61.259 17.134 61.875 16.375 61.875Z"
          fill="#8033EF"
        />
        <path
          d="M52.125 37.125C51.366 37.125 50.75 36.509 50.75 35.75V19.25C50.75 18.491 51.366 17.875 52.125 17.875C52.884 17.875 53.5 18.491 53.5 19.25V35.75C53.5 36.509 52.884 37.125 52.125 37.125Z"
          fill="#8033EF"
        />
      </g>
    </svg>
  );
};
