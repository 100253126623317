import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const DrugsIcon = ({ imageAlt, style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-label={imageAlt}
      >
        <mask
          id="mask0_4832_18385"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="33"
          height="32"
        >
          <rect x="0.5" width="32" height="32" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_4832_18385)">
          <circle cx="16.5" cy="18" r="10" fill="#FFD880" />
          <path
            d="M27.8763 25.4049C27.8763 28.1441 25.6558 30.3646 22.9167 30.3646C20.1775 30.3646 17.957 28.1441 17.957 25.4049C17.957 22.6658 20.1775 20.4453 22.9167 20.4453C25.6558 20.4453 27.8763 22.6658 27.8763 25.4049Z"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <path
            d="M21.4004 24.2002L24.2288 27.0286"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M14.053 12.4382C15.4623 13.8232 15.4819 16.0884 14.0969 17.4977C14.0969 17.4977 14.0969 17.4977 14.0969 17.4977L7.82379 23.8806C6.43875 25.2898 4.17358 25.3095 2.76428 23.9245C1.35501 22.5395 1.33537 20.2743 2.72036 18.865C2.72036 18.865 2.72037 18.865 2.72037 18.865L8.99343 12.4821C10.3785 11.0728 12.6437 11.0531 14.053 12.4382Z"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <path
            d="M2.72037 18.8636L5.33121 16.2071L10.4346 21.2227L7.82379 23.8792C6.43875 25.2885 4.17357 25.3081 2.76427 23.9231C1.35501 22.5381 1.33537 20.2729 2.72036 18.8636C2.72036 18.8636 2.72037 18.8636 2.72037 18.8636Z"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <path
            d="M27.1605 15.2066C25.7178 16.5568 23.4538 16.4819 22.1035 15.0392C22.1035 15.0392 22.1035 15.0392 22.1035 15.0392L15.9882 8.50499C14.638 7.0623 14.713 4.79829 16.1556 3.44806C17.5983 2.09786 19.8623 2.17284 21.2125 3.61549C21.2125 3.61549 21.2125 3.61549 21.2125 3.61549L27.3279 10.1497C28.6781 11.5923 28.6031 13.8564 27.1605 15.2066Z"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <path
            d="M21.2124 3.61533L23.7576 6.33485L18.5332 11.2243L15.9881 8.50483C14.6379 7.06214 14.7128 4.79812 16.1555 3.4479C17.5982 2.0977 19.8622 2.17268 21.2124 3.61532C21.2124 3.61533 21.2124 3.61533 21.2124 3.61533Z"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
        </g>
      </svg>
    </Center>
  );
};
