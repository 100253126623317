export const UploadIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_617_6013)">
        <path
          d="M27.6434 13.2347C27.472 16.0351 24.933 18.3185 22.1239 18.3185H20.529V13.5978C20.529 12.3864 19.5472 11.4045 18.3358 11.4045H9.65572C8.44433 11.4045 7.46537 12.3864 7.46537 13.5978V18.3185H5.89087C3.00621 18.3185 0.536961 16.1165 0.356875 13.2347C0.202875 10.7103 1.76869 8.52283 3.99682 7.75294C3.97648 7.53216 3.96778 7.30849 3.96778 7.07897C3.96778 3.45357 6.90472 0.519531 10.5301 0.519531C13.0836 0.519531 15.3001 1.97493 16.3778 4.11009C16.8572 3.92416 17.3801 3.82829 17.9204 3.82829C20.0672 3.82829 21.8451 5.39995 22.1675 7.45954H22.2198C25.3339 7.45954 27.8351 10.0798 27.6434 13.2347Z"
          fill="#00BEE9"
        />
        <path
          d="M20.5313 13.5993V25.2868C20.5313 26.4989 19.5485 27.4818 18.3364 27.4818H9.65879C8.44658 27.4818 7.4679 26.4989 7.4679 25.2868V13.5993C7.4679 12.3871 8.44658 11.4043 9.65879 11.4043H18.3364C19.5485 11.4043 20.5313 12.3871 20.5313 13.5993Z"
          fill="#F0E6FF"
        />
        <path
          d="M10.8855 18.7499L13.5986 15.3576C13.805 15.0996 14.1919 15.0996 14.3982 15.3576L17.1156 18.7499C17.3822 19.0853 17.1457 19.584 16.7158 19.584H16.3675C16.0837 19.584 15.8559 19.8119 15.8559 20.0957V23.8793C15.8559 24.1631 15.628 24.391 15.3441 24.391H12.6569C12.3732 24.391 12.1453 24.1631 12.1453 23.8793V20.0957C12.1453 19.8119 11.9175 19.584 11.6337 19.584H11.2854C10.8554 19.584 10.6146 19.0853 10.8855 18.7499Z"
          fill="#CDABFF"
        />
        <path
          d="M14.6252 15.175C14.3085 14.7792 13.6993 14.7657 13.3716 15.175L10.6595 18.5664C10.236 19.0911 10.6104 19.8734 11.2853 19.8734H11.6337C11.7577 19.8734 11.8547 19.9705 11.8547 20.0945V23.8782C11.8547 24.3205 12.2147 24.6804 12.6569 24.6804H15.3441C15.7863 24.6804 16.1463 24.3205 16.1463 23.8782V20.0945C16.1463 19.9705 16.2436 19.8734 16.3676 19.8734H16.7157C17.3874 19.8734 17.7622 19.0948 17.3424 18.5671L14.6252 15.175ZM16.7156 19.2924H16.3676C15.9253 19.2924 15.5653 19.6522 15.5653 20.0945V23.8782C15.5653 24.0022 15.468 24.0994 15.344 24.0994H12.6569C12.5329 24.0994 12.4356 24.0022 12.4356 23.8782V20.0945C12.4356 19.6522 12.0759 19.2924 11.6336 19.2924H11.2853C11.099 19.2924 10.9941 19.0769 11.1116 18.9312C11.1119 18.9308 11.1122 18.9305 11.1125 18.93L13.8252 15.5379C13.915 15.4259 14.0805 15.4243 14.1716 15.5379L16.8882 18.9293C17.0028 19.0736 16.9042 19.2924 16.7156 19.2924Z"
          fill="#3C0789"
        />
        <path
          d="M22.4102 7.17284C21.8429 4.48364 19.0884 2.93112 16.5171 3.75351C15.3137 1.57262 13.0424 0.228516 10.5295 0.228516C6.7519 0.228516 3.67835 3.3019 3.67835 7.07949C3.67835 7.24886 3.68262 7.40718 3.69142 7.55823C1.39498 8.45554 -0.0861738 10.7645 0.0670058 13.2525C0.255076 16.2573 2.81254 18.6111 5.88947 18.6111H7.17742V25.2863C7.17742 26.6568 8.29064 27.7718 9.65886 27.7718H18.3364C19.7069 27.7718 20.8218 26.6568 20.8218 25.2863V18.6111H22.1228C25.0939 18.6111 27.7546 16.157 27.9328 13.252C28.1348 9.94364 25.5522 7.27839 22.4102 7.17284ZM18.3363 27.1909H9.65886C8.61089 27.1909 7.75842 26.3365 7.75842 25.2863V13.5988C7.75842 12.5488 8.61089 11.6944 9.65886 11.6944H18.3347C19.3849 11.6944 20.2391 12.5487 20.2391 13.5988V18.3206C20.2391 18.3235 20.2407 18.326 20.2408 18.329V25.2863C20.2408 26.3365 19.3866 27.1909 18.3363 27.1909ZM27.3529 13.2166C27.1926 15.8257 24.7977 18.0301 22.1228 18.0301H20.8218V13.5988C20.8218 12.2283 19.7069 11.1135 18.3364 11.1135H18.3347H9.65886H9.65717C8.28894 11.1135 7.17572 12.2284 7.17572 13.5988V18.0301H5.88947C3.11868 18.0301 0.815951 15.9156 0.646857 13.2164C0.504998 10.9129 1.92179 8.77995 4.09255 8.02969C4.21877 7.98599 4.29905 7.86158 4.28686 7.72841C4.26843 7.52853 4.25935 7.31634 4.25935 7.07949C4.25935 3.62215 7.07215 0.809516 10.5295 0.809516C12.908 0.809516 15.0492 2.12459 16.1179 4.24148C16.1854 4.37481 16.3423 4.43541 16.4816 4.38165C18.8154 3.48384 21.4753 4.92442 21.8805 7.50534C21.9029 7.6466 22.0246 7.75073 22.1676 7.75073H22.2209C25.1695 7.75073 27.5354 10.227 27.3529 13.2166Z"
          fill="#3C0789"
        />
      </g>
      <defs>
        <clipPath id="clip0_617_6013">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
