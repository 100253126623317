const IconZorroPeople = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
      <g
        stroke="#1A1721"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      >
        <path d="M10.056 9.325a2.889 2.889 0 1 0 0-5.778 2.889 2.889 0 0 0 0 5.778ZM5.722 16.548v-1.444a2.889 2.889 0 0 1 2.89-2.89H11.5a2.889 2.889 0 0 1 2.889 2.89v1.444M14.389 3.64a2.89 2.89 0 0 1 0 5.598M18 16.545v-1.444a2.89 2.89 0 0 0-2.167-2.78M5.722 7.156c-.62.123-1.169.403-1.561.796-.393.392-.606.874-.606 1.371s.213.98.606 1.371c.392.393.941.672 1.561.796M2.834 17.163v-1.445A2.89 2.89 0 0 1 5 12.938" />
      </g>
    </svg>
  );
};
export { IconZorroPeople };
