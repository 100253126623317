export const MoneyStackSmall = () => {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_131_745"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="96"
        height="96"
      >
        <rect width="96" height="96" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_131_745)">
        <path
          d="M61.75 78.75C52.4165 78.75 42.5 75.857 42.5 70.5C42.5 69.741 43.116 69.125 43.875 69.125C44.634 69.125 45.25 69.741 45.25 70.5C45.25 72.7962 51.5255 76 61.75 76C71.9745 76 78.25 72.7962 78.25 70.5C78.25 69.741 78.866 69.125 79.625 69.125C80.384 69.125 81 69.741 81 70.5C81 75.857 71.0835 78.75 61.75 78.75Z"
          fill="#8033EF"
        />
        <path
          d="M61.75 70.5C52.4165 70.5 42.5 67.607 42.5 62.25C42.5 56.893 52.4165 54 61.75 54C71.0835 54 81 56.893 81 62.25C81 67.607 71.0835 70.5 61.75 70.5ZM61.75 56.75C51.5255 56.75 45.25 59.9538 45.25 62.25C45.25 64.5463 51.5255 67.75 61.75 67.75C71.9745 67.75 78.25 64.5463 78.25 62.25C78.25 59.9538 71.9745 56.75 61.75 56.75Z"
          fill="#8033EF"
        />
        <path
          d="M79.625 72.125C78.866 72.125 78.25 71.509 78.25 70.75V62.25C78.25 61.491 78.866 60.875 79.625 60.875C80.384 60.875 81 61.491 81 62.25V70.75C81 71.509 80.384 72.125 79.625 72.125Z"
          fill="#8033EF"
        />
        <path
          d="M43.875 72.125C43.116 72.125 42.5 71.509 42.5 70.75V62.25C42.5 61.491 43.116 60.875 43.875 60.875C44.634 60.875 45.25 61.491 45.25 62.25V70.75C45.25 71.509 44.634 72.125 43.875 72.125Z"
          fill="#8033EF"
        />
        <path
          d="M34.25 48C24.9165 48 15 45.107 15 39.75C15 38.991 15.616 38.375 16.375 38.375C17.134 38.375 17.75 38.991 17.75 39.75C17.75 42.0462 24.0255 45.25 34.25 45.25C44.4745 45.25 50.75 42.0462 50.75 39.75C50.75 38.991 51.366 38.375 52.125 38.375C52.884 38.375 53.5 38.991 53.5 39.75C53.5 45.107 43.5835 48 34.25 48Z"
          fill="#8033EF"
        />
        <path
          d="M34.25 56.625C24.9165 56.625 15 53.732 15 48.375C15 47.616 15.616 47 16.375 47C17.134 47 17.75 47.616 17.75 48.375C17.75 50.6712 24.0255 53.875 34.25 53.875C44.4745 53.875 50.75 50.6712 50.75 48.375C50.75 47.616 51.366 47 52.125 47C52.884 47 53.5 47.616 53.5 48.375C53.5 53.732 43.5835 56.625 34.25 56.625Z"
          fill="#8033EF"
        />
        <path
          d="M34.25 39.75C24.9165 39.75 15 36.857 15 31.5C15 30.741 15.616 30.125 16.375 30.125C17.134 30.125 17.75 30.741 17.75 31.5C17.75 33.7962 24.0255 37 34.25 37C44.4745 37 50.75 33.7962 50.75 31.5C50.75 30.741 51.366 30.125 52.125 30.125C52.884 30.125 53.5 30.741 53.5 31.5C53.5 36.857 43.5835 39.75 34.25 39.75Z"
          fill="#8033EF"
        />
        <path
          d="M34.25 64.5C24.9165 64.5 15 61.607 15 56.25C15 55.491 15.616 54.875 16.375 54.875C17.134 54.875 17.75 55.491 17.75 56.25C17.75 58.5462 24.0255 61.75 34.25 61.75C35.746 61.75 37.0495 61.6895 38.2292 61.5658C38.9745 61.4833 39.662 62.0333 39.7417 62.7868C39.8215 63.5403 39.2743 64.2195 38.5208 64.2992C37.2393 64.4368 35.8423 64.5 34.25 64.5Z"
          fill="#8033EF"
        />
        <path
          d="M34.25 72.75C24.9165 72.75 15 69.857 15 64.5C15 63.741 15.616 63.125 16.375 63.125C17.134 63.125 17.75 63.741 17.75 64.5C17.75 66.7962 24.0255 70 34.25 70C35.746 70 37.0495 69.9395 38.2292 69.8158C38.9745 69.7195 39.662 70.2833 39.7417 71.0368C39.8215 71.7903 39.2743 72.4695 38.5208 72.5492C37.2393 72.6868 35.8423 72.75 34.25 72.75Z"
          fill="#8033EF"
        />
        <path
          d="M34.25 31.5C24.9165 31.5 15 28.607 15 23.25C15 17.893 24.9165 15 34.25 15C43.5835 15 53.5 17.893 53.5 23.25C53.5 28.607 43.5835 31.5 34.25 31.5ZM34.25 17.75C24.0255 17.75 17.75 20.9538 17.75 23.25C17.75 25.5463 24.0255 28.75 34.25 28.75C44.4745 28.75 50.75 25.5463 50.75 23.25C50.75 20.9538 44.4745 17.75 34.25 17.75Z"
          fill="#8033EF"
        />
        <path
          d="M16.375 65.875C15.616 65.875 15 65.259 15 64.5V23.25C15 22.491 15.616 21.875 16.375 21.875C17.134 21.875 17.75 22.491 17.75 23.25V64.5C17.75 65.259 17.134 65.875 16.375 65.875Z"
          fill="#8033EF"
        />
        <path
          d="M52.125 49.125C51.366 49.125 50.75 48.509 50.75 47.75V23.25C50.75 22.491 51.366 21.875 52.125 21.875C52.884 21.875 53.5 22.491 53.5 23.25V47.75C53.5 48.509 52.884 49.125 52.125 49.125Z"
          fill="#8033EF"
        />
      </g>
    </svg>
  );
};
