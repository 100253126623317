import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const ReferralRequiredIcon = ({ style, imageAlt }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-label={imageAlt}
      >
        <g clipPath="url(#clip0_1_3503)">
          <path
            d="M5.5 7H10.5"
            stroke="#1A1721"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.5 10.3335H8.83333"
            stroke="#1A1721"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.33325 2V4.66667C9.33325 4.84348 9.40349 5.01305 9.52851 5.13807C9.65354 5.2631 9.82311 5.33333 9.99992 5.33333H12.6666"
            fill="white"
          />
          <path
            d="M9.33325 2V4.66667C9.33325 4.84348 9.40349 5.01305 9.52851 5.13807C9.65354 5.2631 9.82311 5.33333 9.99992 5.33333H12.6666"
            stroke="#1A1721"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.66659 14H4.66659C4.31296 14 3.97382 13.8595 3.72378 13.6095C3.47373 13.3594 3.33325 13.0203 3.33325 12.6667V3.33333C3.33325 2.97971 3.47373 2.64057 3.72378 2.39052C3.97382 2.14048 4.31296 2 4.66659 2H9.33325L12.6666 5.33333V8.66667M9.33325 12.6667H13.9999M13.9999 12.6667L11.9999 10.6667M13.9999 12.6667L11.9999 14.6667"
            stroke="#1A1721"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_3503">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
