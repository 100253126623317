export const PcpReferralIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4832_18482)">
        <path
          d="M29 33V13.7971L25.0625 9.5L21.4178 5H10C8.89543 5 8 5.89452 8 6.99909V12.8538V27.1462V31.0011C8 33.2102 9.79086 35 12 35H23.5023H27C28.1046 35 29 34.1046 29 33Z"
          fill="#FFD880"
        />
        <path
          d="M13.75 17.5H26.25"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.75 25.834H22.0833"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.3335 5V11.6667C23.3335 12.1087 23.5091 12.5326 23.8217 12.8452C24.1342 13.1577 24.5581 13.3333 25.0002 13.3333H31.6668"
          fill="white"
        />
        <path
          d="M23.3335 5V11.6667C23.3335 12.1087 23.5091 12.5326 23.8217 12.8452C24.1342 13.1577 24.5581 13.3333 25.0002 13.3333H31.6668"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.1668 35H11.6668C10.7828 35 9.93493 34.6488 9.30981 34.0237C8.68469 33.3986 8.3335 32.5507 8.3335 31.6667V8.33333C8.3335 7.44928 8.68469 6.60143 9.30981 5.97631C9.93493 5.35119 10.7828 5 11.6668 5H23.3335L31.6668 13.3333V21.6667M23.3335 31.6667H35.0002M35.0002 31.6667L30.0002 26.6667M35.0002 31.6667L30.0002 36.6667"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4832_18482">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
