export const formatPercentage = (
  amount: number,
  {
    minimumFractionDigits = 0,
    maximumFractionDigits = 0,
    signDisplay = 'never' as Intl.NumberFormatOptions['signDisplay'],
  } = {}
) => {
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits,
    maximumFractionDigits,
    signDisplay,
  }).format(amount);
};
