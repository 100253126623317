import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const AddExpenseIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.6665 28V6.66667C6.6665 5.95942 6.94746 5.28115 7.44755 4.78105C7.94765 4.28095 8.62593 4 9.33317 4H22.6665C23.3737 4 24.052 4.28095 24.5521 4.78105C25.0522 5.28115 25.3332 5.95942 25.3332 6.66667V28L21.3332 25.3333L18.6665 28L15.9998 25.3333L13.3332 28L10.6665 25.3333L6.6665 28Z"
          fill="#80E8B6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.33337 4.5502C8.77199 4.5502 8.23361 4.7732 7.83666 5.17015C7.4397 5.5671 7.2167 6.10549 7.2167 6.66686V26.9725L10.3616 24.8759C10.5798 24.7305 10.8702 24.7592 11.0556 24.9446L13.3334 27.2224L15.6111 24.9446C15.7143 24.8415 15.8542 24.7835 16 24.7835C16.1459 24.7835 16.2858 24.8415 16.3889 24.9446L18.6667 27.2224L20.9445 24.9446C21.1298 24.7592 21.4203 24.7305 21.6385 24.8759L24.7834 26.9725V6.66686C24.7834 6.10549 24.5604 5.5671 24.1634 5.17015C23.7665 4.7732 23.2281 4.5502 22.6667 4.5502H9.33337ZM7.05884 4.39234C7.66208 3.78909 8.48025 3.4502 9.33337 3.4502H22.6667C23.5198 3.4502 24.338 3.78909 24.9412 4.39234C25.5445 4.99558 25.8834 5.81375 25.8834 6.66686V28.0002C25.8834 28.203 25.7717 28.3894 25.5929 28.4851C25.414 28.5808 25.1971 28.5703 25.0283 28.4578L21.4034 26.0413L19.0556 28.3891C18.9525 28.4922 18.8126 28.5502 18.6667 28.5502C18.5208 28.5502 18.3809 28.4922 18.2778 28.3891L16 26.1113L13.7223 28.3891C13.6191 28.4922 13.4792 28.5502 13.3334 28.5502C13.1875 28.5502 13.0476 28.4922 12.9445 28.3891L10.5966 26.0413L6.97178 28.4578C6.80301 28.5703 6.58601 28.5808 6.40718 28.4851C6.22834 28.3894 6.1167 28.203 6.1167 28.0002V6.66686C6.1167 5.81375 6.4556 4.99558 7.05884 4.39234Z"
          fill="#3C0789"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.9999 8.1167C16.3036 8.1167 16.5499 8.36294 16.5499 8.6667V10.1167H18.6665C18.9703 10.1167 19.2165 10.3629 19.2165 10.6667C19.2165 10.9705 18.9703 11.2167 18.6665 11.2167H15.3332C14.9486 11.2167 14.5798 11.3695 14.3079 11.6414C14.036 11.9133 13.8832 12.2821 13.8832 12.6667C13.8832 13.0513 14.036 13.4201 14.3079 13.692C14.5798 13.9639 14.9486 14.1167 15.3332 14.1167H16.6665C17.3428 14.1167 17.9914 14.3854 18.4697 14.8636C18.9479 15.3418 19.2165 15.9904 19.2165 16.6667C19.2165 17.343 18.9479 17.9916 18.4697 18.4698C17.9914 18.948 17.3428 19.2167 16.6665 19.2167H16.5499V20.6667C16.5499 20.9705 16.3036 21.2167 15.9999 21.2167C15.6961 21.2167 15.4499 20.9705 15.4499 20.6667V19.2167H13.3332C13.0294 19.2167 12.7832 18.9705 12.7832 18.6667C12.7832 18.3629 13.0294 18.1167 13.3332 18.1167H16.6665C17.0511 18.1167 17.4199 17.9639 17.6918 17.692C17.9638 17.4201 18.1165 17.0513 18.1165 16.6667C18.1165 16.2821 17.9638 15.9133 17.6918 15.6414C17.4199 15.3695 17.0511 15.2167 16.6665 15.2167H15.3332C14.6569 15.2167 14.0083 14.948 13.5301 14.4698C13.0519 13.9916 12.7832 13.343 12.7832 12.6667C12.7832 11.9904 13.0519 11.3418 13.5301 10.8636C14.0083 10.3854 14.6569 10.1167 15.3332 10.1167H15.4499V8.6667C15.4499 8.36294 15.6961 8.1167 15.9999 8.1167Z"
          fill="#3C0789"
        />
      </svg>
    </Center>
  );
};
