import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';
import classNames from './Icon.module.scss';

export const IconZorroUsers3 = ({ style, grow }: SVGIconProps) => {
  return (
    <Center style={style} classNames={classNames} data-grow={grow}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        height="32"
        width="32"
        fill="none"
      >
        <mask
          id="mask0_297_250"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          height="33"
          width="32"
          x="0"
          y="0"
        >
          <rect y="0.00714111" width="32" height="32" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_297_250)">
          <path
            d="M19.6147 5.44805C21.8684 4.72608 24.3476 6.18642 24.8115 8.50699C25.6399 12.6511 26.1441 18.0837 23.1687 23.2372C19.8643 28.9606 15.5941 30.3419 11.26 31.2085C9.28359 31.6037 7.26755 30.4485 6.63445 28.535C5.32474 24.5765 4.35499 19.8215 7.57938 14.2367C10.6296 8.95358 15.4582 6.77958 19.6147 5.44805Z"
            fill="#FFBBAF"
          />
          <path
            d="M15.9998 14.6738C18.9454 14.6738 21.3332 12.286 21.3332 9.34047C21.3332 6.39496 18.9454 4.00714 15.9998 4.00714C13.0543 4.00714 10.6665 6.39496 10.6665 9.34047C10.6665 12.286 13.0543 14.6738 15.9998 14.6738Z"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 28.0071V25.3405C8 23.926 8.5619 22.5694 9.5621 21.5692C10.5623 20.569 11.9188 20.0071 13.3333 20.0071H18.6667C20.0812 20.0071 21.4377 20.569 22.4379 21.5692C23.4381 22.5694 24 23.926 24 25.3405V28.0071"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M24 4.18048C25.1472 4.47422 26.164 5.14142 26.8902 6.07689C27.6163 7.01237 28.0105 8.16292 28.0105 9.34715C28.0105 10.5314 27.6163 11.6819 26.8902 12.6174C26.164 13.5529 25.1472 14.2201 24 14.5138"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M30.6665 28.0072V25.3405C30.6597 24.1634 30.2637 23.0216 29.5402 22.093C28.8167 21.1645 27.8063 20.5014 26.6665 20.2072"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 10.6738C6.85577 10.9012 5.84159 11.4178 5.11735 12.142C4.39311 12.8663 4 13.757 4 14.6738C4 15.5906 4.39311 16.4814 5.11735 17.2056C5.84159 17.9299 6.85577 18.4464 8 18.6738"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.6665 29.1405V26.4738C2.67327 25.2967 3.06928 24.1549 3.79281 23.2264C4.51634 22.2978 5.52674 21.6348 6.6665 21.3405"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </Center>
  );
};
