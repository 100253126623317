import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const HealthShieldIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M57.1427 11.429C52.5713 11.429 47.9998 10.2861 33.1424 2.28613C28.5715 21.7147 30.7477 63.3476 34.2856 61.7147C51 54 50.5001 50.5 54.857 43.429C59.062 36.6046 58.3604 20.9528 57.1427 11.429Z"
          fill="#CCF2FB"
        />
        <mask
          id="mask0_1_11782"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="64"
          height="64"
        >
          <rect width="64" height="64" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1_11782)">
          <mask
            id="mask1_1_11782"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="64"
            height="64"
          >
            <rect y="6.10352e-05" width="64" height="64" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask1_1_11782)">
            <path
              d="M58.0545 17.3941C58.0239 15.7799 57.9946 14.255 57.9946 12.7799C57.9946 11.6241 57.0578 10.6872 55.9019 10.6872C46.9522 10.6872 40.1382 8.11493 34.4583 2.59248C33.6459 1.80221 32.3528 1.80258 31.5406 2.59248C25.8614 8.11493 19.0485 10.6872 10.0994 10.6872C8.94361 10.6872 8.0066 11.6241 8.0066 12.7799C8.0066 14.2554 7.9777 15.7808 7.94669 17.3953C7.65991 32.4171 7.26707 52.9904 32.314 61.6723C32.5362 61.7494 32.7678 61.7879 32.9994 61.7879C33.2309 61.7879 33.4628 61.7494 33.6847 61.6723C58.7336 52.99 58.3414 32.4163 58.0545 17.3941Z"
              stroke="#1A1721"
              strokeWidth="1.5"
              strokeMiterlimit="10"
            />
            <path
              d="M36.7136 27.558V19.7098H29.2877V27.558H21.439V34.9839H29.2877V42.832H36.7136V34.9839H44.5612V27.558H36.7136Z"
              fill="white"
              stroke="#1A1721"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </svg>
    </Center>
  );
};
