import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const DisputeIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_502_1839)">
          <path
            d="M15 6C15 4.89543 15.8954 4 17 4H26C27.1046 4 28 4.89543 28 6V19L26.2121 16.0136C25.8378 15.3883 25.1529 15.0162 24.4246 15.0422L17.0715 15.3052C15.9396 15.3457 15 14.4391 15 13.3065V9.68965V6Z"
            fill="#66D8F2"
          />
          <path
            d="M27.9999 18.6667L23.9999 14.6667H14.6666C14.313 14.6667 13.9738 14.5262 13.7238 14.2761C13.4737 14.0261 13.3333 13.687 13.3333 13.3333V5.33333C13.3333 4.97971 13.4737 4.64057 13.7238 4.39052C13.9738 4.14048 14.313 4 14.6666 4H26.6666C27.0202 4 27.3593 4.14048 27.6094 4.39052C27.8594 4.64057 27.9999 4.97971 27.9999 5.33333V18.6667Z"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.6667 20V22.6667C18.6667 23.0203 18.5262 23.3595 18.2761 23.6095C18.0261 23.8596 17.687 24 17.3333 24H8L4 28V14.6667C4 14.3131 4.14048 13.9739 4.39052 13.7239C4.64057 13.4739 4.97971 13.3334 5.33333 13.3334H8"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_502_1839">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
