import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const GoogleDriveIcon = ({ imageAlt, style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-label={imageAlt}
      >
        <g clipPath="url(#clip0_131_878)">
          <path
            d="M12 10L6 20L3 15L9 5L12 10Z"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9 15H21L18 20H6"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15 15L9 5H15L21 15H15Z"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_131_878">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
