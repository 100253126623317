import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const SignatureIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3_2063)">
          <path
            d="M17.6672 7.84283C18.4583 6.65118 20.2083 6.65119 20.9995 7.84283L30.55 22.2271C31.4326 23.5564 30.4795 25.3333 28.8838 25.3333H9.78288C8.18719 25.3333 7.23406 23.5564 8.11669 22.2271L17.6672 7.84283Z"
            fill="#80E8B6"
          />
          <path
            d="M4 25.3333C8.444 22.6667 10.6667 20 10.6667 17.3333C10.6667 13.3333 9.33333 13.3333 8 13.3333C6.66667 13.3333 5.29067 14.78 5.33333 17.3333C5.37867 20.064 7.544 21.1693 8.66667 22.6667C10.6667 25.3333 12 26 13.3333 24C14.2227 22.6667 14.8893 21.556 15.3333 20.6667C16.6667 23.7773 18.444 25.3333 20.6667 25.3333H24"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.6667 22.6667V6.66667C26.6667 5.172 25.4947 4 24 4C22.5054 4 21.3334 5.172 21.3334 6.66667V22.6667L24 25.3333L26.6667 22.6667Z"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.3334 9.33334H26.6667"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_3_2063">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
