import { GeoDistribution } from '@zorro/clients';
import { FullAllowanceModel, calculateAllowance } from '~/lib/allowance';
import { EmployeeData, EmployeeScenario } from '~/types/sheet';

export function getEmployeeAffordableAllowance(
  employeeLcspPremium: number,
  annualIncome: number
) {
  const AFFORDABILITY_THRESHOLD = 0.0902;
  return Math.max(
    employeeLcspPremium - AFFORDABILITY_THRESHOLD * (annualIncome / 12),
    0
  );
}

export function getEmployeeAnnualIncome(employee: EmployeeData | undefined) {
  const { stateCode, wage } = employee || {};
  if (wage) {
    return wage;
  }

  if (stateCode === 'AK') {
    return 18_810;
  } else if (stateCode === 'HI') {
    return 17_310;
  }

  return 15_060;
}

export function isScenarioAffordableForEmployee(
  employeeLcspPremium: number,
  allowance: number,
  employee: EmployeeData
) {
  if (employee.isMedicareEligible) {
    return allowance >= 300;
  }
  const annualIncome = getEmployeeAnnualIncome(employee);
  const employeeAffordableAllowance = getEmployeeAffordableAllowance(
    employeeLcspPremium,
    annualIncome
  );

  return allowance >= employeeAffordableAllowance;
}

export function calculateScenarioAffordabilityPercentage(
  employeeData: Map<string, EmployeeData>,
  employeeScenarios: EmployeeScenario[] | undefined,
  fullAllowanceModel: FullAllowanceModel,
  geoDistribution: GeoDistribution
) {
  if (!employeeScenarios) {
    return 0;
  }

  let affordableEmployeeScenarioCount = 0;
  for (const { employeeId } of employeeScenarios) {
    const employee = employeeData.get(employeeId);
    if (employee) {
      const employeeOnlyAllowance = calculateAllowance(
        employee,
        fullAllowanceModel,
        geoDistribution,
        employeeId,
        true
      );

      if (
        employee.lowCostSilverPlanPremium &&
        isScenarioAffordableForEmployee(
          employee.lowCostSilverPlanPremium,
          employeeOnlyAllowance,
          employee
        )
      ) {
        affordableEmployeeScenarioCount++;
      }
    }
  }

  return affordableEmployeeScenarioCount / employeeScenarios.length;
}
