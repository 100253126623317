import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const FileLikeIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <g clipPath="url(#clip0_5676_19763)">
          <path
            d="M6.50552 5.92907C6.54371 4.85278 7.4273 4 8.50426 4H15C16.1046 4 17 4.89543 17 6V22.1945C17 22.4865 16.9361 22.775 16.8127 23.0397L15.0384 26.8451C14.71 27.5496 14.0031 28 13.2258 28L6 28C4.89543 28 4 27.1046 4 26V22.5833C4 21.985 4.48502 21.5 5.08333 21.5C5.68164 21.5 6.16667 21.015 6.16667 20.4166V15.4782L6.50552 5.92907Z"
            fill="#FFD880"
          />
          <path
            d="M4 22.6667C4 22.3131 4.14048 21.9739 4.39052 21.7239C4.64057 21.4738 4.97971 21.3334 5.33333 21.3334H6.66667C7.02029 21.3334 7.35943 21.4738 7.60948 21.7239C7.85952 21.9739 8 22.3131 8 22.6667V26.6667C8 27.0203 7.85952 27.3595 7.60948 27.6095C7.35943 27.8596 7.02029 28 6.66667 28H5.33333C4.97971 28 4.64057 27.8596 4.39052 27.6095C4.14048 27.3595 4 27.0203 4 26.6667V22.6667Z"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 26.6667C8 27.0203 8.14048 27.3595 8.39052 27.6095C8.64057 27.8596 8.97971 28.0001 9.33333 28.0001H14.3413C14.6282 28.0001 14.9074 27.9076 15.1375 27.7363C15.3676 27.5651 15.5363 27.3242 15.6187 27.0494L17.2187 23.0494C17.3387 22.6454 17.396 22.2094 17.144 21.8707C16.8933 21.5321 16.4213 21.3334 16 21.3334H13.3333V18.0441C13.3332 17.7294 13.2318 17.4231 13.0442 17.1705C12.8567 16.9178 12.5928 16.7322 12.2916 16.641C11.9905 16.5499 11.6679 16.558 11.3717 16.6642C11.0755 16.7703 10.8213 16.969 10.6467 17.2307L8 21.3334V26.6667Z"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.6666 4V9.33333C18.6666 9.68696 18.8071 10.0261 19.0572 10.2761C19.3072 10.5262 19.6463 10.6667 20 10.6667H25.3333"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.66663 16.1333V6.66667C6.66663 5.95942 6.94758 5.28115 7.44767 4.78105C7.94777 4.28095 8.62605 4 9.33329 4H18.6666L25.3333 10.6667V25.3333C25.3333 26.0406 25.0523 26.7189 24.5522 27.219C24.0521 27.719 23.3739 28 22.6666 28H19.6"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9 8H15"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M9 14L22 14"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_5676_19763">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
