import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const DocumentIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_502_1955)">
          <path
            d="M7 14C7 12.8954 7.89543 12 9 12H23C24.1046 12 25 12.8954 25 14V28H7V14Z"
            fill="#80E8B6"
          />
          <path
            d="M18.6667 4V9.33333C18.6667 9.68695 18.8072 10.0261 19.0573 10.2761C19.3073 10.5262 19.6465 10.6667 20.0001 10.6667H25.3334"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.6667 28H9.33341C8.62617 28 7.94789 27.719 7.4478 27.219C6.9477 26.7189 6.66675 26.0406 6.66675 25.3333V6.66667C6.66675 5.95942 6.9477 5.28115 7.4478 4.78105C7.94789 4.28095 8.62617 4 9.33341 4H18.6667L25.3334 10.6667V25.3333C25.3334 26.0406 25.0525 26.7189 24.5524 27.219C24.0523 27.719 23.374 28 22.6667 28Z"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 22.6667V14.6667"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.6667 19.3333L16.0001 22.6666L19.3334 19.3333"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_502_1955">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
