import { EmployeeDto, OnboardingPeriodDto } from '@zorro/clients';
import {
  DateUtilInstance,
  formatDateISO,
  getDateUtil,
  getNow,
  parseDateISO,
} from '@zorro/shared/formatters';
import { Dayjs } from 'dayjs';

export const oldestQleDate = (): Dayjs => getNow().subtract(45, 'day');
export const newestQleDate = (): Dayjs => getNow().add(60, 'day');

type Props = {
  eligibleFrom?: EmployeeDto['eligibleFrom'];
  eligibleUntil?: EmployeeDto['eligibleUntil'];
  enrollmentEffectiveUntil?: OnboardingPeriodDto['enrollmentEffectiveUntil'];
  enrollmentEffectiveFrom?: OnboardingPeriodDto['enrollmentEffectiveFrom'];
};

export const getQualifyingLifeEventsDateRange = ({
  eligibleFrom,
  eligibleUntil,
  enrollmentEffectiveUntil,
  enrollmentEffectiveFrom,
}: Props = {}): { minDate: string; maxDate: string } => {
  let minDate: DateUtilInstance | null;
  if (enrollmentEffectiveFrom && eligibleFrom) {
    minDate = getDateUtil().max(
      oldestQleDate(),
      parseDateISO(enrollmentEffectiveFrom),
      parseDateISO(eligibleFrom)
    );
  } else if (eligibleFrom) {
    minDate = getDateUtil().max(oldestQleDate(), parseDateISO(eligibleFrom));
  } else if (enrollmentEffectiveFrom) {
    minDate = getDateUtil().max(
      oldestQleDate(),
      parseDateISO(enrollmentEffectiveFrom)
    );
  } else {
    minDate = oldestQleDate();
  }

  let maxDate: DateUtilInstance | null;
  if (enrollmentEffectiveUntil && eligibleUntil) {
    maxDate = getDateUtil().min(
      newestQleDate(),
      parseDateISO(enrollmentEffectiveUntil),
      parseDateISO(eligibleUntil)
    );
  } else if (eligibleUntil) {
    maxDate = getDateUtil().min(newestQleDate(), parseDateISO(eligibleUntil));
  } else if (enrollmentEffectiveUntil) {
    maxDate = getDateUtil().min(
      newestQleDate(),
      parseDateISO(enrollmentEffectiveUntil)
    );
  } else {
    maxDate = newestQleDate();
  }

  // ⚠️ TODO: handle the case when minMax fails and returns null

  return {
    minDate: minDate ? formatDateISO(minDate) : '',
    maxDate: maxDate ? formatDateISO(maxDate) : '',
  };
};
