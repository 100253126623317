import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const ZorroSmallIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_1_7938"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1_7938)">
          <path
            d="M18.9977 9.01678V7.24225C18.9977 6.98346 18.788 6.7732 18.5299 6.7732H12.976C12.681 6.7732 12.4413 6.5329 12.4413 6.23714V3.20102C12.4413 3.09011 12.3514 3 12.2408 3H8.44066C7.11095 3 6.03243 4.08136 6.03243 5.41457V7.23763C6.03243 7.34853 6.1223 7.43865 6.23292 7.43865H11.6324C12.121 7.43865 12.3537 8.04171 11.9919 8.37213L5.86881 13.943C5.31572 14.4467 5 15.1583 5 15.907V17.7185C5 17.9773 5.20971 18.1875 5.46782 18.1875H11.0563C11.3513 18.1875 11.5909 18.4278 11.5909 18.7236V21.799C11.5909 21.9099 11.6808 22 11.7914 22H15.6884C16.9812 22 18.0275 20.9487 18.0275 19.6547V17.7647C18.0275 17.6538 17.9376 17.5637 17.827 17.5637H12.1095C11.6808 17.5637 11.478 17.0345 11.796 16.748L18.1312 10.9854C18.6843 10.4817 19 9.77003 19 9.0214L18.9977 9.01678Z"
            fill="#1A1721"
          />
        </g>
      </svg>
    </Center>
  );
};
