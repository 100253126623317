import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const TrashIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3242_10139)">
          <path
            d="M31.5152 12.1212H8.48486L9.69698 32.1212L11.5152 34.5455L27.2727 35.1515L29.0909 33.3333L31.5152 12.1212Z"
            fill="#FF8972"
            stroke="#1A1721"
          />
          <path
            d="M6.66675 11.6666H33.3334"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.6667 18.3334V28.3334"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23.3333 18.3334V28.3334"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.33325 11.6666L9.99992 31.6666C9.99992 32.5507 10.3511 33.3985 10.9762 34.0236C11.6014 34.6488 12.4492 35 13.3333 35H26.6666C27.5506 35 28.3985 34.6488 29.0236 34.0236C29.6487 33.3985 29.9999 32.5507 29.9999 31.6666L31.6666 11.6666"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15 11.6667V6.66667C15 6.22464 15.1756 5.80072 15.4882 5.48816C15.8007 5.17559 16.2246 5 16.6667 5H23.3333C23.7754 5 24.1993 5.17559 24.5118 5.48816C24.8244 5.80072 25 6.22464 25 6.66667V11.6667"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_3242_10139">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
