import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export function HandWithFiveHearts({ style }: SVGIconProps) {
  return (
    <Center style={style}>
      <svg
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_343_641"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="80"
          height="80"
        >
          <path d="M0 0H80V80H0V0Z" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_343_641)">
          <path
            d="M53.2448 61.7047L72.3342 55.1421C74.3826 54.403 76.8087 55.1012 77.8433 57.0153C79.2483 59.6136 78.0185 62.4792 75.6326 63.4344L46.4375 76.2891C44.0498 77.3749 41.4576 77.937 38.8356 77.937C35.5079 77.937 32.2419 77.0325 29.3891 75.3198L21.2171 70.4168C19.3311 69.2859 16.2543 68.6511 14.0625 68.5717C14.0625 68.5687 14.0625 46.6875 14.0625 46.6875H26.4325C28.0439 46.6875 29.6265 47.1013 31.0297 47.8917L39.286 52.5359C39.7547 52.7996 40.2809 52.9375 40.8186 52.9375H50.937C53.5262 52.9375 55.6246 55.0359 55.6246 57.625C55.6246 59.3749 54.6669 60.8996 53.2448 61.7047Z"
            fill="#FFC39C"
          />
          <path
            d="M14.0623 46.6875V68.5718C14.0574 70.2936 12.6609 71.687 10.9373 71.687H1.56226V43.5625H10.9373C12.664 43.5625 14.0623 44.9608 14.0623 46.6875Z"
            fill="#8033EF"
          />
          <path
            d="M71.8155 53.6603L57.0852 58.7244C57.1505 58.3619 57.1871 57.992 57.1871 57.6166C57.1871 54.1706 54.3838 51.3666 50.9371 51.3666H40.8187C40.5501 51.3666 40.2852 51.2976 40.0514 51.1658L31.7964 46.5228C30.1643 45.603 28.3094 45.1166 26.4326 45.1166H15.3559C14.7114 43.2983 12.9743 41.9922 10.9375 41.9922H1.56251C0.699466 41.9922 0 42.6917 0 43.5547V71.6792C0 72.5422 0.699466 73.2417 1.56251 73.2417H10.9375C12.9279 73.2417 14.6345 71.9911 15.312 70.2345C17.0808 70.4542 19.1273 70.9773 20.4133 71.7488L28.5847 76.6518C31.6804 78.5097 35.2248 79.4917 38.8356 79.4917C41.697 79.4917 44.4692 78.8911 47.076 77.7071L76.2393 64.8658C79.4693 63.5554 81.0642 59.6803 79.2179 56.2647C77.8598 53.7513 74.6793 52.6331 71.8155 53.6603ZM12.5001 68.5615C12.4964 69.4197 11.7963 70.1167 10.9375 70.1167H3.12501V45.1172H10.9375C11.7988 45.1172 12.5001 45.8179 12.5001 46.6797V68.5615ZM75.0522 61.9758C75.0357 61.9819 75.0192 61.9892 75.0028 61.9959C75.0028 61.9959 45.7961 74.8561 45.7906 74.8585C43.5903 75.8595 41.2502 76.3667 38.8356 76.3667C35.7912 76.3667 32.8029 75.5391 30.193 73.9723L22.021 69.0693C20.2717 68.0201 17.7668 67.3805 15.6251 67.1241V48.2422H26.4326C27.7729 48.2422 29.0974 48.5889 30.2638 49.2462L38.5188 53.8892C39.2183 54.2835 40.0142 54.4922 40.8187 54.4922H50.9371C52.6601 54.4922 54.0621 55.8936 54.0621 57.6173C54.0621 59.3342 52.6595 60.7423 50.9371 60.7423H35.0532C34.1902 60.7423 33.4907 61.4417 33.4907 62.3048C33.4907 63.1672 34.1902 63.8667 35.0532 63.8667H50.9371C51.9619 63.8667 52.9708 63.6122 53.8662 63.1349C53.8662 63.1349 72.8574 56.6065 72.8647 56.6041C74.2648 56.0987 75.8481 56.6016 76.4688 57.7503C77.3996 59.4721 76.63 61.3441 75.0522 61.9758Z"
            fill="#1A1721"
          />
          <path
            d="M44.3383 12.6573L36.0093 21.0078L27.6802 12.6573M27.6802 12.6573C27.1309 12.1161 26.6981 11.4656 26.4093 10.7468C26.1204 10.0281 25.9817 9.25651 26.0019 8.48079C26.0221 7.70508 26.2007 6.94199 26.5266 6.2396C26.8524 5.5372 27.3184 4.9107 27.8951 4.39956C28.4719 3.88841 29.1469 3.50369 29.8778 3.26963C30.6086 3.03556 31.3794 2.95721 32.1417 3.03952C32.9039 3.12184 33.641 3.36302 34.3067 3.74789C34.9723 4.13276 35.552 4.65297 36.0093 5.27578C36.4685 4.65749 37.0489 4.14182 37.714 3.76104C38.3792 3.38025 39.1149 3.14256 39.875 3.06282C40.635 2.98308 41.4032 3.06302 42.1314 3.29763C42.8596 3.53224 43.5321 3.91648 44.1068 4.42629C44.6815 4.93611 45.1461 5.56052 45.4715 6.26046C45.7969 6.96039 45.976 7.72078 45.9978 8.49403C46.0195 9.26728 45.8833 10.0368 45.5978 10.7543C45.3122 11.4718 44.8834 12.1219 44.3383 12.664"
            fill="#CDABFF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.0075 4.27449C31.419 4.21094 30.8238 4.27138 30.259 4.45225C29.6943 4.63313 29.1716 4.93075 28.7242 5.32724C28.2768 5.72376 27.9143 6.21065 27.6605 6.75779C27.4067 7.30497 27.2673 7.90006 27.2515 8.5055C27.2357 9.11093 27.344 9.71277 27.5691 10.2729C27.7942 10.8331 28.131 11.3389 28.5574 11.7589L28.5653 11.7667L28.5653 11.7667L36.0093 19.2299L43.4532 11.7667C43.4773 11.7426 43.502 11.7197 43.5274 11.698C43.9173 11.292 44.2263 10.812 44.4364 10.2843C44.6589 9.72507 44.7652 9.12484 44.7482 8.52133C44.7313 7.91782 44.5914 7.32483 44.338 6.77958C44.0845 6.23436 43.7231 5.74909 43.2773 5.3536C42.8315 4.95814 42.3108 4.66089 41.748 4.47959C41.1854 4.2983 40.5922 4.23662 40.0054 4.29818C39.4185 4.35974 38.8499 4.54331 38.3351 4.83805C37.8201 5.13281 37.3698 5.53264 37.0127 6.01332C36.7762 6.33179 36.4025 6.51906 36.0058 6.51796C35.6091 6.51686 35.2364 6.32752 35.0017 6.00774C34.6461 5.52352 34.1963 5.12014 33.681 4.82221C33.1657 4.5243 32.5959 4.33804 32.0075 4.27449ZM45.1424 13.6133L36.8943 21.8827C36.6598 22.1179 36.3413 22.25 36.0093 22.25C35.6772 22.25 35.3587 22.1179 35.1242 21.8827L26.7989 13.5359C26.1286 12.8744 25.6013 12.0808 25.2494 11.2051C24.8968 10.3277 24.7277 9.38646 24.7523 8.44047C24.777 7.49447 24.9948 6.5634 25.3926 5.70578C25.7905 4.84812 26.3599 4.08202 27.066 3.45625C27.7722 2.83045 28.5996 2.35863 29.4965 2.07137C30.3935 1.78411 31.3399 1.68786 32.2759 1.78894C33.2119 1.89001 34.1163 2.18612 34.9324 2.65794C35.3184 2.88116 35.6806 3.14137 36.0144 3.43424C36.3475 3.14517 36.7086 2.88848 37.093 2.66841C37.9085 2.20158 38.8112 1.90974 39.7445 1.81183C40.6779 1.71391 41.6211 1.81211 42.5147 2.10005C43.4084 2.38797 44.2326 2.85919 44.9363 3.48336C45.6399 4.1075 46.2077 4.87106 46.605 5.72571C47.0023 6.58033 47.2208 7.50811 47.2473 8.45111C47.2738 9.3941 47.1077 10.3328 46.7592 11.2087C46.4106 12.0845 45.8868 12.8793 45.2196 13.5426C45.1946 13.5675 45.1688 13.591 45.1424 13.6133Z"
            fill="#1A1721"
          />
          <path
            d="M49.5883 38.9073L41.2593 47.2578L32.9302 38.9073M32.9302 38.9073C32.3809 38.3661 31.9481 37.7156 31.6593 36.9968C31.3704 36.2781 31.2317 35.5065 31.2519 34.7308C31.2721 33.9551 31.4507 33.192 31.7766 32.4896C32.1024 31.7872 32.5684 31.1607 33.1451 30.6496C33.7219 30.1384 34.3969 29.7537 35.1278 29.5196C35.8586 29.2856 36.6294 29.2072 37.3917 29.2895C38.1539 29.3718 38.891 29.613 39.5567 29.9979C40.2223 30.3828 40.802 30.903 41.2593 31.5258C41.7185 30.9075 42.2989 30.3918 42.964 30.011C43.6292 29.6303 44.3649 29.3926 45.125 29.3128C45.885 29.2331 46.6532 29.313 47.3814 29.5476C48.1096 29.7822 48.7821 30.1665 49.3568 30.6763C49.9315 31.1861 50.3961 31.8105 50.7215 32.5105C51.0469 33.2104 51.226 33.9708 51.2478 34.744C51.2695 35.5173 51.1333 36.2868 50.8478 37.0043C50.5622 37.7218 50.1334 38.3719 49.5883 38.914"
            fill="#CDABFF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.2575 30.5245C36.669 30.4609 36.0738 30.5214 35.509 30.7023C34.9443 30.8831 34.4216 31.1807 33.9742 31.5772C33.5268 31.9738 33.1643 32.4606 32.9105 33.0078C32.6567 33.555 32.5173 34.1501 32.5015 34.7555C32.4857 35.3609 32.594 35.9628 32.8191 36.5229C33.0442 37.0831 33.381 37.5889 33.8074 38.0089L33.8153 38.0167L33.8153 38.0167L41.2593 45.4799L48.7032 38.0167C48.7273 37.9926 48.752 37.9697 48.7774 37.948C49.1673 37.542 49.4763 37.062 49.6864 36.5343C49.9089 35.9751 50.0152 35.3748 49.9982 34.7713C49.9813 34.1678 49.8414 33.5748 49.588 33.0296C49.3345 32.4844 48.9731 31.9991 48.5273 31.6036C48.0815 31.2081 47.5608 30.9109 46.998 30.7296C46.4354 30.5483 45.8422 30.4866 45.2554 30.5482C44.6685 30.6097 44.0999 30.7933 43.5851 31.088C43.0701 31.3828 42.6198 31.7826 42.2627 32.2633C42.0262 32.5818 41.6525 32.7691 41.2558 32.768C40.8591 32.7669 40.4864 32.5775 40.2517 32.2577C39.8961 31.7735 39.4463 31.3701 38.931 31.0722C38.4157 30.7743 37.8459 30.588 37.2575 30.5245ZM50.3924 39.8633L42.1443 48.1327C41.9098 48.3679 41.5913 48.5 41.2593 48.5C40.9272 48.5 40.6087 48.3679 40.3742 48.1327L32.0489 39.7859C31.3786 39.1244 30.8513 38.3308 30.4994 37.4551C30.1468 36.5777 29.9777 35.6365 30.0023 34.6905C30.027 33.7445 30.2448 32.8134 30.6426 31.9558C31.0405 31.0981 31.6099 30.332 32.316 29.7063C33.0222 29.0804 33.8496 28.6086 34.7465 28.3214C35.6435 28.0341 36.5899 27.9379 37.5259 28.0389C38.4619 28.14 39.3663 28.4361 40.1824 28.9079C40.5684 29.1312 40.9306 29.3914 41.2644 29.6842C41.5975 29.3952 41.9586 29.1385 42.343 28.9184C43.1585 28.4516 44.0612 28.1597 44.9945 28.0618C45.9279 27.9639 46.8711 28.0621 47.7647 28.35C48.6584 28.638 49.4826 29.1092 50.1863 29.7334C50.8899 30.3575 51.4577 31.1211 51.855 31.9757C52.2523 32.8303 52.4708 33.7581 52.4973 34.7011C52.5238 35.6441 52.3577 36.5828 52.0092 37.4587C51.6606 38.3345 51.1368 39.1293 50.4696 39.7926C50.4446 39.8175 50.4188 39.841 50.3924 39.8633Z"
            fill="#1A1721"
          />
          <path
            d="M76.5883 40.9073L68.2593 49.2578L59.9302 40.9073M59.9302 40.9073C59.3809 40.3661 58.9481 39.7156 58.6593 38.9968C58.3704 38.2781 58.2317 37.5065 58.2519 36.7308C58.2721 35.9551 58.4507 35.192 58.7766 34.4896C59.1024 33.7872 59.5684 33.1607 60.1451 32.6496C60.7219 32.1384 61.3969 31.7537 62.1278 31.5196C62.8586 31.2856 63.6294 31.2072 64.3917 31.2895C65.1539 31.3718 65.891 31.613 66.5567 31.9979C67.2223 32.3828 67.802 32.903 68.2593 33.5258C68.7185 32.9075 69.2989 32.3918 69.964 32.011C70.6292 31.6303 71.3649 31.3926 72.125 31.3128C72.885 31.2331 73.6532 31.313 74.3814 31.5476C75.1096 31.7822 75.7821 32.1665 76.3568 32.6763C76.9315 33.1861 77.3961 33.8105 77.7215 34.5105C78.0469 35.2104 78.226 35.9708 78.2478 36.744C78.2695 37.5173 78.1333 38.2868 77.8478 39.0043C77.5622 39.7218 77.1334 40.3719 76.5883 40.914"
            fill="#CDABFF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M64.2575 32.5245C63.669 32.4609 63.0738 32.5214 62.509 32.7023C61.9443 32.8831 61.4216 33.1807 60.9742 33.5772C60.5268 33.9738 60.1643 34.4606 59.9105 35.0078C59.6567 35.555 59.5173 36.1501 59.5015 36.7555C59.4857 37.3609 59.594 37.9628 59.8191 38.5229C60.0442 39.0831 60.381 39.5889 60.8074 40.0089L60.8153 40.0167L60.8153 40.0167L68.2593 47.4799L75.7032 40.0167C75.7273 39.9926 75.752 39.9697 75.7774 39.948C76.1673 39.542 76.4763 39.062 76.6864 38.5343C76.9089 37.9751 77.0152 37.3748 76.9982 36.7713C76.9813 36.1678 76.8414 35.5748 76.588 35.0296C76.3345 34.4844 75.9731 33.9991 75.5273 33.6036C75.0815 33.2081 74.5608 32.9109 73.998 32.7296C73.4354 32.5483 72.8422 32.4866 72.2554 32.5482C71.6685 32.6097 71.0999 32.7933 70.5851 33.088C70.0701 33.3828 69.6198 33.7826 69.2627 34.2633C69.0262 34.5818 68.6525 34.7691 68.2558 34.768C67.8591 34.7669 67.4864 34.5775 67.2517 34.2577C66.8961 33.7735 66.4463 33.3701 65.931 33.0722C65.4157 32.7743 64.8459 32.588 64.2575 32.5245ZM77.3924 41.8633L69.1443 50.1327C68.9098 50.3679 68.5913 50.5 68.2593 50.5C67.9272 50.5 67.6087 50.3679 67.3742 50.1327L59.0489 41.7859C58.3786 41.1244 57.8513 40.3308 57.4994 39.4551C57.1468 38.5777 56.9777 37.6365 57.0023 36.6905C57.027 35.7445 57.2448 34.8134 57.6426 33.9558C58.0405 33.0981 58.6099 32.332 59.316 31.7063C60.0222 31.0804 60.8496 30.6086 61.7465 30.3214C62.6435 30.0341 63.5899 29.9379 64.5259 30.0389C65.4619 30.14 66.3663 30.4361 67.1824 30.9079C67.5684 31.1312 67.9306 31.3914 68.2644 31.6842C68.5975 31.3952 68.9586 31.1385 69.343 30.9184C70.1585 30.4516 71.0612 30.1597 71.9945 30.0618C72.9279 29.9639 73.8711 30.0621 74.7647 30.35C75.6584 30.638 76.4826 31.1092 77.1863 31.7334C77.8899 32.3575 78.4577 33.1211 78.855 33.9757C79.2523 34.8303 79.4708 35.7581 79.4973 36.7011C79.5238 37.6441 79.3577 38.5828 79.0092 39.4587C78.6606 40.3345 78.1368 41.1293 77.4696 41.7926C77.4446 41.8175 77.4188 41.841 77.3924 41.8633Z"
            fill="#1A1721"
          />
          <path
            d="M76.3383 40.6573L68.0093 49.0078L59.6802 40.6573M59.6802 40.6573C59.1309 40.1161 58.6981 39.4656 58.4093 38.7468C58.1204 38.0281 57.9817 37.2565 58.0019 36.4808C58.0221 35.7051 58.2007 34.942 58.5266 34.2396C58.8524 33.5372 59.3184 32.9107 59.8951 32.3996C60.4719 31.8884 61.1469 31.5037 61.8778 31.2696C62.6086 31.0356 63.3794 30.9572 64.1417 31.0395C64.9039 31.1218 65.641 31.363 66.3067 31.7479C66.9723 32.1328 67.552 32.653 68.0093 33.2758C68.4685 32.6575 69.0489 32.1418 69.714 31.761C70.3792 31.3803 71.1149 31.1426 71.875 31.0628C72.635 30.9831 73.4032 31.063 74.1314 31.2976C74.8596 31.5322 75.5321 31.9165 76.1068 32.4263C76.6815 32.9361 77.1461 33.5605 77.4715 34.2605C77.7969 34.9604 77.976 35.7208 77.9978 36.494C78.0195 37.2673 77.8833 38.0368 77.5978 38.7543C77.3122 39.4718 76.8834 40.1219 76.3383 40.664"
            fill="#CDABFF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M64.0075 32.2745C63.419 32.2109 62.8238 32.2714 62.259 32.4523C61.6943 32.6331 61.1716 32.9307 60.7242 33.3272C60.2768 33.7238 59.9143 34.2106 59.6605 34.7578C59.4067 35.305 59.2673 35.9001 59.2515 36.5055C59.2357 37.1109 59.344 37.7128 59.5691 38.2729C59.7942 38.8331 60.131 39.3389 60.5574 39.7589L60.5653 39.7667L60.5653 39.7667L68.0093 47.2299L75.4532 39.7667C75.4773 39.7426 75.502 39.7197 75.5274 39.698C75.9173 39.292 76.2263 38.812 76.4364 38.2843C76.6589 37.7251 76.7652 37.1248 76.7482 36.5213C76.7313 35.9178 76.5914 35.3248 76.338 34.7796C76.0845 34.2344 75.7231 33.7491 75.2773 33.3536C74.8315 32.9581 74.3108 32.6609 73.748 32.4796C73.1854 32.2983 72.5922 32.2366 72.0054 32.2982C71.4185 32.3597 70.8499 32.5433 70.3351 32.838C69.8201 33.1328 69.3698 33.5326 69.0127 34.0133C68.7762 34.3318 68.4025 34.5191 68.0058 34.518C67.6091 34.5169 67.2364 34.3275 67.0017 34.0077C66.6461 33.5235 66.1963 33.1201 65.681 32.8222C65.1657 32.5243 64.5959 32.338 64.0075 32.2745ZM77.1424 41.6133L68.8943 49.8827C68.6598 50.1179 68.3413 50.25 68.0093 50.25C67.6772 50.25 67.3587 50.1179 67.1242 49.8827L58.7989 41.5359C58.1286 40.8744 57.6013 40.0808 57.2494 39.2051C56.8968 38.3277 56.7277 37.3865 56.7523 36.4405C56.777 35.4945 56.9948 34.5634 57.3926 33.7058C57.7905 32.8481 58.3599 32.082 59.066 31.4563C59.7722 30.8304 60.5996 30.3586 61.4965 30.0714C62.3935 29.7841 63.3399 29.6879 64.2759 29.7889C65.2119 29.89 66.1163 30.1861 66.9324 30.6579C67.3184 30.8812 67.6806 31.1414 68.0144 31.4342C68.3475 31.1452 68.7086 30.8885 69.093 30.6684C69.9085 30.2016 70.8112 29.9097 71.7445 29.8118C72.6779 29.7139 73.6211 29.8121 74.5147 30.1C75.4084 30.388 76.2326 30.8592 76.9363 31.4834C77.6399 32.1075 78.2077 32.8711 78.605 33.7257C79.0023 34.5803 79.2208 35.5081 79.2473 36.4511C79.2738 37.3941 79.1077 38.3328 78.7592 39.2087C78.4106 40.0845 77.8868 40.8793 77.2196 41.5426C77.1946 41.5675 77.1688 41.591 77.1424 41.6133Z"
            fill="#1A1721"
          />
          <path
            d="M72.5883 14.9073L64.2593 23.2578L55.9302 14.9073M55.9302 14.9073C55.3809 14.3661 54.9481 13.7156 54.6593 12.9968C54.3704 12.2781 54.2317 11.5065 54.2519 10.7308C54.2721 9.95508 54.4507 9.19199 54.7766 8.4896C55.1024 7.7872 55.5684 7.1607 56.1451 6.64956C56.7219 6.13841 57.3969 5.75369 58.1278 5.51963C58.8586 5.28556 59.6294 5.20721 60.3917 5.28952C61.1539 5.37184 61.891 5.61302 62.5567 5.99789C63.2223 6.38276 63.802 6.90297 64.2593 7.52578C64.7185 6.90749 65.2989 6.39182 65.964 6.01104C66.6292 5.63025 67.3649 5.39256 68.125 5.31282C68.885 5.23308 69.6532 5.31302 70.3814 5.54763C71.1096 5.78224 71.7821 6.16648 72.3568 6.67629C72.9315 7.18611 73.3961 7.81052 73.7215 8.51046C74.0469 9.21039 74.226 9.97078 74.2478 10.744C74.2695 11.5173 74.1333 12.2868 73.8478 13.0043C73.5622 13.7218 73.1334 14.3719 72.5883 14.914"
            fill="#CDABFF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60.2575 6.52449C59.669 6.46094 59.0738 6.52138 58.509 6.70225C57.9443 6.88313 57.4216 7.18075 56.9742 7.57724C56.5268 7.97376 56.1643 8.46065 55.9105 9.00779C55.6567 9.55497 55.5173 10.1501 55.5015 10.7555C55.4857 11.3609 55.594 11.9628 55.8191 12.5229C56.0442 13.0831 56.381 13.5889 56.8074 14.0089L56.8153 14.0167L56.8153 14.0167L64.2593 21.4799L71.7032 14.0167C71.7273 13.9926 71.752 13.9697 71.7774 13.948C72.1673 13.542 72.4763 13.062 72.6864 12.5343C72.9089 11.9751 73.0152 11.3748 72.9982 10.7713C72.9813 10.1678 72.8414 9.57483 72.588 9.02958C72.3345 8.48436 71.9731 7.99909 71.5273 7.6036C71.0815 7.20814 70.5608 6.91089 69.998 6.72959C69.4354 6.5483 68.8422 6.48662 68.2554 6.54818C67.6685 6.60974 67.0999 6.79331 66.5851 7.08805C66.0701 7.38281 65.6198 7.78264 65.2627 8.26332C65.0262 8.58179 64.6525 8.76906 64.2558 8.76796C63.8591 8.76686 63.4864 8.57752 63.2517 8.25774C62.8961 7.77352 62.4463 7.37014 61.931 7.07221C61.4157 6.7743 60.8459 6.58804 60.2575 6.52449ZM73.3924 15.8633L65.1443 24.1327C64.9098 24.3679 64.5913 24.5 64.2593 24.5C63.9272 24.5 63.6087 24.3679 63.3742 24.1327L55.0489 15.7859C54.3786 15.1244 53.8513 14.3308 53.4994 13.4551C53.1468 12.5777 52.9777 11.6365 53.0023 10.6905C53.027 9.74447 53.2448 8.8134 53.6426 7.95578C54.0405 7.09812 54.6099 6.33202 55.316 5.70625C56.0222 5.08045 56.8496 4.60863 57.7465 4.32137C58.6435 4.03411 59.5899 3.93786 60.5259 4.03894C61.4619 4.14001 62.3663 4.43612 63.1824 4.90794C63.5684 5.13116 63.9306 5.39137 64.2644 5.68424C64.5975 5.39517 64.9586 5.13848 65.343 4.91841C66.1585 4.45158 67.0612 4.15974 67.9945 4.06183C68.9279 3.96391 69.8711 4.06211 70.7647 4.35005C71.6584 4.63797 72.4826 5.10919 73.1863 5.73336C73.8899 6.3575 74.4577 7.12106 74.855 7.97571C75.2523 8.83033 75.4708 9.75811 75.4973 10.7011C75.5238 11.6441 75.3577 12.5828 75.0092 13.4587C74.6606 14.3345 74.1368 15.1293 73.4696 15.7926C73.4446 15.8175 73.4188 15.841 73.3924 15.8633Z"
            fill="#1A1721"
          />
          <path
            d="M22.3383 25.6573L14.0093 34.0078L5.68024 25.6573M5.68024 25.6573C5.13086 25.1161 4.69813 24.4656 4.40928 23.7468C4.12044 23.0281 3.98174 22.2565 4.00193 21.4808C4.02211 20.7051 4.20074 19.942 4.52656 19.2396C4.85239 18.5372 5.31835 17.9107 5.89511 17.3996C6.47187 16.8884 7.14693 16.5037 7.87778 16.2696C8.60863 16.0356 9.37944 15.9572 10.1417 16.0395C10.9039 16.1218 11.641 16.363 12.3067 16.7479C12.9723 17.1328 13.552 17.653 14.0093 18.2758C14.4685 17.6575 15.0489 17.1418 15.714 16.761C16.3792 16.3803 17.1149 16.1426 17.875 16.0628C18.635 15.9831 19.4032 16.063 20.1314 16.2976C20.8596 16.5322 21.5321 16.9165 22.1068 17.4263C22.6815 17.9361 23.1461 18.5605 23.4715 19.2605C23.7969 19.9604 23.976 20.7208 23.9978 21.494C24.0195 22.2673 23.8833 23.0368 23.5978 23.7543C23.3122 24.4718 22.8834 25.1219 22.3383 25.664"
            fill="#CDABFF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.0075 17.2745C9.419 17.2109 8.82377 17.2714 8.25903 17.4523C7.69426 17.6331 7.17156 17.9307 6.72418 18.3272C6.27676 18.7238 5.91431 19.2106 5.6605 19.7578C5.40668 20.305 5.26726 20.9001 5.2515 21.5055C5.23575 22.1109 5.34403 22.7128 5.56914 23.2729C5.79423 23.8331 6.13102 24.3389 6.55745 24.7589L6.56529 24.7667L6.56526 24.7667L14.0093 32.2299L21.4532 24.7667C21.4773 24.7426 21.502 24.7197 21.5274 24.698C21.9173 24.292 22.2263 23.812 22.4364 23.2843C22.6589 22.7251 22.7652 22.1248 22.7482 21.5213C22.7313 20.9178 22.5914 20.3248 22.338 19.7796C22.0845 19.2344 21.7231 18.7491 21.2773 18.3536C20.8315 17.9581 20.3108 17.6609 19.748 17.4796C19.1854 17.2983 18.5922 17.2366 18.0054 17.2982C17.4185 17.3597 16.8499 17.5433 16.3351 17.838C15.8201 18.1328 15.3698 18.5326 15.0127 19.0133C14.7762 19.3318 14.4025 19.5191 14.0058 19.518C13.6091 19.5169 13.2364 19.3275 13.0017 19.0077C12.6461 18.5235 12.1963 18.1201 11.681 17.8222C11.1657 17.5243 10.5959 17.338 10.0075 17.2745ZM23.1424 26.6133L14.8943 34.8827C14.6598 35.1179 14.3413 35.25 14.0093 35.25C13.6772 35.25 13.3587 35.1179 13.1242 34.8827L4.79892 26.5359C4.12855 25.8744 3.60131 25.0808 3.24943 24.2051C2.89684 23.3277 2.72773 22.3865 2.75235 21.4405C2.77696 20.4945 2.9948 19.5634 3.39263 18.7058C3.79047 17.8481 4.35995 17.082 5.06604 16.4563C5.77218 15.8304 6.59959 15.3586 7.49652 15.0714C8.39348 14.7841 9.33987 14.6879 10.2759 14.7889C11.2119 14.89 12.1163 15.1861 12.9324 15.6579C13.3184 15.8812 13.6806 16.1414 14.0144 16.4342C14.3475 16.1452 14.7086 15.8885 15.093 15.6684C15.9085 15.2016 16.8112 14.9097 17.7445 14.8118C18.6779 14.7139 19.6211 14.8121 20.5147 15.1C21.4084 15.388 22.2326 15.8592 22.9363 16.4834C23.6399 17.1075 24.2077 17.8711 24.605 18.7257C25.0023 19.5803 25.2208 20.5081 25.2473 21.4511C25.2738 22.3941 25.1077 23.3328 24.7592 24.2087C24.4106 25.0845 23.8868 25.8793 23.2196 26.5426C23.1946 26.5675 23.1688 26.591 23.1424 26.6133Z"
            fill="#1A1721"
          />
        </g>
      </svg>
    </Center>
  );
}
