import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export function HandWithOneHeart({ style }: SVGIconProps) {
  return (
    <Center style={style}>
      <svg
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_343_637"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="80"
          height="80"
        >
          <path d="M0 0H80V80H0V0Z" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_343_637)">
          <path
            d="M53.2448 61.7047L72.3342 55.1421C74.3826 54.403 76.8087 55.1012 77.8433 57.0153C79.2483 59.6136 78.0185 62.4792 75.6326 63.4344L46.4375 76.2891C44.0498 77.3749 41.4576 77.937 38.8356 77.937C35.5079 77.937 32.2419 77.0325 29.3891 75.3198L21.2171 70.4168C19.3311 69.2859 16.2543 68.6511 14.0625 68.5717C14.0625 68.5687 14.0625 46.6875 14.0625 46.6875H26.4325C28.0439 46.6875 29.6265 47.1013 31.0297 47.8917L39.286 52.5359C39.7547 52.7996 40.2809 52.9375 40.8186 52.9375H50.937C53.5262 52.9375 55.6246 55.0359 55.6246 57.625C55.6246 59.3749 54.6669 60.8996 53.2448 61.7047Z"
            fill="#FFC39C"
          />
          <path
            d="M14.0626 46.6875V68.5718C14.0577 70.2936 12.6612 71.687 10.9375 71.687H1.5625V43.5625H10.9375C12.6642 43.5625 14.0626 44.9608 14.0626 46.6875Z"
            fill="#8033EF"
          />
          <path
            d="M71.8155 53.6681L57.0852 58.7323C57.1505 58.3697 57.1871 57.9998 57.1871 57.6245C57.1871 54.1784 54.3838 51.3744 50.9371 51.3744H40.8187C40.5501 51.3744 40.2852 51.3055 40.0514 51.1736L31.7964 46.5307C30.1643 45.6109 28.3094 45.1244 26.4326 45.1244H15.3559C14.7114 43.3062 12.9743 42 10.9375 42H1.56251C0.699466 42 0 42.6995 0 43.5625V71.687C0 72.5501 0.699466 73.2495 1.56251 73.2495H10.9375C12.9279 73.2495 14.6345 71.9989 15.312 70.2423C17.0808 70.462 19.1273 70.9851 20.4133 71.7566L28.5847 76.6596C31.6804 78.5175 35.2248 79.4995 38.8356 79.4995C41.697 79.4995 44.4692 78.899 47.076 77.7149L76.2393 64.8736C79.4693 63.5632 81.0642 59.6881 79.2179 56.2725C77.8598 53.7591 74.6793 52.6409 71.8155 53.6681ZM12.5001 68.5693C12.4964 69.4275 11.7963 70.1245 10.9375 70.1245H3.12501V45.125H10.9375C11.7988 45.125 12.5001 45.8257 12.5001 46.6875V68.5693ZM75.0522 61.9836C75.0357 61.9897 75.0192 61.997 75.0028 62.0037C75.0028 62.0037 45.7961 74.8639 45.7906 74.8664C43.5903 75.8673 41.2502 76.3745 38.8356 76.3745C35.7912 76.3745 32.8029 75.5469 30.193 73.9801L22.021 69.0771C20.2717 68.0279 17.7668 67.3883 15.6251 67.1319V48.25H26.4326C27.7729 48.25 29.0974 48.5967 30.2638 49.2541L38.5188 53.897C39.2183 54.2913 40.0142 54.5001 40.8187 54.5001H50.9371C52.6601 54.5001 54.0621 55.9014 54.0621 57.6251C54.0621 59.342 52.6595 60.7501 50.9371 60.7501H35.0532C34.1902 60.7501 33.4907 61.4495 33.4907 62.3126C33.4907 63.175 34.1902 63.8745 35.0532 63.8745H50.9371C51.9619 63.8745 52.9708 63.62 53.8662 63.1427C53.8662 63.1427 72.8574 56.6143 72.8647 56.6119C74.2648 56.1065 75.8481 56.6094 76.4688 57.7581C77.3996 59.4799 76.63 61.3519 75.0522 61.9836Z"
            fill="#1A1721"
          />
          <path
            d="M57.5883 34.9073L49.2593 43.2578L40.9302 34.9073M40.9302 34.9073C40.3809 34.3661 39.9481 33.7156 39.6593 32.9968C39.3704 32.2781 39.2317 31.5065 39.2519 30.7308C39.2721 29.9551 39.4507 29.192 39.7766 28.4896C40.1024 27.7872 40.5684 27.1607 41.1451 26.6496C41.7219 26.1384 42.3969 25.7537 43.1278 25.5196C43.8586 25.2856 44.6294 25.2072 45.3917 25.2895C46.1539 25.3718 46.891 25.613 47.5567 25.9979C48.2223 26.3828 48.802 26.903 49.2593 27.5258C49.7185 26.9075 50.2989 26.3918 50.964 26.011C51.6292 25.6303 52.3649 25.3926 53.125 25.3128C53.885 25.2331 54.6532 25.313 55.3814 25.5476C56.1096 25.7822 56.7821 26.1665 57.3568 26.6763C57.9315 27.1861 58.3961 27.8105 58.7215 28.5105C59.0469 29.2104 59.226 29.9708 59.2478 30.744C59.2695 31.5173 59.1333 32.2868 58.8478 33.0043C58.5622 33.7218 58.1334 34.3719 57.5883 34.914"
            fill="#CDABFF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.2575 26.5245C44.669 26.4609 44.0738 26.5214 43.509 26.7023C42.9443 26.8831 42.4216 27.1807 41.9742 27.5772C41.5268 27.9738 41.1643 28.4606 40.9105 29.0078C40.6567 29.555 40.5173 30.1501 40.5015 30.7555C40.4857 31.3609 40.594 31.9628 40.8191 32.5229C41.0442 33.0831 41.381 33.5889 41.8074 34.0089L41.8153 34.0167L41.8153 34.0167L49.2593 41.4799L56.7032 34.0167C56.7273 33.9926 56.752 33.9697 56.7774 33.948C57.1673 33.542 57.4763 33.062 57.6864 32.5343C57.9089 31.9751 58.0152 31.3748 57.9982 30.7713C57.9813 30.1678 57.8414 29.5748 57.588 29.0296C57.3345 28.4844 56.9731 27.9991 56.5273 27.6036C56.0815 27.2081 55.5608 26.9109 54.998 26.7296C54.4354 26.5483 53.8422 26.4866 53.2554 26.5482C52.6685 26.6097 52.0999 26.7933 51.5851 27.088C51.0701 27.3828 50.6198 27.7826 50.2627 28.2633C50.0262 28.5818 49.6525 28.7691 49.2558 28.768C48.8591 28.7669 48.4864 28.5775 48.2517 28.2577C47.8961 27.7735 47.4463 27.3701 46.931 27.0722C46.4157 26.7743 45.8459 26.588 45.2575 26.5245ZM58.3924 35.8633L50.1443 44.1327C49.9098 44.3679 49.5913 44.5 49.2593 44.5C48.9272 44.5 48.6087 44.3679 48.3742 44.1327L40.0489 35.7859C39.3786 35.1244 38.8513 34.3308 38.4994 33.4551C38.1468 32.5777 37.9777 31.6365 38.0023 30.6905C38.027 29.7445 38.2448 28.8134 38.6426 27.9558C39.0405 27.0981 39.6099 26.332 40.316 25.7063C41.0222 25.0804 41.8496 24.6086 42.7465 24.3214C43.6435 24.0341 44.5899 23.9379 45.5259 24.0389C46.4619 24.14 47.3663 24.4361 48.1824 24.9079C48.5684 25.1312 48.9306 25.3914 49.2644 25.6842C49.5975 25.3952 49.9586 25.1385 50.343 24.9184C51.1585 24.4516 52.0612 24.1597 52.9945 24.0618C53.9279 23.9639 54.8711 24.0621 55.7647 24.35C56.6584 24.638 57.4826 25.1092 58.1863 25.7334C58.8899 26.3575 59.4577 27.1211 59.855 27.9757C60.2523 28.8303 60.4708 29.7581 60.4973 30.7011C60.5238 31.6441 60.3577 32.5828 60.0092 33.4587C59.6606 34.3345 59.1368 35.1293 58.4696 35.7926C58.4446 35.8175 58.4188 35.841 58.3924 35.8633Z"
            fill="#1A1721"
          />
        </g>
      </svg>
    </Center>
  );
}
