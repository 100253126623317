import { FamilyUnit } from '@zorro/clients';
import { employeeFamilyUnitLabelConfig } from '@zorro/types';

// TODO: FamilyUnit and PartOfCoverage basically do the same, merge them into one
export const familyUnitToStringMap: Record<FamilyUnit, string> = {
  [FamilyUnit.EMPLOYEE_ONLY]: employeeFamilyUnitLabelConfig.EMPLOYEE_ONLY,
  [FamilyUnit.EMPLOYEE_SPOUSE]: employeeFamilyUnitLabelConfig.EMPLOYEE_SPOUSE,
  [FamilyUnit.EMPLOYEE_CHILD]: employeeFamilyUnitLabelConfig.EMPLOYEE_CHILD,
  [FamilyUnit.FAMILY]: employeeFamilyUnitLabelConfig.FAMILY,
};
