import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const PeriodIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg width="2.5rem" height="2.5rem" viewBox="0 0 40 40" fill="none">
        <g clipPath="url(#prefix__clip0_1327_14463)">
          <path d="M5 9h23v19a2 2 0 01-2 2H5V9z" fill="#80E8B6" />
          <path
            d="M19.658 35H8.333A3.333 3.333 0 015 31.667v-20a3.333 3.333 0 013.333-3.334h20a3.334 3.334 0 013.334 3.334v6.666"
            stroke="#1A1721"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23.334 30a6.667 6.667 0 1013.334 0 6.667 6.667 0 00-13.334 0zM25 5v6.667M11.666 5v6.667M5 18.333h26.667"
            stroke="#1A1721"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M30 27.493V30l1.667 1.667"
            stroke="#1A1721"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="prefix__clip0_1327_14463">
            <path fill="#fff" d="M0 0h40v40H0z" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
