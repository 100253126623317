import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const ReferralFreeIcon = ({ style, imageAlt }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="16"
        fill="none"
        height="16"
        viewBox="0 0 16 16"
        aria-label={imageAlt}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1_1290)">
          <path
            d="M4.66634 2H11.333C11.6866 2 12.0258 2.14048 12.2758 2.39052C12.5259 2.64057 12.6663 2.97971 12.6663 3.33333V10M12.6663 12.6667C12.6663 13.0203 12.5259 13.3594 12.2758 13.6095C12.0258 13.8595 11.6866 14 11.333 14H4.66634C4.31272 14 3.97358 13.8595 3.72353 13.6095C3.47348 13.3594 3.33301 13.0203 3.33301 12.6667V3.33333"
            stroke="#1A1721"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.33301 4.6665H9.99967"
            stroke="#1A1721"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 7.3335H7.33333"
            stroke="#1A1721"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 10H8.66667"
            stroke="#1A1721"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2 2L14 14"
            stroke="#1A1721"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_1290">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
