import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export function HandWithThreeHearts({ style }: SVGIconProps) {
  return (
    <Center style={style}>
      <svg
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_343_639"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="80"
          height="80"
        >
          <rect width="80" height="80" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_343_639)">
          <path
            d="M53.2448 61.7047L72.3342 55.1421C74.3826 54.403 76.8087 55.1012 77.8433 57.0153C79.2483 59.6136 78.0185 62.4792 75.6326 63.4344L46.4375 76.2891C44.0498 77.3749 41.4576 77.937 38.8356 77.937C35.5079 77.937 32.2419 77.0325 29.3891 75.3198L21.2171 70.4168C19.3311 69.2859 16.2543 68.6511 14.0625 68.5717C14.0625 68.5687 14.0625 46.6875 14.0625 46.6875H26.4325C28.0439 46.6875 29.6265 47.1013 31.0297 47.8917L39.286 52.5359C39.7547 52.7996 40.2809 52.9375 40.8186 52.9375H50.937C53.5262 52.9375 55.6246 55.0359 55.6246 57.625C55.6246 59.3749 54.6669 60.8996 53.2448 61.7047Z"
            fill="#FFC39C"
          />
          <path
            d="M14.0626 46.6875V68.5718C14.0577 70.2936 12.6612 71.687 10.9375 71.687H1.5625V43.5625H10.9375C12.6642 43.5625 14.0626 44.9608 14.0626 46.6875Z"
            fill="#8033EF"
          />
          <path
            d="M71.8155 53.6681L57.0852 58.7323C57.1505 58.3697 57.1871 57.9998 57.1871 57.6245C57.1871 54.1784 54.3838 51.3744 50.9371 51.3744H40.8187C40.5501 51.3744 40.2852 51.3055 40.0514 51.1736L31.7964 46.5307C30.1643 45.6109 28.3094 45.1244 26.4326 45.1244H15.3559C14.7114 43.3062 12.9743 42 10.9375 42H1.56251C0.699466 42 0 42.6995 0 43.5625V71.687C0 72.5501 0.699466 73.2495 1.56251 73.2495H10.9375C12.9279 73.2495 14.6345 71.9989 15.312 70.2423C17.0808 70.462 19.1273 70.9851 20.4133 71.7566L28.5847 76.6596C31.6804 78.5175 35.2248 79.4995 38.8356 79.4995C41.697 79.4995 44.4692 78.899 47.076 77.7149L76.2393 64.8736C79.4693 63.5632 81.0642 59.6881 79.2179 56.2725C77.8598 53.7591 74.6793 52.6409 71.8155 53.6681ZM12.5001 68.5693C12.4964 69.4275 11.7963 70.1245 10.9375 70.1245H3.12501V45.125H10.9375C11.7988 45.125 12.5001 45.8257 12.5001 46.6875V68.5693ZM75.0522 61.9836C75.0357 61.9897 75.0192 61.997 75.0028 62.0037C75.0028 62.0037 45.7961 74.8639 45.7906 74.8664C43.5903 75.8673 41.2502 76.3745 38.8356 76.3745C35.7912 76.3745 32.8029 75.5469 30.193 73.9801L22.021 69.0771C20.2717 68.0279 17.7668 67.3883 15.6251 67.1319V48.25H26.4326C27.7729 48.25 29.0974 48.5967 30.2638 49.2541L38.5188 53.897C39.2183 54.2913 40.0142 54.5001 40.8187 54.5001H50.9371C52.6601 54.5001 54.0621 55.9014 54.0621 57.6251C54.0621 59.342 52.6595 60.7501 50.9371 60.7501H35.0532C34.1902 60.7501 33.4907 61.4495 33.4907 62.3126C33.4907 63.175 34.1902 63.8745 35.0532 63.8745H50.9371C51.9619 63.8745 52.9708 63.62 53.8662 63.1427C53.8662 63.1427 72.8574 56.6143 72.8647 56.6119C74.2648 56.1065 75.8481 56.6094 76.4688 57.7581C77.3996 59.4799 76.63 61.3519 75.0522 61.9836Z"
            fill="#1A1721"
          />
          <path
            d="M69.3383 40.6495L61.0093 49L52.6802 40.6495M52.6802 40.6495C52.1309 40.1083 51.6981 39.4578 51.4093 38.739C51.1204 38.0202 50.9817 37.2487 51.0019 36.473C51.0221 35.6973 51.2007 34.9342 51.5266 34.2318C51.8524 33.5294 52.3184 32.9029 52.8951 32.3917C53.4719 31.8806 54.1469 31.4959 54.8778 31.2618C55.6086 31.0277 56.3794 30.9494 57.1417 31.0317C57.9039 31.114 58.641 31.3552 59.3067 31.7401C59.9723 32.1249 60.552 32.6452 61.0093 33.268C61.4685 32.6497 62.0489 32.134 62.714 31.7532C63.3792 31.3724 64.1149 31.1347 64.875 31.055C65.635 30.9753 66.4032 31.0552 67.1314 31.2898C67.8596 31.5244 68.5321 31.9087 69.1068 32.4185C69.6815 32.9283 70.1461 33.5527 70.4715 34.2526C70.7969 34.9526 70.976 35.713 70.9978 36.4862C71.0195 37.2595 70.8833 38.0289 70.5978 38.7465C70.3122 39.464 69.8834 40.1141 69.3383 40.6562"
            fill="#CDABFF"
          />
          <path
            d="M69.3383 40.6495L61.0093 49L52.6802 40.6495M52.6802 40.6495C52.1309 40.1083 51.6981 39.4578 51.4093 38.739C51.1204 38.0202 50.9817 37.2487 51.0019 36.473C51.0221 35.6973 51.2007 34.9342 51.5266 34.2318C51.8524 33.5294 52.3184 32.9029 52.8951 32.3917C53.4719 31.8806 54.1469 31.4959 54.8778 31.2618C55.6086 31.0277 56.3794 30.9494 57.1417 31.0317C57.9039 31.114 58.641 31.3552 59.3067 31.7401C59.9723 32.1249 60.552 32.6452 61.0093 33.268C61.4685 32.6497 62.0489 32.134 62.714 31.7532C63.3792 31.3724 64.1149 31.1347 64.875 31.055C65.635 30.9753 66.4032 31.0552 67.1314 31.2898C67.8596 31.5244 68.5321 31.9087 69.1068 32.4185C69.6815 32.9283 70.1461 33.5527 70.4715 34.2526C70.7969 34.9526 70.976 35.713 70.9978 36.4862C71.0195 37.2595 70.8833 38.0289 70.5978 38.7465C70.3122 39.464 69.8834 40.1141 69.3383 40.6562"
            stroke="#1A1721"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M69.3383 12.6495L61.0093 21L52.6802 12.6495M52.6802 12.6495C52.1309 12.1083 51.6981 11.4578 51.4093 10.739C51.1204 10.0202 50.9817 9.2487 51.0019 8.47298C51.0221 7.69726 51.2007 6.93418 51.5266 6.23178C51.8524 5.52938 52.3184 4.90289 52.8951 4.39174C53.4719 3.8806 54.1469 3.49588 54.8778 3.26181C55.6086 3.02775 56.3794 2.9494 57.1417 3.03171C57.9039 3.11402 58.641 3.35521 59.3067 3.74008C59.9723 4.12494 60.552 4.64516 61.0093 5.26797C61.4685 4.64968 62.0489 4.13401 62.714 3.75323C63.3792 3.37244 64.1149 3.13474 64.875 3.055C65.635 2.97527 66.4032 3.0552 67.1314 3.28982C67.8596 3.52443 68.5321 3.90867 69.1068 4.41848C69.6815 4.92829 70.1461 5.55271 70.4715 6.25264C70.7969 6.95258 70.976 7.71297 70.9978 8.48622C71.0195 9.25947 70.8833 10.0289 70.5978 10.7465C70.3122 11.464 69.8834 12.1141 69.3383 12.6562"
            fill="#CDABFF"
          />
          <path
            d="M69.3383 12.6495L61.0093 21L52.6802 12.6495M52.6802 12.6495C52.1309 12.1083 51.6981 11.4578 51.4093 10.739C51.1204 10.0202 50.9817 9.2487 51.0019 8.47298C51.0221 7.69726 51.2007 6.93418 51.5266 6.23178C51.8524 5.52938 52.3184 4.90289 52.8951 4.39174C53.4719 3.8806 54.1469 3.49588 54.8778 3.26181C55.6086 3.02775 56.3794 2.9494 57.1417 3.03171C57.9039 3.11402 58.641 3.35521 59.3067 3.74008C59.9723 4.12494 60.552 4.64516 61.0093 5.26797C61.4685 4.64968 62.0489 4.13401 62.714 3.75323C63.3792 3.37244 64.1149 3.13474 64.875 3.055C65.635 2.97527 66.4032 3.0552 67.1314 3.28982C67.8596 3.52443 68.5321 3.90867 69.1068 4.41848C69.6815 4.92829 70.1461 5.55271 70.4715 6.25264C70.7969 6.95258 70.976 7.71297 70.9978 8.48622C71.0195 9.25947 70.8833 10.0289 70.5978 10.7465C70.3122 11.464 69.8834 12.1141 69.3383 12.6562"
            stroke="#1A1721"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M38.3383 26.6495L30.0093 35L21.6802 26.6495M21.6802 26.6495C21.1309 26.1083 20.6981 25.4578 20.4093 24.739C20.1204 24.0202 19.9817 23.2487 20.0019 22.473C20.0221 21.6973 20.2007 20.9342 20.5266 20.2318C20.8524 19.5294 21.3184 18.9029 21.8951 18.3917C22.4719 17.8806 23.1469 17.4959 23.8778 17.2618C24.6086 17.0277 25.3794 16.9494 26.1417 17.0317C26.9039 17.114 27.641 17.3552 28.3067 17.7401C28.9723 18.1249 29.552 18.6452 30.0093 19.268C30.4685 18.6497 31.0489 18.134 31.714 17.7532C32.3792 17.3724 33.1149 17.1347 33.875 17.055C34.635 16.9753 35.4032 17.0552 36.1314 17.2898C36.8596 17.5244 37.5321 17.9087 38.1068 18.4185C38.6815 18.9283 39.1461 19.5527 39.4715 20.2526C39.7969 20.9526 39.976 21.713 39.9978 22.4862C40.0195 23.2595 39.8833 24.0289 39.5978 24.7465C39.3122 25.464 38.8834 26.1141 38.3383 26.6562"
            fill="#CDABFF"
          />
          <path
            d="M38.3383 26.6495L30.0093 35L21.6802 26.6495M21.6802 26.6495C21.1309 26.1083 20.6981 25.4578 20.4093 24.739C20.1204 24.0202 19.9817 23.2487 20.0019 22.473C20.0221 21.6973 20.2007 20.9342 20.5266 20.2318C20.8524 19.5294 21.3184 18.9029 21.8951 18.3917C22.4719 17.8806 23.1469 17.4959 23.8778 17.2618C24.6086 17.0277 25.3794 16.9494 26.1417 17.0317C26.9039 17.114 27.641 17.3552 28.3067 17.7401C28.9723 18.1249 29.552 18.6452 30.0093 19.268C30.4685 18.6497 31.0489 18.134 31.714 17.7532C32.3792 17.3724 33.1149 17.1347 33.875 17.055C34.635 16.9753 35.4032 17.0552 36.1314 17.2898C36.8596 17.5244 37.5321 17.9087 38.1068 18.4185C38.6815 18.9283 39.1461 19.5527 39.4715 20.2526C39.7969 20.9526 39.976 21.713 39.9978 22.4862C40.0195 23.2595 39.8833 24.0289 39.5978 24.7465C39.3122 25.464 38.8834 26.1141 38.3383 26.6562"
            stroke="#1A1721"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </Center>
  );
}
