import { BenefitType, FamilyUnit } from '@zorro/clients';
import { capitalizeEachFirstLetter } from '@zorro/shared/formatters';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const typeOrSubType = (supplemental: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return supplemental.type === BenefitType.SUPPLEMENTAL_OTHER
    ? supplemental.settings?.subType
    : supplemental.type;
};

export const dentalPremiumCalculationMap: Record<FamilyUnit, number> = {
  [FamilyUnit.EMPLOYEE_ONLY]: 27,
  [FamilyUnit.EMPLOYEE_CHILD]: 82,
  [FamilyUnit.EMPLOYEE_SPOUSE]: 82,
  [FamilyUnit.FAMILY]: 82,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const determineSupplementalPremium = (supplemental: any) => {
  if (
    supplemental.type === BenefitType.SUPPLEMENTAL_FLEXIBLE_SPENDING_ACCOUNT
  ) {
    let sum = 0;
    sum += supplemental.settings?.fsaDependentsCoverageAmount ?? 0;
    sum += supplemental.settings?.fsaEmployeeCoverageAmount ?? 0;
    return sum;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return supplemental.premium;
};

export const visionPremiumCalculationMap: Record<FamilyUnit, number> = {
  [FamilyUnit.EMPLOYEE_ONLY]: 5.2,
  [FamilyUnit.EMPLOYEE_CHILD]: 12.7,
  [FamilyUnit.EMPLOYEE_SPOUSE]: 12.7,
  [FamilyUnit.FAMILY]: 12.7,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatSupplementalTitle = (title: any): string => {
  switch (title) {
    case BenefitType.SUPPLEMENTAL_FLEXIBLE_SPENDING_ACCOUNT: {
      return 'FSA';
    }
    case BenefitType.SUPPLEMENTAL_ACCIDENTAL_DEATH_AND_DISMEMBERMENT: {
      return 'AD&D';
    }
    default: {
      return capitalizeEachFirstLetter(title);
    }
  }
};
