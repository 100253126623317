/**
 * Exponential Backoff Delay
 * i.e. 100ms, 200ms, 400ms, etc.
 *
 * @param retryIdx - the retry index, the initial kickoff value should be `0`
 */
export function backoffDelay(retryIdx: number): Promise<void> {
  const delay = Math.pow(2, retryIdx) * 100;
  return new Promise((resolve) => setTimeout(resolve, delay));
}
