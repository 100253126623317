export const UnEmployedIcon = () => {
  return (
    <svg width={24} height={24} fill="none">
      <g clipPath="url(#prefix__clip0_733_7522)">
        <path
          d="M19 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2zM8 7V5a2 2 0 012-2h4a2 2 0 012 2v2M12 12v.01M3 13a20 20 0 0018 0"
          stroke="#3C0789"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x={23.085}
          y={0.919}
          width={2.7}
          height={32.083}
          rx={1.35}
          transform="rotate(46.226 23.085 .919)"
          fill="#3C0789"
          stroke="#fff"
          strokeWidth={1.3}
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_733_7522">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
