import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const ZorroIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_3745_12117"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="16"
          height="16"
        >
          <rect width="16" height="16" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_3745_12117)">
          <path
            d="M12.9984 5.43342V4.12587C12.9984 3.93518 12.8486 3.78025 12.6642 3.78025H8.69712C8.48642 3.78025 8.31523 3.60319 8.31523 3.38526V1.14812C8.31523 1.0664 8.25103 1 8.17202 1H5.45761C4.50782 1 3.73745 1.79679 3.73745 2.77916V4.12246C3.73745 4.20418 3.80165 4.27058 3.88066 4.27058H7.73745C8.08642 4.27058 8.25267 4.71495 7.99424 4.95841L3.62058 9.06324C3.22551 9.43439 3 9.95877 3 10.5104V11.8452C3 12.0359 3.14979 12.1908 3.33416 12.1908H7.32593C7.53663 12.1908 7.70782 12.3679 7.70782 12.5858V14.8519C7.70782 14.9336 7.77202 15 7.85103 15H10.6346C11.558 15 12.3053 14.2253 12.3053 13.2719V11.8792C12.3053 11.7975 12.2412 11.7311 12.1621 11.7311H8.07819C7.77202 11.7311 7.62716 11.3412 7.85432 11.1301L12.3794 6.88398C12.7745 6.51283 13 5.98845 13 5.43682L12.9984 5.43342Z"
            fill="#812EE5"
          />
        </g>
      </svg>
    </Center>
  );
};
