import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const FacilitiesIcon = ({ imageAlt, style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-label={imageAlt}
      >
        <g clipPath="url(#clip0_4832_18377)">
          <path
            d="M7.5 11C7.5 8.79086 9.29086 7 11.5 7H21.5C23.7091 7 25.5 8.79086 25.5 11V29H7.5V11Z"
            fill="#80E8B6"
          />
          <path
            d="M7.1665 28V6.66667C7.1665 5.95942 7.44746 5.28115 7.94755 4.78105C8.44765 4.28095 9.12593 4 9.83317 4H23.1665C23.8737 4 24.552 4.28095 25.0521 4.78105C25.5522 5.28115 25.8332 5.95942 25.8332 6.66667V28"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.5 28V22.6667C12.5 21.9594 12.781 21.2811 13.281 20.781C13.7811 20.281 14.4594 20 15.1667 20H17.8333C18.5406 20 19.2189 20.281 19.719 20.781C20.219 21.2811 20.5 21.9594 20.5 22.6667V28"
            fill="white"
          />
          <path
            d="M12.5 28V22.6667C12.5 21.9594 12.781 21.2811 13.281 20.781C13.7811 20.281 14.4594 20 15.1667 20H17.8333C18.5406 20 19.2189 20.281 19.719 20.781C20.219 21.2811 20.5 21.9594 20.5 22.6667V28"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.8335 12H19.1668"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.5 9.33301V14.6663"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.5 28H28.5"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_4832_18377">
            <rect
              width="32"
              height="32"
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
