export const MoneyStackExtraLarge = () => {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_199_218"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="96"
        height="96"
      >
        <rect width="96" height="96" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_199_218)">
        <path
          d="M74.25 51C83.5835 51 93.5 48.107 93.5 42.75C93.5 41.991 92.884 41.375 92.125 41.375C91.366 41.375 90.75 41.991 90.75 42.75C90.75 45.0462 84.4745 48.25 74.25 48.25C64.0255 48.25 57.75 45.0462 57.75 42.75C57.75 41.991 57.134 41.375 56.375 41.375C55.616 41.375 55 41.991 55 42.75C55 48.107 64.9165 51 74.25 51Z"
          fill="#8033EF"
        />
        <path
          d="M74.25 42.75C83.5835 42.75 93.5 39.857 93.5 34.5C93.5 33.741 92.884 33.125 92.125 33.125C91.366 33.125 90.75 33.741 90.75 34.5C90.75 36.7962 84.4745 40 74.25 40C64.0255 40 57.75 36.7962 57.75 34.5C57.75 33.741 57.134 33.125 56.375 33.125C55.616 33.125 55 33.741 55 34.5C55 39.857 64.9165 42.75 74.25 42.75Z"
          fill="#8033EF"
        />
        <path
          d="M74.25 67.5C83.5835 67.5 93.5 64.607 93.5 59.25C93.5 58.491 92.884 57.875 92.125 57.875C91.366 57.875 90.75 58.491 90.75 59.25C90.75 61.5462 84.4745 64.75 74.25 64.75C72.754 64.75 71.4505 64.6895 70.2708 64.5658C69.5255 64.4833 68.838 65.0333 68.7583 65.7868C68.6785 66.5403 69.2257 67.2195 69.9792 67.2993C71.2607 67.4368 72.6578 67.5 74.25 67.5Z"
          fill="#8033EF"
        />
        <path
          d="M74.25 75.75C83.5835 75.75 93.5 72.857 93.5 67.5C93.5 66.741 92.884 66.125 92.125 66.125C91.366 66.125 90.75 66.741 90.75 67.5C90.75 69.7962 84.4745 73 74.25 73C72.754 73 71.4505 72.9395 70.2708 72.8158C69.5255 72.7195 68.838 73.2833 68.7583 74.0368C68.6785 74.7903 69.2257 75.4695 69.9792 75.5493C71.2607 75.6868 72.6578 75.75 74.25 75.75Z"
          fill="#8033EF"
        />
        <path
          d="M74.25 59.25C83.5835 59.25 93.5 56.357 93.5 51C93.5 50.241 92.884 49.625 92.125 49.625C91.366 49.625 90.75 50.241 90.75 51C90.75 53.2962 84.4745 56.5 74.25 56.5C72.754 56.5 71.4505 56.4395 70.2708 56.3157C69.5255 56.2277 68.838 56.7805 68.7583 57.5367C68.6785 58.2902 69.2257 58.9695 69.9792 59.0493C71.2607 59.1868 72.6578 59.25 74.25 59.25Z"
          fill="#8033EF"
        />
        <path
          d="M74.25 34.5C83.5835 34.5 93.5 31.607 93.5 26.25C93.5 20.893 83.5835 18 74.25 18C64.9165 18 55 20.893 55 26.25C55 31.607 64.9165 34.5 74.25 34.5ZM74.25 20.75C84.4745 20.75 90.75 23.9538 90.75 26.25C90.75 28.5462 84.4745 31.75 74.25 31.75C64.0255 31.75 57.75 28.5462 57.75 26.25C57.75 23.9538 64.0255 20.75 74.25 20.75Z"
          fill="#8033EF"
        />
        <path
          d="M92.125 68.875C92.884 68.875 93.5 68.259 93.5 67.5V26.25C93.5 25.491 92.884 24.875 92.125 24.875C91.366 24.875 90.75 25.491 90.75 26.25V67.5C90.75 68.259 91.366 68.875 92.125 68.875Z"
          fill="#8033EF"
        />
        <path
          d="M56.375 44.125C57.134 44.125 57.75 43.509 57.75 42.75V26.25C57.75 25.491 57.134 24.875 56.375 24.875C55.616 24.875 55 25.491 55 26.25V42.75C55 43.509 55.616 44.125 56.375 44.125Z"
          fill="#8033EF"
        />
        <path
          d="M47.75 82C38.4165 82 28.5 79.107 28.5 73.75C28.5 72.991 29.116 72.375 29.875 72.375C30.634 72.375 31.25 72.991 31.25 73.75C31.25 76.0462 37.5255 79.25 47.75 79.25C57.9745 79.25 64.25 76.0462 64.25 73.75C64.25 72.991 64.866 72.375 65.625 72.375C66.384 72.375 67 72.991 67 73.75C67 79.107 57.0835 82 47.75 82Z"
          fill="#8033EF"
        />
        <path
          d="M47.75 73.75C38.4165 73.75 28.5 70.857 28.5 65.5C28.5 64.741 29.116 64.125 29.875 64.125C30.634 64.125 31.25 64.741 31.25 65.5C31.25 67.7962 37.5255 71 47.75 71C57.9745 71 64.25 67.7962 64.25 65.5C64.25 64.741 64.866 64.125 65.625 64.125C66.384 64.125 67 64.741 67 65.5C67 70.857 57.0835 73.75 47.75 73.75Z"
          fill="#8033EF"
        />
        <path
          d="M47.75 65.5C38.4165 65.5 28.5 62.607 28.5 57.25C28.5 51.893 38.4165 49 47.75 49C57.0835 49 67 51.893 67 57.25C67 62.607 57.0835 65.5 47.75 65.5ZM47.75 51.75C37.5255 51.75 31.25 54.9538 31.25 57.25C31.25 59.5462 37.5255 62.75 47.75 62.75C57.9745 62.75 64.25 59.5462 64.25 57.25C64.25 54.9538 57.9745 51.75 47.75 51.75Z"
          fill="#8033EF"
        />
        <path
          d="M65.625 75.125C64.866 75.125 64.25 74.509 64.25 73.75V57.25C64.25 56.491 64.866 55.875 65.625 55.875C66.384 55.875 67 56.491 67 57.25V73.75C67 74.509 66.384 75.125 65.625 75.125Z"
          fill="#8033EF"
        />
        <path
          d="M29.875 75.125C29.116 75.125 28.5 74.509 28.5 73.75V57.25C28.5 56.491 29.116 55.875 29.875 55.875C30.634 55.875 31.25 56.491 31.25 57.25V73.75C31.25 74.509 30.634 75.125 29.875 75.125Z"
          fill="#8033EF"
        />
        <path
          d="M21.25 52C11.9165 52 2 49.107 2 43.75C2 42.991 2.616 42.375 3.375 42.375C4.134 42.375 4.75 42.991 4.75 43.75C4.75 46.0462 11.0255 49.25 21.25 49.25C31.4745 49.25 37.75 46.0462 37.75 43.75C37.75 42.991 38.366 42.375 39.125 42.375C39.884 42.375 40.5 42.991 40.5 43.75C40.5 49.107 30.5835 52 21.25 52Z"
          fill="#8033EF"
        />
        <path
          d="M21.25 43.75C11.9165 43.75 2 40.857 2 35.5C2 34.741 2.616 34.125 3.375 34.125C4.134 34.125 4.75 34.741 4.75 35.5C4.75 37.7962 11.0255 41 21.25 41C31.4745 41 37.75 37.7962 37.75 35.5C37.75 34.741 38.366 34.125 39.125 34.125C39.884 34.125 40.5 34.741 40.5 35.5C40.5 40.857 30.5835 43.75 21.25 43.75Z"
          fill="#8033EF"
        />
        <path
          d="M21.25 68.5C11.9165 68.5 2 65.607 2 60.25C2 59.491 2.616 58.875 3.375 58.875C4.134 58.875 4.75 59.491 4.75 60.25C4.75 62.5462 11.0255 65.75 21.25 65.75C22.746 65.75 24.0495 65.6895 25.2292 65.5658C25.9745 65.4833 26.662 66.0333 26.7417 66.7868C26.8215 67.5403 26.2743 68.2195 25.5208 68.2993C24.2393 68.4368 22.8422 68.5 21.25 68.5Z"
          fill="#8033EF"
        />
        <path
          d="M21.25 76.75C11.9165 76.75 2 73.857 2 68.5C2 67.741 2.616 67.125 3.375 67.125C4.134 67.125 4.75 67.741 4.75 68.5C4.75 70.7962 11.0255 74 21.25 74C22.746 74 24.0495 73.9395 25.2292 73.8158C25.9745 73.7195 26.662 74.2833 26.7417 75.0368C26.8215 75.7903 26.2743 76.4695 25.5208 76.5493C24.2393 76.6868 22.8422 76.75 21.25 76.75Z"
          fill="#8033EF"
        />
        <path
          d="M21.25 60.25C11.9165 60.25 2 57.357 2 52C2 51.241 2.616 50.625 3.375 50.625C4.134 50.625 4.75 51.241 4.75 52C4.75 54.2962 11.0255 57.5 21.25 57.5C22.746 57.5 24.0495 57.4395 25.2292 57.3157C25.9745 57.2277 26.662 57.7805 26.7417 58.5367C26.8215 59.2902 26.2743 59.9695 25.5208 60.0493C24.2393 60.1868 22.8422 60.25 21.25 60.25Z"
          fill="#8033EF"
        />
        <path
          d="M21.25 35.5C11.9165 35.5 2 32.607 2 27.25C2 21.893 11.9165 19 21.25 19C30.5835 19 40.5 21.893 40.5 27.25C40.5 32.607 30.5835 35.5 21.25 35.5ZM21.25 21.75C11.0255 21.75 4.75 24.9538 4.75 27.25C4.75 29.5462 11.0255 32.75 21.25 32.75C31.4745 32.75 37.75 29.5462 37.75 27.25C37.75 24.9538 31.4745 21.75 21.25 21.75Z"
          fill="#8033EF"
        />
        <path
          d="M3.375 69.875C2.616 69.875 2 69.259 2 68.5V27.25C2 26.491 2.616 25.875 3.375 25.875C4.134 25.875 4.75 26.491 4.75 27.25V68.5C4.75 69.259 4.134 69.875 3.375 69.875Z"
          fill="#8033EF"
        />
        <path
          d="M39.125 45.125C38.366 45.125 37.75 44.509 37.75 43.75V27.25C37.75 26.491 38.366 25.875 39.125 25.875C39.884 25.875 40.5 26.491 40.5 27.25V43.75C40.5 44.509 39.884 45.125 39.125 45.125Z"
          fill="#8033EF"
        />
      </g>
    </svg>
  );
};
