import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const DoctorsGreenIcon = ({ imageAlt, style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-label={imageAlt}
      >
        <circle cx="17" cy="20" r="10" fill="#80E8B6" />
        <g clipPath="url(#clip0_1_19743)">
          <path
            d="M8 5.3335H6.66667C5.95942 5.3335 5.28115 5.61445 4.78105 6.11454C4.28095 6.61464 4 7.29292 4 8.00016V12.6668C4 14.6117 4.77262 16.477 6.14788 17.8523C7.52315 19.2275 9.38841 20.0002 11.3333 20.0002C13.2783 20.0002 15.1435 19.2275 16.5188 17.8523C17.894 16.477 18.6667 14.6117 18.6667 12.6668V8.00016C18.6667 7.29292 18.3857 6.61464 17.8856 6.11454C17.3855 5.61445 16.7072 5.3335 16 5.3335H14.6667"
            stroke="#1A1721"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.6667 20C10.6667 21.0506 10.8736 22.0909 11.2756 23.0615C11.6777 24.0321 12.2669 24.914 13.0098 25.6569C13.7527 26.3997 14.6346 26.989 15.6052 27.391C16.5758 27.7931 17.6161 28 18.6667 28C19.7172 28 20.7575 27.7931 21.7281 27.391C22.6987 26.989 23.5806 26.3997 24.3235 25.6569C25.0664 24.914 25.6557 24.0321 26.0577 23.0615C26.4597 22.0909 26.6667 21.0506 26.6667 20V16"
            stroke="#1A1721"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.6667 4V6.66667"
            stroke="#1A1721"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 4V6.66667"
            stroke="#1A1721"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.6667 15.9998C28.1394 15.9998 29.3333 14.8059 29.3333 13.3332C29.3333 11.8604 28.1394 10.6665 26.6667 10.6665C25.1939 10.6665 24 11.8604 24 13.3332C24 14.8059 25.1939 15.9998 26.6667 15.9998Z"
            stroke="#1A1721"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_19743">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
