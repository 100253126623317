import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const SquareRoundedPlusIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
        <path
          d="M23 5c9.6 0 12 3 12 15s-2.4 15-12 15-12-3-12-15S13.4 5 23 5z"
          fill="#80E8B6"
        />
        <path
          d="M15 20h10M20 15v10M20 5c12 0 15 3 15 15s-3 15-15 15S5 32 5 20 8 5 20 5z"
          stroke="#1A1721"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 5c12 0 15 3 15 15s-3 15-15 15S5 32 5 20 8 5 20 5z"
          stroke="#1A1721"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Center>
  );
};
