export const MoneyStackLarge = () => {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_349_179"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="96"
        height="96"
      >
        <rect width="96" height="96" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_349_179)">
        <path
          d="M27.25 58C17.9165 58 8 55.107 8 49.75C8 48.991 8.616 48.375 9.375 48.375C10.134 48.375 10.75 48.991 10.75 49.75C10.75 52.0462 17.0255 55.25 27.25 55.25C37.4745 55.25 43.75 52.0462 43.75 49.75C43.75 48.991 44.366 48.375 45.125 48.375C45.884 48.375 46.5 48.991 46.5 49.75C46.5 55.107 36.5835 58 27.25 58Z"
          fill="#8033EF"
        />
        <path
          d="M27.25 66.625C17.9165 66.625 8 63.732 8 58.375C8 57.616 8.616 57 9.375 57C10.134 57 10.75 57.616 10.75 58.375C10.75 60.6712 17.0255 63.875 27.25 63.875C37.4745 63.875 43.75 60.6712 43.75 58.375C43.75 57.616 44.366 57 45.125 57C45.884 57 46.5 57.616 46.5 58.375C46.5 63.732 36.5835 66.625 27.25 66.625Z"
          fill="#8033EF"
        />
        <path
          d="M27.25 75.625C17.9165 75.625 8 72.732 8 67.375C8 66.616 8.616 66 9.375 66C10.134 66 10.75 66.616 10.75 67.375C10.75 69.6712 17.0255 72.875 27.25 72.875C37.4745 72.875 43.75 69.6712 43.75 67.375C43.75 66.616 44.366 66 45.125 66C45.884 66 46.5 66.616 46.5 67.375C46.5 72.732 36.5835 75.625 27.25 75.625Z"
          fill="#8033EF"
        />
        <path
          d="M27.25 84.625C17.9165 84.625 8 81.732 8 76.375C8 75.616 8.616 75 9.375 75C10.134 75 10.75 75.616 10.75 76.375C10.75 78.6712 17.0255 81.875 27.25 81.875C37.4745 81.875 43.75 78.6712 43.75 76.375C43.75 75.616 44.366 75 45.125 75C45.884 75 46.5 75.616 46.5 76.375C46.5 81.732 36.5835 84.625 27.25 84.625Z"
          fill="#8033EF"
        />
        <path
          d="M27.25 49.75C17.9165 49.75 8 46.857 8 41.5C8 40.741 8.616 40.125 9.375 40.125C10.134 40.125 10.75 40.741 10.75 41.5C10.75 43.7962 17.0255 47 27.25 47C37.4745 47 43.75 43.7962 43.75 41.5C43.75 40.741 44.366 40.125 45.125 40.125C45.884 40.125 46.5 40.741 46.5 41.5C46.5 46.857 36.5835 49.75 27.25 49.75Z"
          fill="#8033EF"
        />
        <path
          d="M27.25 41.5C17.9165 41.5 8 38.607 8 33.25C8 27.893 17.9165 25 27.25 25C36.5835 25 46.5 27.893 46.5 33.25C46.5 38.607 36.5835 41.5 27.25 41.5ZM27.25 27.75C17.0255 27.75 10.75 30.9538 10.75 33.25C10.75 35.5463 17.0255 38.75 27.25 38.75C37.4745 38.75 43.75 35.5463 43.75 33.25C43.75 30.9538 37.4745 27.75 27.25 27.75Z"
          fill="#8033EF"
        />
        <path
          d="M45.125 78.125C44.366 78.125 43.75 77.509 43.75 76.75V34.25C43.75 33.491 44.366 32.875 45.125 32.875C45.884 32.875 46.5 33.491 46.5 34.25V76.75C46.5 77.509 45.884 78.125 45.125 78.125Z"
          fill="#8033EF"
        />
        <path
          d="M9.375 78.125C8.616 78.125 8 77.509 8 76.75V34.25C8 33.491 8.616 32.875 9.375 32.875C10.134 32.875 10.75 33.491 10.75 34.25V76.75C10.75 77.509 10.134 78.125 9.375 78.125Z"
          fill="#8033EF"
        />
        <path
          d="M68.25 45C58.9165 45 49 42.107 49 36.75C49 35.991 49.616 35.375 50.375 35.375C51.134 35.375 51.75 35.991 51.75 36.75C51.75 39.0462 58.0255 42.25 68.25 42.25C78.4745 42.25 84.75 39.0462 84.75 36.75C84.75 35.991 85.366 35.375 86.125 35.375C86.884 35.375 87.5 35.991 87.5 36.75C87.5 42.107 77.5835 45 68.25 45Z"
          fill="#8033EF"
        />
        <path
          d="M68.25 53.625C58.9165 53.625 49 50.732 49 45.375C49 44.616 49.616 44 50.375 44C51.134 44 51.75 44.616 51.75 45.375C51.75 47.6712 58.0255 50.875 68.25 50.875C78.4745 50.875 84.75 47.6712 84.75 45.375C84.75 44.616 85.366 44 86.125 44C86.884 44 87.5 44.616 87.5 45.375C87.5 50.732 77.5835 53.625 68.25 53.625Z"
          fill="#8033EF"
        />
        <path
          d="M68.25 62.625C58.9165 62.625 49 59.732 49 54.375C49 53.616 49.616 53 50.375 53C51.134 53 51.75 53.616 51.75 54.375C51.75 56.6712 58.0255 59.875 68.25 59.875C78.4745 59.875 84.75 56.6712 84.75 54.375C84.75 53.616 85.366 53 86.125 53C86.884 53 87.5 53.616 87.5 54.375C87.5 59.732 77.5835 62.625 68.25 62.625Z"
          fill="#8033EF"
        />
        <path
          d="M68.25 71.625C58.9165 71.625 49 68.732 49 63.375C49 62.616 49.616 62 50.375 62C51.134 62 51.75 62.616 51.75 63.375C51.75 65.6712 58.0255 68.875 68.25 68.875C78.4745 68.875 84.75 65.6712 84.75 63.375C84.75 62.616 85.366 62 86.125 62C86.884 62 87.5 62.616 87.5 63.375C87.5 68.732 77.5835 71.625 68.25 71.625Z"
          fill="#8033EF"
        />
        <path
          d="M68.25 36.75C58.9165 36.75 49 33.857 49 28.5C49 27.741 49.616 27.125 50.375 27.125C51.134 27.125 51.75 27.741 51.75 28.5C51.75 30.7962 58.0255 34 68.25 34C78.4745 34 84.75 30.7962 84.75 28.5C84.75 27.741 85.366 27.125 86.125 27.125C86.884 27.125 87.5 27.741 87.5 28.5C87.5 33.857 77.5835 36.75 68.25 36.75Z"
          fill="#8033EF"
        />
        <path
          d="M68.25 28.5C58.9165 28.5 49 25.607 49 20.25C49 14.893 58.9165 12 68.25 12C77.5835 12 87.5 14.893 87.5 20.25C87.5 25.607 77.5835 28.5 68.25 28.5ZM68.25 14.75C58.0255 14.75 51.75 17.9538 51.75 20.25C51.75 22.5463 58.0255 25.75 68.25 25.75C78.4745 25.75 84.75 22.5463 84.75 20.25C84.75 17.9538 78.4745 14.75 68.25 14.75Z"
          fill="#8033EF"
        />
        <path
          d="M86.125 65.125C85.366 65.125 84.75 64.509 84.75 63.75V21.25C84.75 20.491 85.366 19.875 86.125 19.875C86.884 19.875 87.5 20.491 87.5 21.25V63.75C87.5 64.509 86.884 65.125 86.125 65.125Z"
          fill="#8033EF"
        />
        <path
          d="M50.375 65.125C49.616 65.125 49 64.509 49 63.75V21.25C49 20.491 49.616 19.875 50.375 19.875C51.134 19.875 51.75 20.491 51.75 21.25V63.75C51.75 64.509 51.134 65.125 50.375 65.125Z"
          fill="#8033EF"
        />
      </g>
    </svg>
  );
};
