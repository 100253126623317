import { LeaveOfAbsenceDto } from '@zorro/clients';
import { parseDateISOThenFormatEnLocale } from '@zorro/shared/formatters';
import { EmploymentStatus } from '@zorro/types';

import { logger } from './logger';

export const getToolTipContentForEmploymentStatus = (
  employee: {
    id: string;
    eligibleFrom?: string | null;
    eligibleUntil?: string | null;
    leaveOfAbsence?: LeaveOfAbsenceDto | null;
  },
  status?: EmploymentStatus | null
): string | null => {
  const eligibilityStart = employee.eligibleFrom
    ? parseDateISOThenFormatEnLocale(employee.eligibleFrom)
    : '';
  const eligibilityEnd = employee.eligibleUntil
    ? parseDateISOThenFormatEnLocale(employee.eligibleUntil)
    : '';
  switch (status) {
    case EmploymentStatus.ELIGIBLE_EMPLOYED: {
      return 'Eligible for benefits';
    }
    case EmploymentStatus.ELIGIBLE_UPCOMING_LEAVE: {
      return `Upcoming leave of absence between ${parseDateISOThenFormatEnLocale(
        employee.leaveOfAbsence!.startDate
      )} and ${parseDateISOThenFormatEnLocale(
        employee.leaveOfAbsence!.endDate
      )}`;
    }
    case EmploymentStatus.INELIGIBLE_WAITING: {
      return `Waiting for eligibility start on ${eligibilityStart}`;
    }
    case EmploymentStatus.INELIGIBLE_ON_LEAVE: {
      return `On leave of absence between ${parseDateISOThenFormatEnLocale(
        employee.leaveOfAbsence!.startDate
      )} and ${parseDateISOThenFormatEnLocale(
        employee.leaveOfAbsence!.endDate
      )}`;
    }
    case EmploymentStatus.ELIGIBLE_UPCOMING_TERMINATION: {
      return `Benefit eligibility will end on ${eligibilityEnd}`;
    }
    case EmploymentStatus.INELIGIBLE_TERMINATED: {
      return `Benefit eligibility ended ${eligibilityEnd}`;
    }
    default: {
      logger.error(
        `Could not determine employee employment status for employee ${employee.id}`
      );
      return null;
    }
  }
};
