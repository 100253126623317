import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const InfoIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1"
          width="30"
          height="30"
          rx="5"
          fill="white"
          stroke="#8033EF"
          strokeWidth="2"
        />
        <path
          d="M16.1875 9H16.2094"
          stroke="#8033EF"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M14 14.25H16.1875V23H18.375" fill="white" />
        <path
          d="M14 14.25H16.1875V23H18.375"
          stroke="#8033EF"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Center>
  );
};
