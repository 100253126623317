import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

const IconZorroNoCard = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width="56"
        height="56"
        viewBox="0 0 56 56"
      >
        <path
          fill="#E5FAF0"
          d="M7 16a4 4 0 0 1 4-4h32v32H11a4 4 0 0 1-4-4V16Z"
        />
        <path
          stroke="#1A1721"
          d="m7 7 42 42M21 11.667h21a7 7 0 0 1 7 7v18.666c0 .688-.1 1.372-.299 2.03M44.03 44.035c-.658.198-1.342.299-2.03.298H14a7 7 0 0 1-7-7V18.667a7.002 7.002 0 0 1 4.956-6.697M7 25.667h18.667M35 25.667h14"
        />
      </svg>
    </Center>
  );
};

export { IconZorroNoCard };
