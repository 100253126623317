import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const InfoIconColored = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_4828_18475"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="32"
          height="32"
        >
          <rect width="32" height="32" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_4828_18475)">
          <mask
            id="mask1_4828_18475"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="4"
            y="4"
            width="24"
            height="24"
          >
            <rect x="4" y="4" width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask1_4828_18475)">
            <path
              d="M9.10484 26.6639L22.4887 26.5881C25.1101 26.5733 27.2228 24.3864 27.2076 21.7036L27.1301 8.00595L22.3837 8.03281L13.7462 8.0817C11.1249 8.09653 9.01213 10.2834 9.02731 12.9662L9.07734 21.8062L9.10484 26.6639Z"
              fill="#66D8F2"
            />
            <rect
              x="4.75"
              y="4.75"
              width="22.5"
              height="22.5"
              rx="5.25"
              stroke="#1A1721"
              strokeWidth="1.5"
            />
            <path
              d="M16.1406 10.75H16.1584"
              stroke="#1A1721"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M14.5 14.6875H16.1406V21.25H17.7812" fill="#66D8F2" />
            <path
              d="M14.5 14.6875H16.1406V21.25H17.7812"
              stroke="#1A1721"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </svg>
    </Center>
  );
};
