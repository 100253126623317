export const ResidenceIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4828_18451)">
        <path
          d="M23 28V15.4505C23 12.9926 21.0074 11 18.5495 11H10.4505C7.99255 11 6 12.9926 6 15.4505V23.5495C6 26.0074 7.99255 28 10.4505 28H18.5495H23Z"
          fill="#FFD880"
        />
        <path
          d="M12 27.9999V19.9999C12 19.2927 12.281 18.6144 12.781 18.1143C13.2811 17.6142 13.9594 17.3333 14.6667 17.3333H17.3333C18.0406 17.3333 18.7189 17.6142 19.219 18.1143C19.719 18.6144 20 19.2927 20 19.9999"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.3333 16H28L16 4L4 16H6.66667V25.3333C6.66667 26.0406 6.94762 26.7189 7.44772 27.219C7.94781 27.719 8.62609 28 9.33333 28H16.6667"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.3335 25.3333H29.3335"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.3335 21.3333L29.3335 25.3333L25.3335 29.3333"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4828_18451">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
