export const PAYMENT_METHODS = {
  VIRTUAL_CARD: 'Virtual Card',
  DIRECT_ACH: 'Direct ACH',
} as const;

export const INVALID_ACCESS_TOKEN_STRINGS = {
  ResponseCode: '900',
  ResponseCodeDesc: 'Invalid Access',
} as const;

export const EXPIRED_ACCESS_TOKEN_STRINGS = {
  ResponseCode: '903',
  ResponseCodeDesc: 'Security API Failed',
} as const;

export const CLIENT_ERRORS = {
  INVALID_ACCESS_TOKEN: 'Invalid Access Token',
  EXPIRED_ACCESS_TOKEN: 'Expired Access Token',
  IP_RESTRICTED: 'The origin IP is restricted from accessing the server',
} as const;

export const OMISSION_CHAR = '*';
