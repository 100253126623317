export const FamilyChangeIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4828_18458)">
        <path
          d="M25.5366 4.41651C26.6848 4.38352 27.653 5.30883 27.6797 6.45717C27.7981 11.5393 27.9647 19.5098 22.3376 25.5289C16.4123 31.867 10.4006 31.31 4.47971 30.9115C3.50696 30.8461 2.70916 30.1009 2.57743 29.1349C1.85632 23.8465 0.895488 17.2151 6.74806 10.9547C12.4601 4.84469 20.0444 4.57432 25.5366 4.41651Z"
          fill="#80E8B6"
        />
        <path
          d="M15.9998 14.6667C18.9454 14.6667 21.3332 12.2789 21.3332 9.33333C21.3332 6.38781 18.9454 4 15.9998 4C13.0543 4 10.6665 6.38781 10.6665 9.33333C10.6665 12.2789 13.0543 14.6667 15.9998 14.6667Z"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 28V25.3333C8 23.9188 8.5619 22.5623 9.5621 21.5621C10.5623 20.5619 11.9188 20 13.3333 20H18.6667C20.0812 20 21.4377 20.5619 22.4379 21.5621C23.4381 22.5623 24 23.9188 24 25.3333V28"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24 4.17334C25.1472 4.46707 26.164 5.13427 26.8902 6.06975C27.6163 7.00523 28.0105 8.15578 28.0105 9.34001C28.0105 10.5242 27.6163 11.6748 26.8902 12.6103C26.164 13.5457 25.1472 14.2129 24 14.5067"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.6665 28V25.3333C30.6597 24.1562 30.2637 23.0144 29.5402 22.0858C28.8167 21.1573 27.8063 20.4942 26.6665 20.2"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 10.6667C6.85577 10.8942 5.84159 11.4107 5.11735 12.1349C4.39311 12.8592 4 13.7499 4 14.6667C4 15.5836 4.39311 16.4743 5.11735 17.1986C5.84159 17.9228 6.85577 18.4393 8 18.6667"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.6665 29.1333V26.4666C2.67327 25.2895 3.06928 24.1477 3.79281 23.2191C4.51634 22.2906 5.52674 21.6275 6.6665 21.3333"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4828_18458">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
