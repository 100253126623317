export const LifeEventIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
        fill="#80E8B6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.65027 4.62491C9.29315 3.6758 11.2025 3.29317 13.0843 3.53598C14.966 3.77878 16.7157 4.63354 18.0638 5.9686C19.412 7.30365 20.2838 9.04492 20.545 10.9242C20.5868 11.2251 20.3768 11.5029 20.0759 11.5447C19.775 11.5865 19.4972 11.3765 19.4554 11.0756C19.2275 9.43532 18.4665 7.91548 17.2898 6.7502C16.1131 5.58492 14.5859 4.83886 12.9435 4.62693C11.301 4.41501 9.63449 4.74897 8.20053 5.57739C7.01189 6.26408 6.03774 7.25892 5.37648 8.4499H8.0002C8.30395 8.4499 8.5502 8.69615 8.5502 8.9999C8.5502 9.30366 8.30395 9.5499 8.0002 9.5499H4.51249C4.50416 9.55009 4.49581 9.55009 4.48745 9.5499H4.0002C3.69644 9.5499 3.4502 9.30366 3.4502 8.9999V4.9999C3.4502 4.69615 3.69644 4.4499 4.0002 4.4499C4.30395 4.4499 4.5502 4.69615 4.5502 4.9999V7.6808C5.30267 6.4204 6.36744 5.36601 7.65027 4.62491Z"
        fill="#3C0789"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.92407 12.4553C4.22493 12.4134 4.50273 12.6234 4.54454 12.9243C4.77249 14.5646 5.53343 16.0844 6.71014 17.2497C7.88685 18.415 9.41405 19.1611 11.0565 19.373C12.6989 19.5849 14.3655 19.2509 15.7994 18.4225C16.9881 17.7358 17.9622 16.741 18.6235 15.55H15.9998C15.696 15.55 15.4498 15.3038 15.4498 15C15.4498 14.6963 15.696 14.45 15.9998 14.45H19.4875C19.4958 14.4498 19.5042 14.4498 19.5125 14.45H19.9998C20.3035 14.45 20.5498 14.6963 20.5498 15V19C20.5498 19.3038 20.3035 19.55 19.9998 19.55C19.696 19.55 19.4498 19.3038 19.4498 19V16.3191C18.6973 17.5795 17.6325 18.6339 16.3497 19.375C14.7068 20.3241 12.7975 20.7067 10.9157 20.4639C9.03399 20.2211 7.28428 19.3664 5.93613 18.0313C4.58798 16.6963 3.71617 14.955 3.45501 13.0757C3.4132 12.7749 3.6232 12.4971 3.92407 12.4553Z"
        fill="#3C0789"
      />
    </svg>
  );
};
