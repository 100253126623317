export function toWords(
  inputString: string,
  firstWordCapitalized = false
): string {
  const formattedString = inputString
    // Convert kebab-case to snake_case
    .replaceAll('-', '_')
    // Split camelCase and PascalCase
    .replaceAll(/([a-z])([A-Z])/gu, '$1_$2')
    // Split snake_case
    .replaceAll('_', ' ')
    .toLowerCase();
  return firstWordCapitalized
    ? formattedString.charAt(0).toUpperCase() + formattedString.slice(1)
    : formattedString;
}
