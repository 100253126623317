import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const UserSpouseIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_4832_18341)">
          <path
            d="M29 29V16.4505C29 13.9926 27.0074 12 24.5495 12L12 12V16.4505V24.5495C12 27.0075 13.9926 29 16.4505 29H24.5495H29Z"
            fill="#80E8B6"
          />
          <path
            d="M13.9998 14.6667C16.9454 14.6667 19.3332 12.2789 19.3332 9.33333C19.3332 6.38781 16.9454 4 13.9998 4C11.0543 4 8.6665 6.38781 8.6665 9.33333C8.6665 12.2789 11.0543 14.6667 13.9998 14.6667Z"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 28V25.3333C6 23.9188 6.5619 22.5623 7.5621 21.5621C8.56229 20.5619 9.91885 20 11.3333 20H16.6667C18.0812 20 19.4377 20.5619 20.4379 21.5621C21.4381 22.5623 22 23.9188 22 25.3333V28"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22 4.17334C23.1472 4.46707 24.164 5.13427 24.8902 6.06975C25.6163 7.00523 26.0105 8.15578 26.0105 9.34001C26.0105 10.5242 25.6163 11.6748 24.8902 12.6103C24.164 13.5457 23.1472 14.2129 22 14.5067"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.6665 28.0002V25.3335C28.6597 24.1564 28.2637 23.0146 27.5402 22.0861C26.8167 21.1575 25.8063 20.4945 24.6665 20.2002"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_4832_18341">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
