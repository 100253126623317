import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const InfoSquareRounded = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_3324_770"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="40"
          height="40"
        >
          <rect width="40" height="40" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_3324_770)">
          <mask
            id="mask1_3324_770"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="5"
            y="5"
            width="30"
            height="30"
          >
            <rect x="5" y="5" width="30" height="30" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask1_3324_770)">
            <path
              d="M11.3814 33.3297L28.1112 33.2351C31.3879 33.2165 34.0288 30.4829 34.0098 27.1294L33.9129 10.0073L27.9799 10.0409L17.1831 10.102C13.9064 10.1205 11.2655 12.8541 11.2844 16.2077L11.347 27.2576L11.3814 33.3297Z"
              fill="#66D8F2"
            />
            <rect
              x="5.75"
              y="5.75"
              width="28.5"
              height="28.5"
              rx="5.25"
              stroke="#1A1721"
              strokeWidth="1.5"
            />
            <path
              d="M20.1758 13.4375H20.198"
              stroke="#1A1721"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M18.125 18.3594H20.1758V26.5625H22.2266" fill="#66D8F2" />
            <path
              d="M18.125 18.3594H20.1758V26.5625H22.2266"
              stroke="#1A1721"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </svg>
    </Center>
  );
};
