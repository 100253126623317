import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const ReferralIcon = ({ imageAlt, style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-label={imageAlt}
      >
        <g clipPath="url(#clip0_1_19767)">
          <path
            id="Rectangle 86"
            d="M23.2 26V11.0377L20.05 7.6L17.1342 4L8.40001 4C7.29544 4 6.40001 4.89446 6.40001 5.99903V10.2831V21.717V24.0008C6.40001 26.21 8.19088 28 10.4 28H18.8019H21.2007C22.3052 28 23.2 27.1046 23.2 26Z"
            fill="#FFBBAF"
          />
          <path
            id="Vector"
            d="M11 14H21"
            stroke="#1A1721"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M11 20.667H17.6667"
            stroke="#1A1721"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="Vector_3">
            <path
              d="M18.6667 4V9.33333C18.6667 9.68695 18.8071 10.0261 19.0572 10.2761C19.3072 10.5262 19.6464 10.6667 20 10.6667H25.3333"
              fill="white"
            />
            <path
              d="M18.6667 4V9.33333C18.6667 9.68695 18.8071 10.0261 19.0572 10.2761C19.3072 10.5262 19.6464 10.6667 20 10.6667H25.3333"
              stroke="#1A1721"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <path
            id="Vector_4"
            d="M15.3333 28H9.33332C8.62608 28 7.9478 27.719 7.4477 27.219C6.94761 26.7189 6.66666 26.0406 6.66666 25.3333V6.66667C6.66666 5.95942 6.94761 5.28115 7.4477 4.78105C7.9478 4.28095 8.62608 4 9.33332 4H18.6667L25.3333 10.6667V17.3333M18.6667 25.3333H28M28 25.3333L24 21.3333M28 25.3333L24 29.3333"
            stroke="#1A1721"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_19767">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
