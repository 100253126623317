import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export function HandWithTwoHearts({ style }: SVGIconProps) {
  return (
    <Center style={style}>
      <svg
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_343_638"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="80"
          height="80"
        >
          <path d="M0 0H80V80H0V0Z" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_343_638)">
          <path
            d="M53.2449 61.7049L72.3343 55.1424C74.3826 54.4032 76.8088 55.1015 77.8433 57.0156C79.2484 59.6138 78.0185 62.4795 75.6326 63.4347L46.4376 76.2893C44.0499 77.3751 41.4577 77.9373 38.8356 77.9373C35.508 77.9373 32.242 77.0327 29.3892 75.3201L21.2171 70.4171C19.3311 69.2861 16.2543 68.6514 14.0626 68.572C14.0626 68.569 14.0626 46.6878 14.0626 46.6878H26.4326C28.0439 46.6878 29.6266 47.1016 31.0298 47.892L39.2861 52.5362C39.7548 52.7998 40.2809 52.9378 40.8187 52.9378H50.9371C53.5262 52.9378 55.6246 55.0362 55.6246 57.6253C55.6246 59.3752 54.667 60.8999 53.2449 61.7049Z"
            fill="#FFC39C"
          />
          <path
            d="M14.0627 46.6878V68.572C14.0578 70.2938 12.6613 71.6872 10.9377 71.6872H1.56262V43.5627H10.9377C12.6644 43.5627 14.0627 44.9611 14.0627 46.6878Z"
            fill="#8033EF"
          />
          <path
            d="M71.8156 53.6684L57.0853 58.7325C57.1506 58.37 57.1873 58.0001 57.1873 57.6247C57.1873 54.1787 54.3839 51.3747 50.9372 51.3747H40.8188C40.5502 51.3747 40.2853 51.3057 40.0516 51.1739L31.7965 46.5309C30.1644 45.6111 28.3096 45.1247 26.4327 45.1247H15.356C14.7115 43.3064 12.9744 42.0003 10.9377 42.0003H1.56263C0.699588 42.0003 0.00012207 42.6997 0.00012207 43.5628V71.6873C0.00012207 72.5503 0.699588 73.2498 1.56263 73.2498H10.9377C12.928 73.2498 14.6346 71.9992 15.3121 70.2426C17.0809 70.4623 19.1274 70.9854 20.4134 71.7569L28.5848 76.6598C31.6806 78.5177 35.2249 79.4998 38.8357 79.4998C41.6971 79.4998 44.4693 78.8992 47.0761 77.7151L76.2395 64.8739C79.4695 63.5635 81.0643 59.6883 79.218 56.2728C77.8599 53.7593 74.6794 52.6412 71.8156 53.6684ZM12.5002 68.5696C12.4965 69.4277 11.7964 70.1248 10.9377 70.1248H3.12514V45.1253H10.9377C11.7989 45.1253 12.5002 45.826 12.5002 46.6878V68.5696ZM75.0523 61.9839C75.0358 61.99 75.0194 61.9973 75.0029 62.004C75.0029 62.004 45.7962 74.8642 45.7907 74.8666C43.5904 75.8676 41.2503 76.3748 38.8357 76.3748C35.7913 76.3748 32.803 75.5472 30.1931 73.9804L22.0211 69.0774C20.2718 68.0282 17.7669 67.3886 15.6252 67.1322V48.2503H26.4327C27.7731 48.2503 29.0975 48.597 30.2639 49.2543L38.519 53.8973C39.2184 54.2916 40.0143 54.5003 40.8188 54.5003H50.9372C52.6603 54.5003 54.0622 55.9017 54.0622 57.6253C54.0622 59.3423 52.6596 60.7503 50.9372 60.7503H35.0534C34.1903 60.7503 33.4909 61.4498 33.4909 62.3128C33.4909 63.1753 34.1903 63.8747 35.0534 63.8747H50.9372C51.962 63.8747 52.9709 63.6202 53.8663 63.1429C53.8663 63.1429 72.8575 56.6146 72.8648 56.6121C74.265 56.1068 75.8482 56.6097 76.4689 57.7584C77.3997 59.4802 76.6301 61.3521 75.0523 61.9839Z"
            fill="#1A1721"
          />
          <path
            d="M66.5884 38.9073L58.2594 47.2578L49.9304 38.9073M49.9304 38.9073C49.381 38.3661 48.9482 37.7156 48.6594 36.9968C48.3706 36.278 48.2319 35.5065 48.252 34.7308C48.2722 33.9551 48.4509 33.192 48.7767 32.4896C49.1025 31.7872 49.5685 31.1607 50.1452 30.6495C50.722 30.1384 51.397 29.7537 52.1279 29.5196C52.8587 29.2855 53.6296 29.2072 54.3918 29.2895C55.154 29.3718 55.8912 29.613 56.5568 29.9979C57.2224 30.3827 57.8021 30.903 58.2594 31.5258C58.7186 30.9075 59.299 30.3918 59.9642 30.011C60.6293 29.6302 61.365 29.3925 62.1251 29.3128C62.8852 29.2331 63.6533 29.313 64.3815 29.5476C65.1097 29.7822 65.7822 30.1665 66.3569 30.6763C66.9316 31.1861 67.3962 31.8105 67.7216 32.5104C68.047 33.2104 68.2261 33.9708 68.2479 34.744C68.2696 35.5173 68.1334 36.2867 67.8479 37.0043C67.5623 37.7218 67.1336 38.3719 66.5884 38.914"
            fill="#CDABFF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.2576 30.5247C53.6691 30.4612 53.0739 30.5216 52.5091 30.7025C51.9444 30.8834 51.4217 31.181 50.9743 31.5775C50.5268 31.974 50.1644 32.4609 49.9106 33.008C49.6568 33.5552 49.5173 34.1503 49.5016 34.7558C49.4858 35.3612 49.5941 35.963 49.8192 36.5232C50.0443 37.0833 50.3811 37.5891 50.8075 38.0092L50.8154 38.0169L50.8154 38.017L58.2593 45.4802L65.7033 38.017C65.7274 37.9929 65.7521 37.97 65.7775 37.9483C66.1674 37.5423 66.4764 37.0623 66.6864 36.5345C66.909 35.9753 67.0153 35.3751 66.9983 34.7716C66.9814 34.1681 66.8415 33.5751 66.5881 33.0298C66.3346 32.4846 65.9732 31.9993 65.5274 31.6039C65.0816 31.2084 64.5608 30.9111 63.9981 30.7298C63.4355 30.5486 62.8423 30.4869 62.2555 30.5484C61.6686 30.61 61.1 30.7936 60.5851 31.0883C60.0702 31.3831 59.6198 31.7829 59.2628 32.2636C59.0263 32.582 58.6526 32.7693 58.2559 32.7682C57.8592 32.7671 57.4865 32.5778 57.2518 32.258C56.8962 31.7738 56.4463 31.3704 55.9311 31.0725C55.4158 30.7746 54.846 30.5883 54.2576 30.5247ZM67.3925 39.8636L59.1444 48.133C58.9099 48.3681 58.5914 48.5003 58.2593 48.5003C57.9273 48.5003 57.6088 48.3681 57.3743 48.133L49.049 39.7862C48.3786 39.1247 47.8514 38.331 47.4995 37.4554C47.1469 36.578 46.9778 35.6367 47.0024 34.6907C47.0271 33.7447 47.2449 32.8137 47.6427 31.956C48.0406 31.0984 48.61 30.3323 49.3161 29.7065C50.0223 29.0807 50.8497 28.6089 51.7466 28.3216C52.6436 28.0344 53.59 27.9381 54.526 28.0392C55.4619 28.1403 56.3664 28.4364 57.1824 28.9082C57.5685 29.1314 57.9307 29.3916 58.2645 29.6845C58.5976 29.3954 58.9587 29.1387 59.3431 28.9187C60.1586 28.4518 61.0613 28.16 61.9946 28.0621C62.928 27.9642 63.8712 28.0624 64.7648 28.3503C65.6584 28.6382 66.4827 29.1095 67.1864 29.7336C67.89 30.3578 68.4578 31.1213 68.8551 31.976C69.2524 32.8306 69.4709 33.7584 69.4974 34.7014C69.5239 35.6444 69.3578 36.5831 69.0093 37.4589C68.6607 38.3348 68.1369 39.1295 67.4697 39.7929C67.4447 39.8177 67.4189 39.8413 67.3925 39.8636Z"
            fill="#1A1721"
          />
          <path
            d="M44.3383 19.9077L36.0093 28.2582L27.6802 19.9077M27.6802 19.9077C27.1309 19.3665 26.6981 18.716 26.4093 17.9972C26.1204 17.2785 25.9817 16.5069 26.0019 15.7312C26.0221 14.9555 26.2007 14.1924 26.5266 13.49C26.8524 12.7876 27.3184 12.1611 27.8951 11.65C28.4719 11.1388 29.1469 10.7541 29.8778 10.52C30.6086 10.286 31.3794 10.2076 32.1417 10.2899C32.9039 10.3722 33.641 10.6134 34.3067 10.9983C34.9723 11.3832 35.552 11.9034 36.0093 12.5262C36.4685 11.9079 37.0489 11.3922 37.714 11.0114C38.3792 10.6307 39.1149 10.393 39.875 10.3132C40.635 10.2335 41.4032 10.3134 42.1314 10.548C42.8596 10.7826 43.5321 11.1669 44.1068 11.6767C44.6815 12.1865 45.1461 12.8109 45.4715 13.5109C45.7969 14.2108 45.976 14.9712 45.9978 15.7444C46.0195 16.5177 45.8833 17.2871 45.5978 18.0047C45.3122 18.7222 44.8834 19.3723 44.3383 19.9144"
            fill="#CDABFF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.0076 11.5247C31.4191 11.4612 30.8239 11.5216 30.2591 11.7025C29.6944 11.8834 29.1717 12.181 28.7243 12.5775C28.2768 12.974 27.9144 13.4609 27.6606 14.008C27.4068 14.5552 27.2673 15.1503 27.2516 15.7557C27.2358 16.3612 27.3441 16.963 27.5692 17.5232C27.7943 18.0833 28.1311 18.5891 28.5575 19.0092L28.5654 19.0169L28.5654 19.017L36.0093 26.4802L43.4533 19.017C43.4774 18.9929 43.5021 18.97 43.5275 18.9483C43.9174 18.5422 44.2264 18.0623 44.4364 17.5345C44.659 16.9753 44.7653 16.3751 44.7483 15.7716C44.7314 15.1681 44.5915 14.5751 44.3381 14.0298C44.0846 13.4846 43.7232 12.9993 43.2774 12.6038C42.8316 12.2084 42.3108 11.9111 41.7481 11.7298C41.1855 11.5485 40.5923 11.4869 40.0055 11.5484C39.4186 11.61 38.85 11.7936 38.3351 12.0883C37.8202 12.3831 37.3698 12.7829 37.0128 13.2636C36.7763 13.582 36.4026 13.7693 36.0059 13.7682C35.6092 13.7671 35.2365 13.5778 35.0018 13.258C34.6462 12.7738 34.1963 12.3704 33.6811 12.0725C33.1658 11.7745 32.596 11.5883 32.0076 11.5247ZM45.1425 20.8636L36.8944 29.133C36.6599 29.3681 36.3414 29.5002 36.0093 29.5002C35.6773 29.5002 35.3588 29.3681 35.1243 29.133L26.799 20.7862C26.1286 20.1247 25.6014 19.331 25.2495 18.4554C24.8969 17.578 24.7278 16.6367 24.7524 15.6907C24.7771 14.7447 24.9949 13.8136 25.3927 12.956C25.7906 12.0984 26.36 11.3323 27.0661 10.7065C27.7723 10.0807 28.5997 9.60887 29.4966 9.32162C30.3936 9.03435 31.34 8.93811 32.276 9.03918C33.2119 9.14026 34.1164 9.43636 34.9324 9.90819C35.3185 10.1314 35.6807 10.3916 36.0145 10.6845C36.3476 10.3954 36.7087 10.1387 37.0931 9.91865C37.9086 9.45182 38.8113 9.15999 39.7446 9.06207C40.678 8.96415 41.6212 9.06236 42.5148 9.35029C43.4084 9.63822 44.2327 10.1094 44.9364 10.7336C45.64 11.3577 46.2078 12.1213 46.6051 12.976C47.0024 13.8306 47.2209 14.7584 47.2474 15.7014C47.2739 16.6443 47.1078 17.5831 46.7593 18.4589C46.4107 19.3348 45.8869 20.1295 45.2197 20.7928C45.1947 20.8177 45.1689 20.8413 45.1425 20.8636Z"
            fill="#1A1721"
          />
        </g>
      </svg>
    </Center>
  );
}
